import { BaseResourceModel } from './base-resource.model';

export class Screenshot extends BaseResourceModel {
  constructor(
    public deviceId?: string, 
    public image?: string,
    public modelName?: string,
    public type?: string,
    public data?: string, // Store image data in base64
    public value?: string // Stores the value of the image download URL
    ){
    super();
  }

  static fromJson(jsonData: any): Screenshot {
    return Object.assign(new Screenshot(), jsonData);
  }
}
