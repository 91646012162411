import { Permission } from './permission.model';
import { Role } from './role.model';
import { Company } from './company.model';

export class Authorization {
  constructor(
    public token?: string,
    public username?: string,
    public name?: string,
    public operatorType?: TokenType,
    public operatorId?: string,
    public companyId?: string,
    public deviceId?: string,
    public companies?: Company[],
    public roles?: Role[],
    public permissions?: Permission[]
  ) {}

  static fromJson(jsonData: any): Authorization {
    return new Authorization(jsonData);
  }
}

export enum TokenType {
  DEVICE_TOKEN = 'DEVICE_TOKEN',
  GUEST_TOKEN = 'GUEST_TOKEN',
  REWRITE_PASSWORD = 'REWRITE_PASSWORD',
  SECURITY_COMPANY_TOKEN = 'SECURITY_COMPANY_TOKEN',
  SECURITY_TOKEN = 'SECURITY_TOKEN'
}
