import { BaseResourceModel } from './base-resource.model';

export class Upload extends BaseResourceModel {
  constructor(public fileName?: string, public modelName?: string) {
    super();
  }

  static fromJson(jsonData: any): Upload {
    return Object.assign(new Upload(), jsonData);
  }
}
