import { Device } from '../../../../../shared/models/device.model';
import { WebsocketUser } from '../../models/participants/websocket-user.model';
import { RegisteredType } from '../../constants/registered-type';

export class SetRegisterState {
  static readonly type = 'SetRegisterState';

  constructor(public readonly state: RegisteredType) {}
}

export class SetCallNotRegistered {
  static readonly type = 'SetCallNotRegistered';

  constructor() {}
}

export class SetCallRegistering {
  static readonly type = 'SetCallRegistering';

  constructor() {}
}

export class SetCallRegistered {
  static readonly type = 'SetCallRegistered';

  constructor() {}
}

export class SetCallEnded {
  static readonly type = 'SetCallEnded';

  constructor() {}
}

export class SetCallFailure {
  static readonly type = 'SetCallFailure';

  constructor(public readonly reason: string) {}
}

export class SetConnectionId {
  static readonly type = 'SetConnectionId';

  constructor(public readonly connectionId: string) {}
}

export class SetCallDevice {
  static readonly type = 'SetCallDevice';

  constructor(public readonly device: Device) {}
}

export class SetCallOperator {
  static readonly type = 'SetCallOperator';

  constructor(public readonly operator: WebsocketUser) {}
}

export class SetScheduleEndTime {
  static readonly type = 'SetScheduleEndTime';

  constructor(public readonly scheduleEndTime: Date) {}
}

export class ClearCallState {
  static readonly type = 'ClearCallState';
  constructor() {}
}
