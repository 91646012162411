<p-galleria
  #galleria
  [value]="screenshots"
  [(visible)]="show"
  [fullScreen]="true"
  [baseZIndex]="2000"
  [showThumbnails]="false"
  [numVisible]="5"
  [circular]="true"
  [showItemNavigators]="true"
  [showItemNavigatorsOnHover]="true"
  [containerClass]="galleriaClass()"
  [transitionInterval]="3000"
  [(activeIndex)]="activeIndex"
  (close)="emitClose()"
>
  <ng-template pTemplate="item" let-item>
    <img
      style="
        max-height: 100vh;
        max-width: 80%;
        width: auto;
        aspect-ratio: attr(width) / attr(height);
      "
      [src]="
        item?.image && item?.image.includes(cdnS3Medias)
          ? item?.image
          : image
      "
      [ngStyle]="{
        width: !fullscreen ? '100%' : '',
        display: !fullscreen ? 'block' : ''
      }"
    />
  </ng-template>

  <ng-template pTemplate="header" let-item>
    <div class="custom-galleria-header">
      <div class="galleria-buttons">
        <button
          type="button"
          pButton
          icon="pi pi-times"
          alt="Close"
          (click)="emitClose()"
        ></button>
        <button
          type="button"
          pButton
          icon="pi pi-trash"
          alt="Delete"
          (click)="confirmDelete($event, screenshots[activeIndex])"
        ></button>
        <button
          type="button"
          pButton
          icon="pi pi-cloud-download"
          alt="Download"
          (click)="emitDownload(screenshots[activeIndex])"
        >
          <a href="javascript:void(0);"></a>
        </button>
      </div>
      <span *ngIf="screenshots" class="title-container">
        <span>{{ activeIndex + 1 }}/{{ screenshots.length }}</span>
        <span
          >{{ 'createdAt' | translate }}:
          {{
            screenshots[activeIndex]?.createdAt | date : 'dd/MM/yyyy HH:mm'
          }}</span
        >
      </span>
    </div>
  </ng-template>
</p-galleria>
<p-confirmPopup key="galleriaPopup"></p-confirmPopup>
