/**
 * Created by Wilton O. Ferreira on 18/10/21
 */
import { Constants } from './src/app/constants';

const awsmobile = {
  aws_project_region: 'us-east-1',
  aws_cognito_identity_pool_id:
    'us-east-1:d580b584-28ea-4729-817d-20d64ae02b2d',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_fLhHbMdp4',
  aws_user_pools_web_client_id: '4hlcsavoq5or7ju5n1b8jeh7rt',
  oauth: {},
  aws_appsync_graphqlEndpoint: Constants.CHAT_ENDPOINT,
  aws_appsync_region: 'us-east-1',
  aws_appsync_authenticationType: 'AWS_LAMBDA'
};

export default awsmobile;
