import { BaseResourceModel } from '../models/base-resource.model';
import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class BaseResourceNotifications<T extends BaseResourceModel> {
  constructor(protected notification: MessageService) {}

  public addToast(options: any): void {
    this.notification.add(options);
  }
}
