import { BaseResourceModel } from './base-resource.model';

export class File extends BaseResourceModel {
  constructor(
    public type?: string,
    public fileName?: string,
    public modelName?: string,
    public url?: string
  ) {
    super();
  }

  static fromJson(jsonData: any): File {
    return Object.assign(new File(), jsonData);
  }
}
