import { BrowserModule } from '@angular/platform-browser';
import {
  NgModule,
  NO_ERRORS_SCHEMA,
  Injector,
  APP_INITIALIZER
} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './core/guards/jwt.interceptor';
import { ErrorInterceptor } from './core/guards/error.interceptor';
import { Amplify } from 'aws-amplify';
import awsconfig from '../../aws-exports';
import { ConfirmationService, MessageService } from 'primeng/api';
import { NgxsModule } from '@ngxs/store';
import { environment } from '../environments/environment';
import { CallState } from './pages/device/device-control/store/states/call.state';
import { ParticipantsState } from './pages/device/device-control/store/states/participants.state';
import { WebsocketHandlerService } from './pages/device/device-control/services/websocket-handler.service';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { ScreenshotsState } from './pages/screenshot/store/screenshot.state';
import { ToastContainerModule } from 'ngx-toastr';
import { RoomsState } from './pages/device/shared/store/states/rooms.state';
import { DevicesState } from './pages/device/shared/store/states/devices.state';
import { WebsocketState } from './pages/device/shared/store/states/websocket.state';
import { WebsocketService } from './pages/device/shared/services/websocket.service';
import { ScheduleState } from './pages/schedule/store/schedule.state';
import {
  TranslateModule,
  TranslateLoader,
  TranslateService
} from '@ngx-translate/core';
import {
  ApplicationInitializerFactory,
  HttpLoaderFactory
} from './translation.config';
import { HttpClient } from '@angular/common/http';
import { MobileStoreComponent } from './pages/mobile-store/mobile-store.component';

Amplify.configure(awsconfig);

@NgModule({
  declarations: [AppComponent, MobileStoreComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    NgxsModule.forRoot(
      [
        RoomsState,
        DevicesState,
        WebsocketState,
        CallState,
        ParticipantsState,
        ScreenshotsState,
        ScheduleState
      ],
      {
        developmentMode: !environment.production,
        selectorOptions: {
          suppressErrors: false,
          injectContainerState: false
        }
      }
    ),
    ToastContainerModule,
    NgxsReduxDevtoolsPluginModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  schemas: [NO_ERRORS_SCHEMA],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: ApplicationInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    ConfirmationService,
    MessageService,
    WebsocketService,
    WebsocketHandlerService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
