<div class="row text-center">
  <div class="col-sm-12 centered-content">
    <div class="btn-group mt-10">
      <button
        [type]="to.type"
        [hidden]="
          to.action === 'previo' ||
          to.action === 'next_previo' ||
          to.action === 'previo_save'
            ? false
            : true
        "
        [ngClass]="'btn btn-' + to.btnType"
        (click)="onClick($event)"
        awPreviousStep
      >
        << Anterior
      </button>
      <button
        [type]="to.type"
        [hidden]="
          to.action === 'next' || to.action === 'next_previo' ? false : true
        "
        [ngClass]="'btn btn-' + to.btnType"
        (click)="onClick($event)"
        awNextStep
      >
        Próximo >>
      </button>
      <button
        [type]="to.type"
        [hidden]="
          to.action === 'save' || to.action === 'previo_save' ? false : true
        "
        [ngClass]="'btn btn-' + to.btnTypeSubmit"
        (click)="onClick($event)"
        awResetWizard
      >
        Salvar
      </button>
    </div>
  </div>
</div>
