import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const url: string = state.url;
    const requiredRoles: string[] | undefined = route.data.roles;
    const loggedIn = this.checkLogin(url);
    if (requiredRoles && loggedIn) {
      return this.checkRoles(requiredRoles);
    }
    return loggedIn;
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivate(route, state);
  }

  private checkRoles(roles: string[]) {
    if (this.authenticationService.hasPermission(roles)) {
      return true;
    }

    this.router.navigate(['/access-denied']);
  }

  private checkLogin(url: string) {
    if (this.authenticationService.isLoggedIn()) {
      return true;
    }

    this.authenticationService.redirectUrl = url;
    this.router.navigate(['/auth/login/simple'], {
      queryParams: { returnUrl: url }
    });
  }
}
