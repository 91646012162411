import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { ConfirmationService } from 'primeng/api';
import { Observable, throwError } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    protected confirmationService: ConfirmationService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 401 || err.status === 403) {
          if (!request.url.includes('access-log')) {
            this.confirmationService.confirm({
              key: 'confirm-logout',
              accept: () => this.authenticationService.clearAuthorization(),
              closeOnEscape: false
            });
          }
        }
        const errorMessage = err.error.message || err.statusText;
        return throwError(errorMessage);
      })
    );
  }
}
