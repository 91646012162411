import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import { IsLoadingService } from '@service-work/is-loading';
import { interval, of, ReplaySubject } from 'rxjs';
import { DeviceTableViewModel } from '../shared/store/queries/devices.queries';
import { QosMqttService } from '../../../core/mqtt/services/qos-mqtt.service';
import { CheckDeviceMqttConnectionService } from '../../../core/mqtt';
import { Dropdown } from 'primeng/dropdown/dropdown';
import { Select, Store } from '@ngxs/store';
import {
  debounceTime,
  filter,
  takeUntil,
  take,
  tap
} from 'rxjs/operators';
import { ParticipantsState } from '../device-control/store/states/participants.state';
import {
  SetOperatorAudioDevice,
  SetOperatorAudioStatus,
  SetOperatorVideoDevice,
  SetOperatorVideoStatus,
  SetWebRTCCommunication,
  ToggleOperatorAudioStatus,
  ToggleOperatorVideoStatus,
  WebRTCState
} from '../device-control/store/actions/participants.actions';
import { WebsocketUser } from '../device-control/models/participants/websocket-user.model';
import { ParticipantsQueries } from '../device-control/store/queries/participants.queries';
import { WebsocketState } from '../shared/store/states/websocket.state';
import { SetCallFailure } from '../device-control/store/actions/call.actions';
import { MessageService } from 'primeng/api';
import { PreCallQueries } from '../shared/store/queries/pre-call.queries';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-device-pre-call',
  templateUrl: './device-pre-call.component.html',
  styleUrls: ['./device-pre-call.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DevicePreCallComponent implements OnInit, OnDestroy {
  @Input() preCall$: Observable<any>;
  @Input() notifier$: Observable<boolean>;
  @Input() device: DeviceTableViewModel;
  @Output() onPreCallClose = new EventEmitter();

  @ViewChild('video') video!: ElementRef;
  @ViewChild('mqtt') mqtt!: ElementRef;
  @ViewChild('cam') cam!: Dropdown;
  @ViewChild('mic') mic!: Dropdown;
  cams$ = new Observable<MediaDeviceInfo[]>();
  mics$ = new Observable<MediaDeviceInfo[]>();
  cameraSelected: MediaDeviceInfo = null;
  microphoneSelected: MediaDeviceInfo = null;

  showModal = false;

  private readonly videoConstraints = {
    video: {
      width: { min: 426, ideal: 640, max: 1920 },
      height: { min: 240, ideal: 360, max: 1080 },
      aspectRatio: { min: 1, ideal: 1.7777777778 },
      deviceId: ''
    }
  };

  @Select(ParticipantsState.getOtherParticipants)
  normalParticipants$: Observable<WebsocketUser[]>;

  @Select(ParticipantsState.hasOperatorVideoDevice)
  cameraCheck$: Observable<boolean>;

  @Select(ParticipantsState.hasWebRTCCommunicationCheck)
  webRTCCheck$: Observable<boolean>;

  @Select(ParticipantsState.hasOperatorAudioDevice)
  microphoneCheck$: Observable<boolean>;

  @Select(WebsocketState.connected)
  webSocketCheck$: Observable<boolean>;

  @Select(ParticipantsState.getOperatorAudioStatus)
  sendAudio$: Observable<boolean>;

  @Select(ParticipantsState.hasDeviceMqttStatus)
  deviceMqttCheck$: Observable<boolean>;

  @Select(ParticipantsState.hasOperatorMqttStatus)
  operatorMqttCheck$: Observable<boolean>;
  mqttStatus = false;

  @Select(ParticipantsState.getOperatorVideoStatus)
  sendVideo$: Observable<boolean>;

  @Select(ParticipantsQueries.getParticipantsAmount)
  participantsAmount$: Observable<number>;

  @Select(PreCallQueries.conditionsForCallReady)
  conditionsForCallReady$: Observable<boolean>;

  @Select(ParticipantsState.hasWebRTCCommunicationReady)
  callReady$: Observable<boolean>;

  @Select(WebsocketState.connectionStatus)
  webRTCStatus$: Observable<boolean>;
  private destroy$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    protected router: Router,
    private qosMqttService: QosMqttService,
    private checkDeviceMqttConnectionService: CheckDeviceMqttConnectionService,
    private readonly store: Store,
    private messageService: MessageService,
    private translate: TranslateService
  ) { }

  async ngOnInit(): Promise<void> {
    this.qosMqttService.subscribeCheck();
    await this.loadDevices();
    this.cameraSelected = null;
    this.microphoneSelected = null;

    // Wait 1.5 seconds before showing "Connect" button
    this.conditionsForCallReady$
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(1500),
        filter((state: boolean) => state === true)
      )
      .subscribe(() =>
        this.store.dispatch(new SetWebRTCCommunication(WebRTCState.READY))
      );

    // If any state changes lead to call not ready, WebRTCState update immediately
    this.conditionsForCallReady$
      .pipe(
        takeUntil(this.destroy$),
        filter((state: boolean) => state === false)
      )
      .subscribe(() =>
        this.store.dispatch(new SetWebRTCCommunication(WebRTCState.CONNECTING))
      );

    this.webRTCStatus$
      .pipe(
        takeUntil(this.destroy$),
        filter((status: boolean) => status === false)
      )
      .subscribe(() => {
        this.messageService.add({
          severity: 'warn',
          summary: this.translate.instant('Disconnected from server'),
          detail:
            'Try reloading the page if this message does not disappear after a few seconds',
          key: 'bottom-actions-toast',
          sticky: true
        });
      });
  }

  async loadDevices(): Promise<void> {
    await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
    this.cams$ = of([]);
    this.mics$ = of([]);
    navigator.mediaDevices
      .enumerateDevices()
      .then(async (devices: MediaDeviceInfo[]) => {
        if (devices !== undefined && devices !== null && devices?.length > 0) {
          this.cams$ = of(
            devices.filter(
              (value: MediaDeviceInfo) => value.kind === 'videoinput'
            )
          );
          this.mics$ = of(
            devices.filter(
              (value: MediaDeviceInfo) => value.kind === 'audioinput'
            )
          );
        }
      })
      .catch(() =>
        this.store.dispatch(
          new SetCallFailure('Failed to enumerate input devices')
        )
      );
  }

  handleStream(stream: any) {
    this.video.nativeElement.srcObject = stream;
  }

  async startStream(constraints): Promise<any> {
    try {
      const stream: MediaStream = await navigator.mediaDevices.getUserMedia(
        constraints
      );
      this.handleStream(stream);
    } catch (error) {
      console.error('Failed to check camera.', error);
    }
  }

  stopMediaTracks(stream: MediaStream) {
    try {
      if (stream) {
        stream?.getTracks()?.forEach((track: MediaStreamTrack) => {
          if (track) {
            track.stop();
            track = null;
          }
          this.video.nativeElement.srcObject = null;
        });
      }
    } catch (e) {
      console.error('Error while stopping media tracks.', e);
    }
  }

  async playMicrophone(device: MediaDeviceInfo): Promise<any> {
    this.store.dispatch(new SetOperatorAudioDevice(device.deviceId));
  }

  async playVideo(device: any): Promise<any> {
    this.clearMediaTracks();
    const updatedConstraints = {
      ...this.videoConstraints
    };
    updatedConstraints.video.deviceId = device?.deviceId;
    await this.startStream(updatedConstraints);
    await this.store.dispatch(new SetOperatorVideoDevice(device.deviceId)).toPromise();
  }

  async openModal(): Promise<void> {
    // Request robot MQTT connection status check.
    interval(500)
      .pipe(take(1))
      .subscribe(async () => await this.checkDeviceMqttConnectionService.checkDeviceConnection());
    interval(5000)
      .pipe(
        takeUntil(this.destroy$),
        takeUntil(this.deviceMqttCheck$.pipe(filter((status) => status))),
        take(7)
      )
      .subscribe(async () => await this.checkDeviceMqttConnectionService.checkDeviceConnection());
    interval(4000)
      .pipe(
        takeUntil(this.destroy$),
        takeUntil(this.operatorMqttCheck$.pipe(filter((status) => status)))
      )
      .subscribe(() => this.qosMqttService.subscribeCheck());
    this.store.dispatch(new SetOperatorVideoStatus(true));
    this.store.dispatch(new SetOperatorAudioStatus(true))
    this.showModal = true;
  }

  async closeModal(event: Event): Promise<void> {
    this.showModal = true;
    this.clearMediaTracks();
    this.cam.writeValue(null);
    this.mic.writeValue(null);
    this.cameraSelected = null;
    this.microphoneSelected = null;
    // Todo: these dispatches are also done in CallSetupService and may be removed from here
    await this.store.dispatch(new SetOperatorAudioDevice('')).toPromise();
    await this.store.dispatch(new SetOperatorVideoDevice('')).toPromise();
    await this.store.dispatch(new SetWebRTCCommunication(WebRTCState.STOPPED)).toPromise();
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  clearMediaTracks(): void {
    this.stopMediaTracks(this.video?.nativeElement?.srcObject);
  }

  // Called when user clicks on "connect" button
  async call(): Promise<void> {
    await this.store.dispatch(
      new SetWebRTCCommunication(WebRTCState.RECEIVESTREAM)
    ).toPromise();
  }

  mute() {
    this.store.dispatch(new ToggleOperatorAudioStatus());
  }

  handleVideo() {
    this.store.dispatch(new ToggleOperatorVideoStatus());
  }

  async ngOnDestroy(): Promise<void> {
    console.log('Destroyed pre-call component');
    this.onPreCallClose.emit(true);
    this.clearMediaTracks();
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
