import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxLongPressDirective } from './ngx-long-press.directive';

@NgModule({
  declarations: [NgxLongPressDirective],
  imports: [CommonModule],
  exports: [NgxLongPressDirective]
})
export class NgxLongPressModule {}
