import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BaseIndicatorModel } from '../base-indicator/base-indicator.model';

@Component({
  selector: 'battery-status-indicator',
  templateUrl: './battery-status.component.html',
  styleUrls: ['./battery-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BatteryStatusComponent extends BaseIndicatorModel {
  @Input() status: string;

  constructor() {
    super();
    this.states = new Map<string, string>([
      ['full', 'assets/icon/battery/battery-full.svg'],
      ['mid', 'assets/icon/battery/battery-medium.svg'],
      ['low', 'assets/icon/battery/battery-low.svg'],
      ['very_low', 'assets/icon/battery/battery-critical.svg'],
      ['critical', 'assets/icon/battery/battery-critical.svg'],
      ['charging', 'assets/icon/battery/battery-recharging.svg']
    ]);
    this.defaultIcon = 'assets/icon/battery/battery-unknown.svg';
  }
}
