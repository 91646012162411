import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mobile-store',
  templateUrl: './mobile-store.component.html',
  styleUrls: ['./mobile-store.component.scss']
})
export class MobileStoreComponent {
  constructor(private router: Router) {}

  readonly playStore =
    'https://play.google.com/store/apps/details?id=com.rbv_ctrl_flutter';

  readonly appleStore =
    'https://apps.apple.com/br/app/robovisor-ctrl/id6478391030';

  handleBackToSite() {
    sessionStorage.setItem('mobileView', 'false');
    const redirectUrl = sessionStorage.getItem('redirectUrl');
    sessionStorage.removeItem('redirectUrl');
    if (redirectUrl !== null) {
      this.router.navigate([redirectUrl]);
    } else {
      this.router.navigate(['/']);
    }
  }
}
