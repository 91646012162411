import { AfterViewInit, Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { Constants } from 'src/app/constants';
import {
  ChildrenItems,
  MainMenuItems,
  MenuItems
} from 'src/app/shared/menu-items/menu-items';

@Component({
  selector: 'app-aside-menu',
  templateUrl: './aside-menu.component.html',
  styleUrls: ['./aside-menu.component.scss']
})
export class AsideMenuComponent implements OnInit, AfterViewInit {
  items: MenuItem[];
  private _selectedMenuItem: string;
  version = Constants.VERSION;

  constructor(
    public menuItems: MenuItems,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this._updateMenu();
    this.translate.onDefaultLangChange.subscribe(() => this._updateMenu());
  }

  ngAfterViewInit(): void {
    this.setSelectedMenu(document.location.pathname.slice(1));
  }

  private async _updateMenu() {
    this.items = this.menuItems.getAll().map((menuItem: MainMenuItems) => ({
      label: this.translate.instant(menuItem.state),
      icon: menuItem.icon,
      command: !menuItem.children
        ? () => this.setSelectedMenu(menuItem.state)
        : undefined,
      routerLink: !menuItem.children ? menuItem.state : undefined,
      items: menuItem.children
        ? menuItem.children.map((menuItemChildren: ChildrenItems) => ({
            label: this.translate.instant(menuItemChildren.state),
            icon: menuItemChildren.icon,
            routerLink: `${menuItem.state}/${menuItemChildren.state}`,
            command: () =>
              this.setSelectedMenu(
                `${menuItem.state}/${menuItemChildren.state}`
              )
          }))
        : undefined
    }));
  }

  setSelectedMenu(menu: string) {
    // remove styles from last selected menu
    if (this._selectedMenuItem) {
      const lastMenuItem = document.documentElement.querySelector(
        `.asideMenu a[href='/${this._selectedMenuItem}']`
      );

      if (lastMenuItem) {
        lastMenuItem.classList.remove('selected');
      }
    }

    // add styles for new one
    const menuItem = document.documentElement.querySelector(
      `.asideMenu a[href='/${menu}']`
    );

    if (menuItem) {
      menuItem.classList.add('selected');
      this._selectedMenuItem = menu;
    }
  }
}
