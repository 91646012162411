/**
 * Created by Guilherme Beneti Martins on 22/11/2022
 */

import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { patch } from '@ngxs/store/operators';
import { Device } from '../../../../../shared/models/device.model';
import {
  ClearCallState,
  SetCallDevice,
  SetCallEnded,
  SetCallFailure,
  SetCallNotRegistered,
  SetCallOperator,
  SetCallRegistered,
  SetCallRegistering,
  SetConnectionId,
  SetRegisterState,
  SetScheduleEndTime
} from '../actions/call.actions';
import { CallStateModel } from '../models/call.model';
import { WebsocketUser } from '../../models/participants/websocket-user.model';
import { RegisteredType } from '../../constants/registered-type';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

@State<CallStateModel>({
  name: 'state',
  defaults: {
    state: RegisteredType.NOT_REGISTERED,
    connectionId: null,
    device: null,
    operator: null,
    failed: false,
    scheduleEndTime: null
  }
})
@Injectable({
  providedIn: 'root'
})
export class CallState {
  constructor(
    protected messageService: MessageService,
    private translate: TranslateService
  ) {}

  @Action(SetRegisterState)
  setRegisterState(
    ctx: StateContext<CallStateModel>,
    action: SetRegisterState
  ) {
    ctx.setState(
      patch<CallStateModel>({
        state: action.state
      })
    );
  }

  @Action(SetCallNotRegistered)
  setCallNotRegistered(ctx: StateContext<CallStateModel>) {
    ctx.setState(
      patch<CallStateModel>({
        state: RegisteredType.NOT_REGISTERED
      })
    );
  }

  @Action(SetCallRegistering)
  setCallRegistering(ctx: StateContext<CallStateModel>) {
    ctx.setState(
      patch<CallStateModel>({
        state: RegisteredType.REGISTERING
      })
    );
  }

  @Action(SetCallRegistered)
  setCallRegistered(ctx: StateContext<CallStateModel>) {
    ctx.setState(
      patch<CallStateModel>({
        state: RegisteredType.REGISTERED
      })
    );
  }

  @Action(SetCallEnded)
  setCallEnded(ctx: StateContext<CallStateModel>) {
    ctx.setState(
      patch<CallStateModel>({
        state: RegisteredType.ENDED
      })
    );
  }

  @Action(SetScheduleEndTime)
  setScheduleEndTime(
    ctx: StateContext<CallStateModel>,
    action: SetScheduleEndTime
  ) {
    ctx.patchState({ scheduleEndTime: action.scheduleEndTime });
  }

  @Action(SetCallFailure)
  setCallFailure(ctx: StateContext<CallStateModel>, action: SetCallFailure) {
    ctx.setState(
      patch<CallStateModel>({
        state: RegisteredType.FAILED
      })
    );
    // TODO: implement better usage of call failure reason
    console.error('CALL FAILURE REASON:', action.reason);
    this.messageService.add({
      severity: 'error',
      summary: this.translate.instant('Server error'),
      detail: action.reason,
      key: 'bottom-actions-toast'
    });
  }

  @Action(SetConnectionId)
  setConnectionId(ctx: StateContext<CallStateModel>, action: SetConnectionId) {
    ctx.setState(
      patch<CallStateModel>({
        connectionId: action.connectionId
      })
    );
  }

  @Action(SetCallDevice)
  setCallDevice(ctx: StateContext<CallStateModel>, action: SetCallDevice) {
    ctx.setState(
      patch<CallStateModel>({
        device: action.device
      })
    );
  }

  @Action(SetCallOperator)
  setCallOperator(ctx: StateContext<CallStateModel>, action: SetCallOperator) {
    ctx.setState(
      patch<CallStateModel>({
        operator: action.operator
      })
    );
  }

  @Action(ClearCallState)
  clearCallState(ctx: StateContext<CallStateModel>) {
    ctx.setState(
      patch<CallStateModel>({
        state: RegisteredType.NOT_REGISTERED,
        connectionId: null,
        device: null,
        operator: null,
        failed: false
      })
    );
  }

  @Selector()
  static getRegisterState(registerState: CallStateModel): RegisteredType {
    console.log('Register state', registerState.state);
    return registerState.state;
  }

  @Selector()
  static getConnectionId(state: CallStateModel): string {
    return state.connectionId;
  }

  @Selector()
  static getDevice(state: CallStateModel): Device | null {
    return state.device;
  }

  @Selector()
  static getScheduleEndTime(state: CallStateModel): Date | null {
    return state.scheduleEndTime;
  }

  @Selector([CallState.getDevice])
  static getDeviceId(device: Device | null): string {
    return device?.id;
  }

  @Selector()
  static getOperator(state: CallStateModel): WebsocketUser | null {
    return state.operator;
  }
}
