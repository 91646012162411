import { BaseMqttService } from './base-mqtt.service';
import {
  AwsIotCoreService,
  MqttSubscription
} from '../iot/aws-iot-core.service';
import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { SetOperatorMqttStatus } from '../../../pages/device/device-control/store/actions/participants.actions';

@Injectable({
  providedIn: 'root'
})
export class QosMqttService
  extends BaseMqttService
  implements MqttSubscription, OnDestroy
{
  private _status = false;

  constructor(
    protected awsIotCoreService: AwsIotCoreService,
    protected readonly store: Store
  ) {
    super(awsIotCoreService);
    console.log('Creating QOS Service');
  }

  get clientId(): string {
    return this.awsIotCoreService.clientId;
  }

  protected subscribe(params: any): void {}

  public subscribeCheck(): void {
    this.awsIotCoreService.subscribeTopic(
      `$aws/events/subscriptions/subscribed/${this.awsIotCoreService.clientId}`,
      async (data, error) => {
        // error case
        if (error !== null) {
          console.error('QOS MQTT service error', error);
          this._status = false;
          this.store.dispatch(new SetOperatorMqttStatus(false));
        }

        if (!this._status) {
          this._status = true;
          this.store.dispatch(new SetOperatorMqttStatus(true));
        }
      }
    );
  }

  public unsubscribeCheck(): void {
    this.awsIotCoreService.unsubscribe(
      `$aws/events/subscriptions/subscribed/${this.clientId}`
    );
  }

  ngOnDestroy(): void {
    console.log('Destroying QOS MQTT Service');
    this.unsubscribeCheck();
  }
}
