import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseResourceService } from './base-resource.service';
import { Constants } from '../../constants';
import { Tag } from '../models/tag.model';

@Injectable({
  providedIn: 'root'
})
export class TagService extends BaseResourceService<Tag> {
  constructor(protected injector: Injector) {
    super('tags', injector, Tag.fromJson);
  }

  createFree(resource: Tag): Observable<Tag> {
    return this.http
      .post(`${Constants.endPoint(this.apiPath)}${Constants.FREE}`, resource)
      .pipe(
        map(this.jsonDataToResource.bind(this)),
        catchError(this.handleError)
      );
  }

  getAllFree(): Observable<Tag[]> {
    return this.http
      .get<Tag[]>(`${Constants.endPoint(this.apiPath)}${Constants.FREE}`)
      .pipe(
        map(this.jsonDataToResources.bind(this)),
        catchError(this.handleError)
      );
  }
}
