/**
 * Created by Wilton O. Ferreira on 04/02/21
 */

import { Observable, ReplaySubject, Subject } from 'rxjs';
import { Constants } from '../../../../constants';
import { Injectable, OnDestroy } from '@angular/core';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { catchError, delay, retryWhen, tap } from 'rxjs/operators';
import { Store } from '@ngxs/store';

import {
  SetWebsocketConnected,
  SetWebsocketDisconnected
} from '../store/actions/websocket.actions';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService implements OnDestroy {
  private socket$: WebSocketSubject<any> | undefined;
  private socketConnected = false;
  private enableToReconnect = true;
  private timeReconnect: any;
  public messages$: Subject<any> = new Subject<any>();
  private _socketConnectionSubject: ReplaySubject<boolean> =
    new ReplaySubject<boolean>(1);
  public socketConnected$: Observable<boolean>;

  constructor(private store: Store) {
    console.log('Start WebsocketService!');
    this.socketConnected$ = this._socketConnectionSubject.asObservable();
    this.enableToReconnect = true;
    this.socketConnected = false;
    this.connect();
    setInterval(() => {
      this.connect();
    }, 5000);
  }

  private connect(): void {
    if (!this.socketConnected) {
      console.log('Try Connection!');
      this.socket$ = this.getNewWebSocket();
      this.socket$
        .asObservable()
        .pipe(
          retryWhen((errors) =>
            errors.pipe(
              tap((err) => console.log(`WebSocket error: `, err)),
              delay(1000)
            )
          ),
          catchError(() => {
            console.error('Error during Websocket reconnection attempt');
            return this.socket$;
          })
        )
        .subscribe(this.messages$);
    }
  }

  private getNewWebSocket(): WebSocketSubject<unknown> {
    // this.close(true);
    return webSocket({
      url: Constants.WS_ENDPOINT,
      openObserver: {
        next: () => {
          console.log(`WEBSOCKET OPEN`);
          this.socketConnected = true;
          this._socketConnectionSubject.next(true);
          this.store.dispatch(new SetWebsocketConnected());
        }
      },
      closeObserver: {
        next: () => {
          console.log(`WEBSOCKET CLOSE`);
          this._socketConnectionSubject.next(false);
          this.store.dispatch(new SetWebsocketDisconnected());
        }
      }
    });
  }

  private reconnect(): void {
    this.socketConnected = false;
    console.log('Close Observable!');
    this.timeReconnect = setTimeout(() => {
      this.connect();
    }, 1000);
  }

  sendMessage = (msg: any) => {
    if (this.socketConnected) {
      this.socket$.next(msg);
    }
  };

  public close(enableToReconnect = false): void {
    console.log('CLOSED INTENTIONALLY!');
    try {
      this.socketConnected = false;
      this.enableToReconnect = enableToReconnect;
      this.socket$.complete();
    } catch (e) {
      console.log('[WebsocketService][socket>>>> closed');
    }
  }

  ngOnDestroy(): void {
    this.close();
    console.log('Destroying data service');
  }

  isOpen(): boolean {
    return this.socketConnected;
  }
}
