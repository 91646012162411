import { count } from 'console';
import { BaseResourceModel } from './base-resource.model';
import { CompanyFormComponent } from '../../pages/company/company-form/company-form.component'

export interface IOption {
  label: string;
  value: string;
}

export interface State extends IOption {
  id: number;
}

export class Address extends BaseResourceModel {
  constructor(
    public id?: string,
    public street?: string,
    public complement?: string,
    public numberValue?: string,
    public zipCode?: string,
    public neighborhood?: string,
    public state?: string,
    public city?: boolean,
  ) {
    super();
  }

  static fromJson(jsonData: any): Address {
    return Object.assign(new Address(), jsonData);
  }

  static getStates(country): State[] {
    let states: State[] = [];
    switch(country){
      case('BR'):
        states.length = 0;
        states.push({ id: 1, label: 'Acre', value: 'AC' });
        states.push({ id: 2, label: 'Alagoas', value: 'AL' });
        states.push({ id: 3, label: 'Amapá', value: 'AP' });
        states.push({ id: 4, label: 'Amazonas', value: 'AM' });
        states.push({ id: 5, label: 'Bahia', value: 'BA' });
        states.push({ id: 6, label: 'Ceará', value: 'CE' });
        states.push({ id: 7, label: 'Distrito Federal', value: 'DF' });
        states.push({ id: 8, label: 'Espírito Santo', value: 'ES' });
        states.push({ id: 9, label: 'Goiás', value: 'GO' });
        states.push({ id: 10, label: 'Maranhão', value: 'MA' });
        states.push({ id: 11, label: 'Mato Grosso', value: 'MT' });
        states.push({ id: 12, label: 'Mato Grosso do Sul', value: 'MS' });
        states.push({ id: 13, label: 'Minas Gerais', value: 'MG' });
        states.push({ id: 14, label: 'Pará', value: 'PA' });
        states.push({ id: 15, label: 'Paraíba', value: 'PB' });
        states.push({ id: 16, label: 'Paraná', value: 'PR' });
        states.push({ id: 17, label: 'Pernambuco', value: 'PE' });
        states.push({ id: 18, label: 'Piauí', value: 'PI' });
        states.push({ id: 19, label: 'Rio de Janeiro', value: 'RJ' });
        states.push({ id: 20, label: 'Rio Grande do Norte', value: 'RN' });
        states.push({ id: 21, label: 'Rio Grande do Sul', value: 'RS' });
        states.push({ id: 22, label: 'Rondônia', value: 'RO' });
        states.push({ id: 23, label: 'Roraima', value: 'RR' });
        states.push({ id: 24, label: 'Santa Catarina', value: 'SC' });
        states.push({ id: 25, label: 'São Paulo', value: 'SP' });
        states.push({ id: 26, label: 'Sergipe', value: 'SE' });
        states.push({ id: 27, label: 'Tocantins', value: 'TO' });
        return states;

      case('US'):
        states.length = 0;
        states.push({ id: 1, label: 'Alabama', value: 'AL' });
        states.push({ id: 2, label: 'Alaska', value: 'AK' });
        states.push({ id: 3, label: 'Arizona', value: 'AZ' });
        states.push({ id: 4, label: 'Arkansas', value: 'AR' });
        states.push({ id: 5, label: 'California', value: 'CA' });
        states.push({ id: 6, label: 'Colorado', value: 'CO' });
        states.push({ id: 7, label: 'Connecticut', value: 'CT' });
        states.push({ id: 8, label: 'Delaware', value: 'DE' });
        states.push({ id: 9, label: 'Florida', value: 'FL' });
        states.push({ id: 10, label: 'Georgia', value: 'GA' });
        states.push({ id: 11, label: 'Hawaii', value: 'HI' });
        states.push({ id: 12, label: 'Idaho', value: 'ID' });
        states.push({ id: 13, label: 'Illinois', value: 'IL' });
        states.push({ id: 14, label: 'Indiana', value: 'IN' });
        states.push({ id: 15, label: 'Iowa', value: 'IA' });
        states.push({ id: 16, label: 'Kansas', value: 'KS' });
        states.push({ id: 17, label: 'Kentucky', value: 'KY' });
        states.push({ id: 18, label: 'Louisiana', value: 'LA' });
        states.push({ id: 19, label: 'Maine', value: 'ME' });
        states.push({ id: 20, label: 'Maryland', value: 'MD' });
        states.push({ id: 21, label: 'Massachusetts', value: 'MA' });
        states.push({ id: 22, label: 'Michigan', value: 'MI' });
        states.push({ id: 23, label: 'Minnesota', value: 'MN' });
        states.push({ id: 24, label: 'Mississippi', value: 'MS' });
        states.push({ id: 25, label: 'Missouri', value: 'MO' });
        states.push({ id: 26, label: 'Montana', value: 'MT' });
        states.push({ id: 27, label: 'Nebraska', value: 'NE' });
        states.push({ id: 28, label: 'Nevada', value: 'NV' });
        states.push({ id: 29, label: 'New Hampshire', value: 'NH' });
        states.push({ id: 30, label: 'New Jersey', value: 'NJ' });
        states.push({ id: 31, label: 'New Mexico', value: 'NM' });
        states.push({ id: 32, label: 'New York', value: 'NY' });
        states.push({ id: 33, label: 'North Carolina', value: 'NC' });
        states.push({ id: 34, label: 'North Dakota', value: 'ND' });
        states.push({ id: 35, label: 'Ohio', value: 'OH' });
        states.push({ id: 36, label: 'Oklahoma', value: 'OK' });
        states.push({ id: 37, label: 'Oregon', value: 'OR' });
        states.push({ id: 38, label: 'Pennsylvania', value: 'PA' });
        states.push({ id: 39, label: 'Rhode Island', value: 'RI' });
        states.push({ id: 40, label: 'South Carolina', value: 'SC' });
        states.push({ id: 41, label: 'South Dakota', value: 'SD' });
        states.push({ id: 42, label: 'Tennessee', value: 'TN' });
        states.push({ id: 43, label: 'Texas', value: 'TX' });
        states.push({ id: 44, label: 'Utah', value: 'UT' });
        states.push({ id: 45, label: 'Vermont', value: 'VT' });
        states.push({ id: 46, label: 'Virginia', value: 'VA' });
        states.push({ id: 47, label: 'Washington', value: 'WA' });
        states.push({ id: 48, label: 'West Virginia', value: 'WV' });
        states.push({ id: 49, label: 'Wisconsin', value: 'WI' });
        states.push({ id: 50, label: 'Wyoming', value: 'WY' });
        return states;

      default:
        return states;
    }

  }

  static getPostalCode(country){
    switch(country){
      case('BR'):
        return 'CEP'
      case('US'):
        return 'Zip Code'
      default:
        return 'Postal Code'
    }
  }

}
