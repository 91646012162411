import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { BaseResourceUploadService } from '../../services/base-resource-upload.service';
import { S3FileService } from '../../services/s3-file.service';
import { interval } from 'rxjs';
import { Upload } from '../../models/upload.model';
import { UploaderSender } from '../../models/uploader-sender.model';
import { Router } from '@angular/router';
import { Constants } from 'src/app/constants';

@Component({
  selector: 'app-image-upload-type',
  templateUrl: './image-upload-type.component.html',
  styleUrls: ['./image-upload-type.component.scss']
})
export class ImageUploadTypeComponent extends FieldType implements OnInit {
  seconds = interval(1000);
  files: File[] = [];
  image: string | ArrayBuffer = 'assets/images/pattern1.png';
  file: any;
  private prfImg: any = null;
  private conut = 0;
  private findEnable = true;
  private currentRoute: string = null;

  constructor(
    private router: Router,
    public baseResourceUploadService: BaseResourceUploadService,
    public s3FileService: S3FileService
  ) {
    super();
    this.currentRoute = this.router.url;
  }

  protected loadFile(value: string, modelName: string): void {
    if (value) {
      if (value.includes(Constants.CDN_S3_MEDIAS)) {
        const regex = /\/([^\/?]+)\?/;
        const fileName = value.match(regex)[1];
        this.image = value;
        this.to.profileImage = fileName;
      } else if (!value.includes('https://') && !value.includes('http://')) {
        this.s3FileService
          .findByName(value, modelName)
          .subscribe((resource) => {
            this.image = resource.url;
            this.to.profileImage = resource.fileName;
          });
      }
    }
    return null;
  }

  ngOnInit(): void {
    this.seconds.subscribe((v) => {
      this.conut++;
      if (
        (this.model != null &&
          this.model[this.key.toString()] !== undefined &&
          this.model[this.key.toString()] != null &&
          this.model[this.key.toString()] !== '' &&
          this.prfImg !== this.model[this.key.toString()] &&
          this.findEnable)
      ) {
        this.prfImg = this.model[this.key.toString()];
        this.findEnable = false;
        this.loadFile(this.model[this.key.toString()], this.to.modelName);
      }
    });
  }

  getFile(event) {
    const files = event.target.files;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      for (const file of files) {
        if (file.property === undefined) {
          const types = file.type.split('/');
          const type = types[1];
          this.baseResourceUploadService.getBase64(file, (base) => {
            this.image = base;
            const mediaData = base.toString();
            const modelName = this.to.modelName;
            // Prepare the request body for the media management gateway (S3MediasAPI)
            if (this.currentRoute.includes('/media')) {
              this.model['data'] = mediaData;
              this.model['type'] = type;
              this.model['modelName'] = modelName;
            } else {
              const uploader = new UploaderSender();
              uploader.data = mediaData;
              uploader.modelName = modelName;
              uploader.type = type;
              this.baseResourceUploadService.create(uploader).subscribe(
                (resourceReceived) => {
                  const up = Upload.fromJson(resourceReceived);
                  file.property = up.fileName;
                  this.model[this.key.toString()] = up.fileName;
                  this.to.changeValue(up.fileName, this.key);
                },
                (error) => console.log(error)
              );
            }
          });
        } else {
          if (file.property) {
            this.model[this.key.toString()] = file.property;
            this.to.changeValue(file.property, this.key);
          }
        }
      }
    };
    reader.onerror = (error) => {
      console.log('Error: ', error);
    };
  }
}
