import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';

@Directive({ selector: '[myHideIfUnauthorized]' })
export class MyHideIfUnauthorizedDirective implements OnInit {
  @Input('myHideIfUnauthorized') permission: string[]; // Required permission passed in

  constructor(
    private el: ElementRef,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {
    if (!this.authenticationService.hasPermission(this.permission)) {
      try {
        this.el.nativeElement.style.display = 'none';
      } catch (e) {
        console.log(e);
      }
    }
  }
}
