import { Component, Injector, OnInit, OnDestroy } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { ActivatedRoute } from '@angular/router';
import { CompanyService } from '../shared/CompanyService';
import { Address } from '../../../shared/models/address.model';
import { CompanyFormComponent } from '../company-form/company-form.component';
import { Company } from '../../../shared/models/company.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-company-view',
  template: `
    <formly-form [model]="model" [fields]="fields" [options]=""> </formly-form>
  `
})
export class CompanyViewComponent extends FieldType implements OnInit, OnDestroy {
  ufField;
  public countrySubject: Subscription | undefined;

  infoCompany = {
    fieldGroupClassName: 'row',
    fieldGroup: [
      {
        className: 'col-12 col-md-6',
        type: 'outputLabel',
        key: 'businessAccount',
        templateOptions: {
          label: 'Is Business Account?',
          type: 'boolean'
        }
      },
      {
        className: 'col-12 col-md-6',
        type: 'outputLabel',
        key: 'name',
        templateOptions: {
          label: 'Name',
          type: 'text'
        }
      },
      {
        className: 'col-12 col-md-6',
        type: 'outputLabel',
        key: 'description',
        templateOptions: {
          label: 'Description',
          type: 'text'
        }
      },
      {
        className: 'col-12 col-md-6',
        type: 'outputLabel',
        key: 'country',
        templateOptions: {
          label: 'Country',
          type: 'select',
          valueProp: 'value',
          labelProp: 'label',
          options: Company.getCountries()
        }
      },
      {
        className: 'col-12 col-md-6',
        type: 'outputLabel',
        key: 'entityId',
        templateOptions: {
          label: 'Entity Number',
          type: 'text'
        }
      },
      {
        className: 'col-12 col-md-6',
        type: 'outputLabel',
        key: 'email',
        templateOptions: {
          label: 'E-mail',
          type: 'text'
        }
      },
      {
        className: 'col-12 col-md-6',
        type: 'outputLabel',
        key: 'phone',
        templateOptions: {
          label: 'Phone',
          type: 'text'
        }
      },
      {
        className: 'col-12 col-md-6',
        type: 'outputLabel',
        key: 'cellphone',
        templateOptions: {
          label: 'Cell Phone',
          type: 'text'
        }
      },
      {
        className: 'col-12 col-md-6',
        type: 'outputLabel',
        key: 'isEnable',
        templateOptions: {
          label: 'Is Enabled?',
          type: 'boolean'
        }
      }
    ]
  };

  infoAddress = {
    key: 'address',
    fieldGroupClassName: 'row',
    fieldGroup: [
      {
        className: 'col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6',
        type: 'outputLabel',
        key: 'street',
        templateOptions: {
          label: 'Street',
          type: 'text'
        }
      },
      {
        className: 'col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-2',
        type: 'outputLabel',
        key: 'numberValue',
        templateOptions: {
          label: 'Number',
          type: 'text'
        }
      },
      {
        className: 'col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4',
        type: 'outputLabel',
        key: 'neighborhood',
        templateOptions: {
          label: 'Neighborhood',
          type: 'text'
        }
      },
      {
        className: 'col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4',
        type: 'outputLabel',
        key: 'complement',
        templateOptions: {
          label: 'Complement',
          type: 'text'
        }
      },
      {
        className: 'col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4',
        type: 'outputLabel',
        key: 'city',
        templateOptions: {
          label: 'City',
          type: 'text'
        }
      },
      {
        className: 'col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-2',
        type: 'outputLabel',
        key: 'state',
        templateOptions: {
          label: 'State',
          type: 'select',
          valueProp: 'value',
          labelProp: 'label',
          options: Address.getStates('BR')
        }
      },
      {
        className: 'col-xs-12 col-sm-12 col-md-6 lg-6 col-xl-2',
        type: 'outputLabel',
        key: 'postalCode',
        templateOptions: {
          label: 'Postal Code',
          type: 'text'
        }
      }
    ]
  };

  infoLatLong = {
    fieldGroupClassName: 'row',
    fieldGroup: [
      {
        className: 'col-12 col-md-6',
        type: 'outputLabel',
        key: 'latitude',
        templateOptions: {
          label: 'Latitude',
          type: 'text'
        }
      },
      {
        className: 'col-12 col-md-6',
        type: 'outputLabel',
        key: 'longitude',
        templateOptions: {
          label: 'Longitude',
          type: 'text'
        }
      }
    ]
  };

  fields: FormlyFieldConfig[] = [
    {
      className: 'section-label text-center',
      template: '<div><strong>COMPANY INFORMATION</strong></div><br>'
    },
    this.infoCompany,
    {
      className: 'section-label',
      template: '<div>&nbsp;</div><hr />'
    },
    {
      className: 'section-label text-center',
      template: '<div><strong>COMPANY ADDRESS</strong></div><br>'
    },
    this.infoAddress,
    this.infoLatLong
  ];

  protected route: ActivatedRoute;

  constructor(
    protected injector: Injector,
    protected companyService: CompanyService,
    protected companyFormComponent: CompanyFormComponent
  ) {
    super();
    this.route = this.injector.get(ActivatedRoute);
  }
  ngOnInit(): void {
    this.ufField = this.infoAddress.fieldGroup.find(
      (item) => item.key === 'state'
    );

    this.countrySubject = this.companyService.countryValueSubject.subscribe(() => {
      this.ufField.templateOptions.options = Address.getStates(
        this.companyFormComponent.model.country
      );
    });
  }

  ngOnDestroy(){
    if (this.countrySubject) {
      this.countrySubject.unsubscribe();
    }
  }

  getData(data): string {
    if (data !== undefined) {
      const d = data.split('-');
      return `${d[0]}-${d[1]}-${d[2]}`;
    } else {
      return '';
    }
  }
}
