import { Injectable, Injector } from '@angular/core';
import { BaseResourceService } from '../../../shared/services/base-resource.service';
import { OperatorType } from '../../../shared/models/operatorType.model';

@Injectable({
  providedIn: 'root'
})
export class OperatorTypeService extends BaseResourceService<OperatorType> {
  constructor(protected injector: Injector) {
    super('operatorsTypes', injector, OperatorType.fromJson);
  }
}
