import { Injectable } from '@angular/core';
import {
  AwsIotCoreService,
  MqttSendMessage,
  OnInitMqttService
} from '../iot/aws-iot-core.service';
import { BaseMqttService } from './base-mqtt.service';

@Injectable({
  providedIn: 'root'
})
export class CommandMqttService
  extends BaseMqttService
  implements MqttSendMessage, OnInitMqttService
{
  constructor(protected awsIotCoreService: AwsIotCoreService) {
    super(awsIotCoreService);
  }

  protected subscribe(): void {}
}
