import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Tag } from '../../models/tag.model';
import { TagService } from '../../services/tag.service';

@Component({
  selector: 'app-select-multiple-type',
  templateUrl: './select-multiple-type.component.html',
  styleUrls: ['./select-multiple-type.component.css']
})
export class SelectMultipleTypeComponent extends FieldType {
  searchTerm = '';

  constructor(public tagService: TagService) {
    super();
  }

  async onFilterInputChanged(searchTerm: string) {
    this.searchTerm = searchTerm;
    let options = (await this.to.options) as any[];
    options = options.filter((option) => {
      return option.value !== '';
    });
    options.push(new Tag('', searchTerm));

    this.to.options = options;
  }

  async createNewTag(): Promise<void> {
    const newTag = new Tag();
    newTag.name = this.searchTerm;
    this.searchTerm = '';
    let createTerm = false;
    this.to.options.forEach((option: any) => {
      if (
        option.name
          .toString()
          .toLowerCase()
          .includes(this.searchTerm.toLowerCase())
      ) {
        if (option.id === '') {
          createTerm = true;
          return;
        }
      }
    });
    if (createTerm) {
      this.tagService.createFree(newTag).subscribe(
        async (resourceReceived: Tag) => {
          const model: string[] = this.model[this.key.toString()];
          model.forEach((item, index) => {
            if (item === '') {
              model[index] = resourceReceived.id;
              return;
            }
          });
          this.model[this.key.toString()] = model;
          const values = [];
          for (const option of this.to.options as any[]) {
            if (option.value === '') {
              option.id = resourceReceived.id;
              return;
            }
          }
          // this.to.options = options;
        },
        (error) => {
          console.log('error tag created');
        }
      );
    }
  }

  async clickOption($event: MouseEvent): Promise<void> {
    $event.stopImmediatePropagation();
    await this.onFilterInputChanged(this.searchTerm);
  }

  private openMyModal(event): void {
    document.querySelector('#' + event).classList.add('md-show');
  }

  add(event): void {
    this.openMyModal(event);
  }
}
