import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-wizard-button-next-type',
  templateUrl: './wizard-button-next-type.component.html',
  styleUrls: ['./wizard-button-next-type.component.css']
})
export class WizardButtonNextTypeComponent extends FieldType {
  onClick($event): void {
    if (this.to.onClick) {
      this.to.onClick($event);
    }
  }
}
