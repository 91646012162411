<div
  class="screenshot-container p-1"
  [ngClass]="selected && 'selected'"
  (mouseover)="showHoverOptions = true"
  (mouseleave)="showHoverOptions = false"
>
  <img
    [src]="
      screenshot?.image && screenshot?.image.includes(cdnS3Medias)
        ? screenshot?.image
        : image
    "
    class="img-responsive img-thumbnail"
    (click)="emitClickedScreenshot(screenshot)"
    loading="lazy"
  />
  <div
    *ngIf="showHoverOptions || selected"
    class="icon-container check-container"
    [ngClass]="selected && 'selected'"
  >
    <i class="check-icon pi pi-check" (click)="emitClickedIcon(screenshot)"></i>
  </div>
</div>
