/**
 * Created by Guilherme Beneti Martins on 26/09/2022
 */

import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Device } from '../../../../../shared/models/device.model';
import { Injectable } from '@angular/core';
import { DeviceService } from '../../services/device.service';
import { IsLoadingService } from '@service-work/is-loading';
import {
  FetchDevices,
  UpdateMqttOnlineStatus
} from '../actions/devices.actions';
import { DevicesStateModel } from '../models/devices.model';

@State<DevicesStateModel>({
  name: 'devices',
  defaults: {
    items: []
  }
})
@Injectable({
  providedIn: 'root'
})
export class DevicesState {
  constructor(
    protected deviceService: DeviceService,
    protected isLoadingService: IsLoadingService
  ) {}

  @Action(FetchDevices)
  protected async fetchDevices(ctx: StateContext<DevicesStateModel>) {
    console.log('Fetching devices');
    this.isLoadingService
      .add(this.deviceService.getAll().toPromise(), { key: 'fetching-devices' })
      .then((devices) => {
        ctx.getState().items.forEach((oldDevice) => {
          devices.find(
            (newDevice) => newDevice.id === oldDevice.id
          ).mqttOnline = oldDevice.mqttOnline;
        });
        ctx.patchState({ items: devices });
      })
      .catch((error) => console.error('Could not fetch devices', error));
  }

  @Action(UpdateMqttOnlineStatus)
  updateMqttOnlineStatus(
    ctx: StateContext<DevicesStateModel>,
    action: UpdateMqttOnlineStatus
  ) {
    const devices = ctx.getState().items.map((device: Device) => {
      const newDevice = JSON.parse(JSON.stringify(device));
      if (newDevice.id === action.payload.deviceId) {
        newDevice.mqttOnline = action.payload.newMqttOnline;
      }
      return newDevice;
    });
    ctx.patchState({ items: devices });
  }

  @Selector()
  static getAllDevices(state: DevicesStateModel): Device[] {
    return state.items;
  }

  @Selector([DevicesState.getAllDevices])
  static getDeviceById(devices: Device[]): (deviceId: string) => Device {
    return (deviceId: string) =>
      devices.find((device) => device.id == deviceId);
  }

  @Selector([DevicesState.getDeviceById])
  static isDeviceMqttOnline(
    getDeviceById: (deviceId: string) => Device
  ): (deviceId: string) => boolean {
    return (deviceId: string) => getDeviceById(deviceId)?.mqttOnline;
  }

  @Selector([DevicesState.getAllDevices])
  static getAllDevicesQtty(devices: Device[]): number {
    return devices.length;
  }
}
