<div class="form-group">
  <label class="ng-star-inserted"
    >{{ to.label }} {{ to.required ? '*' : '' }}</label
  >
  <input
    currencyMask
    class="ng-star-inserted form-control"
    type="text"
    [formControl]="formControl"
    [formlyAttributes]="field"
    [min]="to.min"
  />
</div>
