import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { CompanyFormComponent } from '../../../pages/company/company-form/company-form.component';

@Component({
  selector: 'app-zipcode-mask-type',
  templateUrl: './zipcode-mask-type.component.html',
  styleUrls: ['./zipcode-mask-type.component.css']
})
export class ZipcodeMaskTypeComponent extends FieldType {
  constructor(
    public companyFormComponent: CompanyFormComponent
  ) {
    super();
  }

  postalCodeMaskType(country): string {
    switch(country){
      case('BR'):
        return '00000-000'
      case('US'):
        return '00000-0000'
      default:
        return '00000-0000'
    }
  }
}
