<div class="container m-t-20">
  <div class="row align-items-center">
    <div class="col-12 align-self-center">
      <div class="col text-center">
        <h3>{{ to.label }}</h3>
      </div>
      <p-pickList
        [source]="to.source"
        [target]="to.target"
        [sourceHeader]="to.sourceHeader || 'available' | translate"
        [targetHeader]="to.targetHeader || 'selected' | translate"
        [responsive]="true"
        [filterBy]="to.filterBy || 'description'"
        [dragdrop]="to.dragdrop || 'true'"
        [styleClass]="to.styleClass || { '': 'black' }"
        [sourceStyle]="to.sourceStyle || { 'min-height': '480px' }"
        [targetStyle]="to.targetStyle || { 'min-height': '480px' }"
        [sourceFilterPlaceholder]="
          to.sourceFilterPlaceholder || ('Search available' | translate)
        "
        [targetFilterPlaceholder]="
          to.targetFilterPlaceholder || ('Search selected' | translate)
        "
      >
        <ng-template let-object pTemplate="item">
          <div class="ui-helper-clearfix">
            <div>{{ object[to.filterBy || 'description'] }}</div>
          </div>
        </ng-template>
      </p-pickList>
    </div>
  </div>
</div>
