import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MobileRedirectGuard implements CanActivate {
  constructor(private platform: Platform, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const mobileView =
      sessionStorage.getItem('mobileView') === 'false' ? false : true;
    if ((this.platform.ANDROID || this.platform.IOS) && mobileView) {
      sessionStorage.setItem('redirectUrl', state.url);
      this.router.navigate(['/mobile-store']);
      return false;
    }

    return true;
  }
}
