<p-dialog
  [header]="('callSettings' | translate) + ' - ' + device?.name"
  [(visible)]="showModal"
  (onHide)="closeModal($event)"
  [breakpoints]="{ '960px': '75vw', '640px': '100vw' }"
  [style]="{ width: '70vw', maxWidth: '800px', overflow: 'auto' }"
>
  <div class="container">
    <div class="precall-content">
      <div class="video-container">
        <video autoplay playsinline id="video" #video></video>
      </div>
      <div class="precall-controls">
        <div class="select-mic">
          <p-dropdown
            #mic
            [options]="mics$ | async"
            optionLabel="label"
            appendTo="body"
            placeholder="{{ 'selectMicrophone' | translate }}"
            [(ngModel)]="microphoneSelected"
            (onChange)="playMicrophone(mic.value)"
          ></p-dropdown>
        </div>
        <div class="control-buttons">
          <a class="mute-mic" role="button" (click)="mute()">
            <i *ngIf="sendAudio$ | async" class="fa fa-microphone"></i>
            <i
              *ngIf="(sendAudio$ | async) === false"
              class="fa fa-microphone red-icon"
            ></i>
          </a>
          <div class="refresh-mic">
            <a role="button" (click)="loadDevices()"
              ><i class="fa fa-refresh"></i
            ></a>
          </div>
        </div>
      </div>
      <div class="precall-controls">
        <div class="select-camera">
          <p-dropdown
            #cam
            [options]="cams$ | async"
            optionLabel="label"
            placeholder="{{ 'selectCamera' | translate }}"
            [(ngModel)]="cameraSelected"
            (onChange)="playVideo(cam.value)"
            showClear="true"
            appendTo="body"
          ></p-dropdown>
        </div>
        <div class="control-buttons">
          <a class="hide-camera" role="button" (click)="handleVideo()">
            <i *ngIf="sendVideo$ | async" class="pi pi-video"></i>
            <i
              *ngIf="(sendVideo$ | async) === false"
              class="pi pi-video red-icon"
            ></i>
          </a>
          <div class="refresh-camera">
            <a role="button" (click)="loadDevices()"
              ><i class="fa fa-refresh"></i
            ></a>
          </div>
        </div>
      </div>
    </div>
    <div class="precall-checkboxes">
      <div class="checkboxes">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            [checked]="cameraCheck$ | async"
            id="camera"
            #camera
            disabled
          />
          <label class="form-check-label" for="camera">
            {{ 'cameraChecked' | translate }}
          </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            [checked]="microphoneCheck$ | async"
            id="microphone"
            #microphone
            disabled
          />
          <label class="form-check-label" for="microphone">
            {{ 'microphoneChecked' | translate }}
          </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            [checked]="operatorMqttCheck$ | async"
            id="mqtt"
            #mqtt
            disabled
          />
          <label class="form-check-label" for="mqtt"
            >{{ 'OperatorMqttChecked' | translate }}
          </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            [checked]="deviceMqttCheck$ | async"
            id="mqtt"
            #mqtt
            disabled
          />
          <label class="form-check-label" for="mqtt"
            >{{ 'DeviceMqttChecked' | translate }}
          </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            [checked]="webSocketCheck$ | async"
            id="webSocket"
            #webSocket
            disabled
          />
          <label class="form-check-label" for="webSocket"
            >{{ 'webSocketChecked' | translate }}
          </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            [checked]="(webRTCCheck$ | async) && (webSocketCheck$ | async)"
            id="webrtc"
            disabled
          />
          <label class="form-check-label" for="webrtc">
            {{ 'webTRCChecked' | translate }}
          </label>
        </div>
      </div>
      <div
        class="circle-singleline d-flex align-items-center justify-content-center"
      >
        {{ participantsAmount$ | async }} {{ 'participants' | translate }}
      </div>
    </div>
  </div>
  <div class="col-12 text-center">
    <button
      class="btn btn-primary"
      [hidden]="(callReady$ | async) === false"
      (click)="call()"
    >
      {{ 'connect' | translate | uppercase }}
    </button>
  </div>
</p-dialog>
