import { BaseResourceModel } from './base-resource.model';
import { Credential } from './credential.model';
import { Company } from './company.model';
import { Media } from './media.model';
import { Operator } from './operator.model';

export class Device extends BaseResourceModel {
  constructor(
    public id?: string,
    public name?: string,
    public description?: string,
    public version?: string,
    public image?: string,
    public longitude?: number,
    public latitude?: number,
    public credentialId?: string,
    public credential?: Credential,
    public companyId?: string,
    public company?: Company,
    public operators?: Operator[],
    public guest?: boolean[],
    public medias?: Media[],
    public ordenation?: number,
    public accessKey?: string,
    public mqttOnline?: boolean,
    public isCertified?: boolean
  ) {
    super();
  }

  static fromJson(jsonData: any): Device {
    return Object.assign(new Device(), jsonData);
  }
}
