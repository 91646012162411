import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { Table } from 'primeng/table/table';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Constants } from '../../../constants';
import { DeviceTableViewModel } from '../../../pages/device/shared/store/queries/devices.queries';

@Component({
  selector: 'app-primetable',
  templateUrl: './primetable.component.html',
  styleUrls: ['./primetable.component.scss']
})
export class PrimetableComponent {
  @ViewChild('dt') table: Table;
  @Input() title = 'Lista de items';
  @Input() currentPageReportTemplate =
    'Mostrando {first} de {last} de {totalRecords}';
  @Input() columns: any[] = [];
  @Input() rows: any[] = [];
  @Input() rowStyle: string;
  @Input() urlAPI = '';
  @Input() data: Observable<DeviceTableViewModel[]>;
  @Input() loading = false;
  @Input() limit = 5;
  @Input() count = 50;
  @Input() first = 10;
  @Input() paginator = true;
  @Input() urlAction = '';
  @Input() hasDeleteAll = true;
  @Input() isSearch = false;
  @Input() buttons: any[] = [];
  @Output() loadItems = new EventEmitter(true);
  @Output() editItem = new EventEmitter(true);
  @Output() deleteItem = new EventEmitter(true);
  @Output() deleteAll = new EventEmitter(true);
  @Output() seletedItem = new EventEmitter(true);
  @Output() searchItem = new EventEmitter();
  @Output() buttonEvent = new EventEmitter();

  fields: string[] = [];

  @ViewChild('input', { static: true }) input: ElementRef;

  search = '';
  dataSelected: any[] = [];
  offset = 0;

  public consoleMessages: string[] = [];
  userQuestionUpdate = new Subject<string>();
  imagePath: string | SVGImageElement;

  constructor() {
    this.imagePath = Constants.IMAGE_PATH;
    console.log('ROWS DE DENTRO', this.data);
    this.fields = this.columns.map((items: any) => items.value);
    this.userQuestionUpdate
      .pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe((value) => {
        console.log('*** VALUE', value);
        this.searchItem.emit(value);
      });
  }

  eventButton(data, item): void {
    console.log('*** DATA', data);
    console.log('*** EVENT', item.event);
    const params = { data, event: item.event };
    this.buttonEvent.emit(params);
  }

  getSearch(value: any) {
    this.searchItem.emit(value);
  }

  excluir(item): void {
    this.deleteItem.emit(item);
  }

  editar(item): void {
    this.editItem.emit(item);
  }

  excluirAll(): void {
    this.deleteAll.emit(this.dataSelected);
  }

  onActivityChange(event, fieldName: string, matchMode: string): void {
    const value = event.target.value;
    if (value && value.trim().length) {
      const activity = parseInt(value);

      if (!isNaN(activity)) {
        this.table.filter(activity, fieldName, matchMode);
      }
    }
  }

  loadLazy($event) {
    // console.log('lazy load event', $event);
    this.loadItems.emit($event);
  }

  onDateSelect(value, fieldName, matchMode) {
    this.table.filter(this.formatDate(value), fieldName, matchMode);
  }

  formatDate(date) {
    let month = date.getMonth() + 1;
    let day = date.getDate();

    if (month < 10) {
      month = '0' + month;
    }

    if (day < 10) {
      day = '0' + day;
    }

    return date.getFullYear() + '-' + month + '-' + day;
  }

  onMultiSelectChange(event, fieldName, matchMode) {
    this.table.filter(event.value, fieldName, matchMode);
  }

  buttonClick(event) {
    console.log('*** EVENT BUTTON', event);
  }

  rowStyleProcess(data: any): string {
    if (this.rowStyle !== undefined && this.rowStyle !== null) {
      const rowStyleParsed = JSON.parse(this.rowStyle);
      const value = data[rowStyleParsed.copare.property];
      const result = rowStyleParsed.metch.filter(
        (m) => m.value.toUpperCase() === value.toUpperCase()
      );
      return result[0].class ?? '';
    } else {
      return '';
    }
  }

  checkValue(image: string | SVGImageElement): any {
    return image !== undefined && image !== null
      ? `thumbnails/${image}`
      : 'noimage.png';
  }
}
