<label>{{ to.label }}{{ to.required ? '*' : '' }}</label>
<!--<div class="row justify-content-between m-b-5">
    <label class="col-9">{{to.label}}{{to.required ? '*' : ''}}</label>
    <div class="col-3">
        <button (click)="add('tagAdd')" type="button" class="btn btn-sm btn-primary">add +</button>
    </div>
</div>-->

<ng-select
  [ngClass]="'ng-select'"
  [options]="to.options"
  [formControl]="formControl"
  [placeholder]="to.placeholder"
  [formlyAttributes]="field"
  [multiple]="true"
  (click)="clickOption($event)"
  (filterInputChanged)="onFilterInputChanged($event)"
  (keydown.enter)="$event.stopImmediatePropagation(); createNewTag()"
>
</ng-select>
