<!-- Page-header start -->
<div class="" *ngFor="let breadcrumb of breadcrumbs; let last = last">
  <div class="page-header card" *ngIf="breadcrumb.status && last">
    <div class="row align-items-center" *ngIf="last">
      <div class="col-lg-8">
        <div class="page-header-title">
          <span *ngFor="let breadcrumb of breadcrumbs; let last = last"
            ><i class="{{ breadcrumb.icon }}" *ngIf="last"></i
          ></span>
          <div class="d-inline">
            <span
              class="d-block"
              *ngFor="let breadcrumb of breadcrumbs; let last = last"
              ><h4 *ngIf="last">
                {{ breadcrumb.label | translate : { value: breadcrumb.label } }}
              </h4></span
            >
            <span class="text-muted" *ngIf="breadcrumb.caption">{{
              breadcrumb.caption | translate : { value: breadcrumb.caption }
            }}</span>
          </div>
        </div>
      </div>
      <div class="col-lg-4 d-flex justify-content-end">
        <div class="page-header-breadcrumb">
          <ul class="breadcrumb m-0">
            <li class="breadcrumb-item">
              <a [routerLink]="'/'">
                <i class="icofont icofont-home"></i>
              </a>
            </li>
            <li class="breadcrumb-item" *ngFor="let breadcrumb of breadcrumbs">
              <a
                [ngClass]="breadcrumb.status === false ? 'not-active' : ''"
                [routerLink]="breadcrumb.url"
                >{{
                  breadcrumb.label | translate : { value: breadcrumb.label }
                }}</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Page-header end -->
