import { BaseResourceModel } from './base-resource.model';

export class UploaderSender extends BaseResourceModel {
  constructor(
    public modelName?: string,
    public type?: string,
    public data?: string
  ) {
    super();
  }

  static fromJson(jsonData: any): UploaderSender {
    return Object.assign(new UploaderSender(), jsonData);
  }
}
