import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { ISchedulesStateModel } from './schedule-event.model';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import {
  AddNewSchedule,
  GetAllSchedules,
  GetSchedulesByPagination,
  RemoveSchedule,
  SetScheduleForm,
  SetSelectedScheduleId,
  UpdateSchedule
} from './schedule.actions';
import { EventInput } from '@fullcalendar/core';
import { ScheduleService } from '../shared/schedule.service';
import { Schedule } from 'src/app/shared/models/schedule.model';
import { MessageService } from 'primeng/api';

@State<ISchedulesStateModel>({
  name: 'schedule',
  defaults: {
    schedules: [],
    scheduleEvents: [],
    selectedScheduleId: null,
    scheduleForm: {
      description: '',
      email: '',
      guestName: '',
      startAt: null,
      endAt: null,
      selectedDevice: null,
      title: ''
    },
    paginationCount: null
  }
})
@Injectable({
  providedIn: 'root'
})
export class ScheduleState {
  constructor(
    protected scheduleService: ScheduleService,
    protected messageService: MessageService
  ) {}

  @Action(GetAllSchedules)
  protected async getAllSchedules(
    ctx: StateContext<ISchedulesStateModel>,
    action: GetAllSchedules
  ) {
    this.scheduleService
      .getByDate(action.startAt, action.endAt)
      .toPromise()
      .then((schedules: Schedule[]) => {
        ctx.setState(
          patch<ISchedulesStateModel>({
            schedules: schedules,
            scheduleEvents: schedules.map((schedule: Schedule) =>
              this._scheduleToEvent(schedule)
            )
          })
        );
      })
      .catch((error) => {
        this.showMessage(error, 'error', "Cannot get schedules");
      });
  }

  @Action(GetSchedulesByPagination)
  protected async getSchedulesByPagination(
    ctx: StateContext<ISchedulesStateModel>,
    action: GetSchedulesByPagination
  ) {
    this.scheduleService
      .getByPagination(action.start, action.limit, action.search)
      .toPromise()
      .then(({ count, schedules }) => {
        ctx.setState(
          patch<ISchedulesStateModel>({
            schedules: schedules,
            scheduleEvents: schedules.map((schedule: Schedule) =>
              this._scheduleToEvent(schedule)
            ),
            paginationCount: count
          })
        );
      })
      .catch((error) => {
        this.showMessage(error, 'error', "Cannot get schedules");
      });
  }

  @Action(AddNewSchedule)
  protected async addNewSchedule(
    ctx: StateContext<ISchedulesStateModel>,
    action: AddNewSchedule
  ) {
    this.scheduleService
      .create({
        device: action.scheduleForm.selectedDevice,
        startEventAt: action.scheduleForm.startAt,
        endEventAt: action.scheduleForm.endAt,
        companyId: this.scheduleService.getAuthenticationCurrent.companyId,
        guest: {
          email: action.scheduleForm.email,
          name: action.scheduleForm.guestName
        },
        note: action.scheduleForm.description,
        title: action.scheduleForm.title
      })
      .toPromise()
      .then((newSchedule: Schedule) => {
        ctx.setState(
          patch<ISchedulesStateModel>({
            schedules: append<Schedule>([newSchedule]),
            scheduleEvents: append<EventInput>([
              this._scheduleToEvent(newSchedule)
            ])
          })
        );
        this.showMessage(
          'Schedule created!',
          'success',
          'Schedule created successfully'
        );
      })
      .catch((error) => {
        this.showMessage(error, 'error', 'Error while creating schedule');
      });
  }

  @Action(SetSelectedScheduleId)
  protected setSelectedScheduleId(
    ctx: StateContext<ISchedulesStateModel>,
    action: SetSelectedScheduleId
  ) {
    ctx.setState(
      patch<ISchedulesStateModel>({
        selectedScheduleId: action.scheduleId
      })
    );
  }

  @Action(UpdateSchedule)
  protected async updateSchedule(
    ctx: StateContext<ISchedulesStateModel>,
    action: UpdateSchedule
  ) {
    this.scheduleService
      .update({
        id: action.scheduleId,
        device: action.scheduleForm.selectedDevice,
        startEventAt: action.scheduleForm.startAt,
        endEventAt: action.scheduleForm.endAt,
        guest: {
          name: action.scheduleForm.guestName,
          email: action.scheduleForm.email
        },
        note: action.scheduleForm.description,
        title: action.scheduleForm.title
      })
      .toPromise()
      .then((newSchedule) => {
        ctx.setState(
          patch<ISchedulesStateModel>({
            schedules: updateItem(
              (item) => item.id === action.scheduleId,
              patch<Schedule>(newSchedule)
            ),
            scheduleEvents: updateItem(
              (item) => item.id === action.scheduleId,
              patch<EventInput>(this._scheduleToEvent(newSchedule))
            )
          })
        );
        this.showMessage(
          'Schedule updated!',
          'success',
          'Schedule updated successfully'
        );
      })
      .catch((error) => {
        this.showMessage(error, 'error', 'Error while updating schedule');
      });
  }

  @Action(RemoveSchedule)
  protected async removeSchedule(
    ctx: StateContext<ISchedulesStateModel>,
    action: RemoveSchedule
  ) {
    await this.scheduleService
      .delete(action.scheduleId)
      .toPromise()
      .then(() => {
        ctx.setState(
          patch<ISchedulesStateModel>({
            schedules: removeItem((item) => item.id === action.scheduleId),
            scheduleEvents: removeItem((item) => item.id === action.scheduleId)
          })
        );
        this.showMessage(
          'Schedule removed',
          'info',
          'Schedule deleted successfully'
        );
      })
      .catch((error) => {
        this.showMessage(error, 'error', 'Error while deleting schedule');
      });
  }

  @Action(SetScheduleForm)
  protected setScheduleForm(
    ctx: StateContext<ISchedulesStateModel>,
    action: SetScheduleForm
  ) {
    ctx.setState(
      patch<ISchedulesStateModel>({
        scheduleForm: action.scheduleForm
      })
    );
  }

  private _scheduleToEvent(schedule: Schedule): EventInput {
    return <EventInput>{
      id: schedule.id,
      allDay: false,
      start: schedule.startEventAt,
      end: schedule.endEventAt,
      title: schedule.title
    };
  }

  @Selector()
  static getScheduleForm(state: ISchedulesStateModel) {
    return state.scheduleForm;
  }

  @Selector()
  static getAllSchedules(state: ISchedulesStateModel) {
    return state.schedules;
  }

  @Selector()
  static getSchedulesPagination(state: ISchedulesStateModel) {
    return { count: state.paginationCount, schedules: state.schedules };
  }

  @Selector()
  static getAllScheduleEvents(state: ISchedulesStateModel) {
    return state.scheduleEvents;
  }

  @Selector()
  static getSelectedScheduleId(state: ISchedulesStateModel) {
    return state.selectedScheduleId;
  }

  @Selector([
    ScheduleState.getAllSchedules,
    ScheduleState.getSelectedScheduleId
  ])
  static getScheduleById(
    schedules: Schedule[],
    scheduleId: string | undefined
  ): Schedule {
    return schedules.find((schedule: Schedule) => schedule.id === scheduleId);
  }

  showMessage(detail: string, severity: string, summary: string): void {
    this.messageService.add({
      key: 'submitMessage',
      severity,
      summary,
      detail
    });
  }
}
