import { Injectable, Injector, OnInit } from '@angular/core';
import { BaseResourceModel } from '../models/base-resource.model';
import { BaseResourceService } from '../services/base-resource.service';
import { BaseResourceNotifications } from '../base-resource-notifications/base-resource-notifications.component';
import Swal from 'sweetalert2';
import { MessageService } from 'primeng/api';

export interface FilterQueryValue {
  query: string;
  fields: string[];
}

@Injectable()
export abstract class BaseResourceListComponent<T extends BaseResourceModel>
  extends BaseResourceNotifications<T>
  implements OnInit
{
  sortBy = '';
  sortOrder = 'desc';
  title: any = '';

  resources: T[] = [];
  currentAction: string;
  id: string;

  filterQueryValue: FilterQueryValue = { query: '', fields: [] };
  private fields: string[];

  protected constructor(
    protected injector: Injector,
    protected resourceService: BaseResourceService<T>
  ) {
    super(injector.get(MessageService));
  }

  ngOnInit(): void {
    this.currentAction = '';
    this.resourceService.getAll().subscribe(
      (resources) => {
        this.resources = resources; // .sort((a, b) => b.id - a.id);
      },
      () =>
        this.addToast({
          severity: 'error',
          summary: 'Failed to load list!',
          key: 'bottom-actions-toast'
        })
    );
  }

  deleteResource(resource: string): void {
    this.openConfirmsSwal(resource);
  }

  private openMyModal(event): void {
    document.querySelector('#' + event).classList.add('md-show');
  }

  add(event): void {
    this.currentAction = 'add';
    this.openMyModal(event);
  }

  edit(event, item): void {
    this.currentAction = 'edit';
    this.id = item.id;
    this.openMyModal(event);
  }

  closeMyModal(event): void {
    this.currentAction = '';
    this.id = undefined;
    event.target.parentElement.parentElement.parentElement.classList.remove(
      'md-show'
    );
  }

  set query(value: string) {
    this.filterQuery = { query: value, fields: this.fields };
  }

  get query(): string {
    return this.filterQueryValue.query;
  }

  set filterQuery(value: FilterQueryValue) {
    this.filterQueryValue = { query: value.query, fields: this.fields };
  }

  get filterQuery(): FilterQueryValue {
    return this.filterQueryValue;
  }

  openConfirmsSwal(resource: string): void {
    Swal.fire({
      title: 'Remove selected item.',
      text: 'Do you really want to delete this item?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff0000',
      cancelButtonColor: 'No',
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.value) {
        this.resourceService.delete(resource).subscribe(
          () => {
            this.addToast({
              severity: 'success',
              summary: 'Deleted successfully!',
              key: 'bottom-actions-toast'
            });

            setTimeout(() => window.location.reload(), 1500);
          },
          () =>
            this.addToast({
              severity: 'error',
              summary: 'Delete error!',
              detail: 'Error trying to delete!',
              key: 'bottom-actions-toast'
            })
        );
      }
    });
  }
}
