export class AddRoom {
  static readonly type = '[Dashboard Page] AddRoom';
  constructor(public readonly name: string) {}
}

export class RemoveRoom {
  static readonly type = '[Dashboard Page] RemoveRoom';
  constructor(public readonly name: string) {}
}

export class AddRooms {
  static readonly type = '[Dashboard Page] AddRooms';
  constructor(public readonly names: string[]) {}
}

export class ClearRooms {
  static readonly type = 'ClearRooms';
  constructor() {}
}
