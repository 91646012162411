import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';

@Directive({
  selector: '[myDisableIfUnauthorized]'
})
export class MyDisableIfUnauthorizedDirective implements OnInit {
  @Input('myDisableIfUnauthorized') permission: string[]; // Required permission passed in

  constructor(
    private el: ElementRef,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {
    if (!this.authenticationService.hasPermission(this.permission)) {
      this.el.nativeElement.disabled = true;
    }
  }
}
