import { BaseResourceModel } from './base-resource.model';
import { IOption } from './address.model';

export class Tag extends BaseResourceModel implements IOption {
  constructor(public id?: string, public name?: string) {
    super();
  }

  get value(): string {
    return this.id;
  }

  get label(): string {
    return this.name;
  }

  static fromJson(jsonData: any): Tag {
    return Object.assign(new Tag(), jsonData);
  }
}
