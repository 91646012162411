<label>{{ to.label }}</label>
<div class="card rounded-card user-card">
  <div class="card-body">
    <div class="imgs-hover d-flex justify-content-center">
      <img
        class="img-fluid"
        [src]="image"
        style="max-height: 260px; max-width: 260px"
        alt="round-img"
      />
      <div class="imgs-overlay">
        <span>
          <label for="selecao-arquivo" class="labels-custon">+</label>
          <input
            type="file"
            (change)="getFile($event)"
            id="selecao-arquivo"
            style="display: none"
          />
          <input type="hidden" id="image" />
        </span>
      </div>
    </div>
  </div>
</div>
