import { IScheduleForm } from './schedule-event.model';

export class GetAllSchedules {
  static readonly type = 'GetAllSchedules';
  constructor(public readonly startAt: Date, public readonly endAt: Date) {}
}

export class GetSchedulesByPagination {
  static readonly type = 'GetSchedulesByPagination';
  constructor(public readonly start?: number,
    public readonly limit?: number,
    public readonly search?: string) {}
}

export class AddNewSchedule {
  static readonly type = 'AddNewSchedule';
  constructor(public readonly scheduleForm: IScheduleForm) {}
}

export class UpdateSchedule {
  static readonly type = 'UpdateSchedule';

  constructor(
    public readonly scheduleId: string,
    public readonly scheduleForm: IScheduleForm
  ) {}
}

export class RemoveSchedule {
  static readonly type = 'RemoveSchedule';

  constructor(public readonly scheduleId: string) {}
}

export class SetSelectedScheduleId {
  static readonly type = 'SetSelectedScheduleId';

  constructor(public readonly scheduleId: string) {}
}

export class SetScheduleForm {
  static readonly type = 'SetScheduleForm';

  constructor(public readonly scheduleForm: IScheduleForm) {}
}
