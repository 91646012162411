/**
 * Created by Guilherme Beneti Martins on 07/11/2022
 */

import { Observable } from 'rxjs';

export class FetchAllScreenshots {
  static readonly type = 'FetchAllScreenshots';
  constructor(public readonly notifier: Observable<any>) {}
}

export class FetchScreenshotsByDeviceID {
  static readonly type = 'FetchScreenshotsByDeviceID';
  constructor(
    public readonly deviceId: string,
    public readonly notifier: Observable<any>
  ) {}
}

export class FetchScreenshotsByID {
  static readonly type = 'FetchScreenshotsByID';
  constructor(public readonly id: string) {}
}

export class DeleteScreenshot {
  static readonly type = 'DeleteScreenshot';
  constructor(public readonly id: string) {}
}

export class SelectScreenshot {
  static readonly type = 'SelectScreenshot';
  constructor(public readonly id: string) {}
}

export class DeselectScreenshot {
  static readonly type = 'DeselectScreenshot';
  constructor(public readonly id: string) {}
}

export class ClearSelectedScreenshots {
  static readonly type = 'ClearSelectedScreenshots';
  constructor() {}
}

export class ClearScreenshots {
  static readonly type = 'ClearScreenshots';
  constructor() {}
}

export class SetMaxScreenshotsToShow {
  static readonly type = 'SetMaxScreenshotsToShow';
  constructor(public readonly maxScreenshotsToShow: number) {}
}
