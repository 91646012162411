import { Injectable, Injector } from '@angular/core';
import { BaseResourceService } from '../../../../shared/services/base-resource.service';
import { Device } from '../../../../shared/models/device.model';
import { Observable } from 'rxjs';
import { catchError, map, first } from 'rxjs/operators';
import { Command } from '../../../../shared/models/command.model';
import { Constants } from '../../../../constants';
import { S3FileService } from '../../../../shared/services/s3-file.service'

@Injectable({
  providedIn: 'root'
})
export class DeviceService extends BaseResourceService<Device> {
  constructor(
    protected injector: Injector,
    protected s3FileService: S3FileService
  ) {
    super('devices', injector, Device.fromJson);
  }

  sendCommand(resource: Command, deviceId: string): Observable<Command> {
    return this.http
      .post(`${Constants.endPoint('commands')}/${deviceId}`, resource)
      .pipe(
        map(this.jsonDataToResource.bind(this)),
        catchError(this.handleError)
      );
  }

  auth(deviceId: string): Observable<any> {
    return this.http
      .post(`${Constants.endPoint(this.apiPath)}/${deviceId}/auth`, {})
      .pipe(
        map(this.jsonDataToResource.bind(this)),
        catchError(this.handleError)
      );
  }

  async getImageURLFromDevice(device: Device): Promise<string> {
    return await this.s3FileService.linkImageURL(device.image ? device.image : 'default.png', Constants.AWS_S3_DEVICE);
  }

  getDevicesImagesURLs(devices: Device[], imageURLsDict: Map<string, string>): Promise<void[]> {
    if (devices.length > 0) {
      let getDefaultImg = false;
      devices
        .filter(async (device) => !imageURLsDict.has(device.image))
        .map(async (device) => {
          if (!imageURLsDict.has(device.image)) {
            if (device.image === null && getDefaultImg === true) {
              return;
            }
            if (device.image === null) {
              getDefaultImg = true;
            }
            const result = await this.s3FileService
              .linkImageURL(device.image ? device.image : 'default.png', Constants.AWS_S3_DEVICE);
            imageURLsDict.set(device.image ? device.image : 'default', result);
            return result;
          }
        })
      return;
    } else {
      return;
    }
  }
}
