import {
  Component,
  Input,
  Inject,
  ViewEncapsulation,
  OnInit
} from '@angular/core';
import { Spinkit } from './spinkits';
import {
  Router,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError
} from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { Observable } from 'rxjs/Observable';
import { IsLoadingService } from '@service-work/is-loading';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: [
    './spinner.component.css',
    './spinkit-css/sk-double-bounce.css',
    './spinkit-css/sk-chasing-dots.css',
    './spinkit-css/sk-cube-grid.css',
    './spinkit-css/sk-rotating-plane.css',
    './spinkit-css/sk-spinner-pulse.css',
    './spinkit-css/sk-three-bounce.css',
    './spinkit-css/sk-wandering-cubes.css',
    './spinkit-css/sk-wave.css',
    './spinkit-css/sk-line-material.css'
  ],
  encapsulation: ViewEncapsulation.None
})
export class SpinnerComponent implements OnInit {
  isLoading: Observable<boolean>;
  public Spinkit = Spinkit;
  @Input() public backgroundColor = 'rgba(70, 128, 255, 0.69)';
  @Input() public spinner = Spinkit.skLine;

  constructor(
    private isLoadingService: IsLoadingService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter(
          (event) =>
            event instanceof NavigationStart ||
            event instanceof NavigationEnd ||
            event instanceof NavigationCancel ||
            event instanceof NavigationError
        )
      )
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.isLoadingService.add();
          return;
        }
        this.isLoadingService.remove();
      });
  }
}
