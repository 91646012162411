import { Action, Selector, State, StateContext } from '@ngxs/store';
import { WebsocketStateModel } from '../models/websocket.model';
import { Injectable } from '@angular/core';
import {
  SetWebsocketConnected,
  SetWebsocketDisconnected,
  WebsocketMessage
} from '../actions/websocket.actions';
import { append, patch } from '@ngxs/store/operators';

@State<WebsocketStateModel>({
  name: 'websocket',
  defaults: {
    status: false,
    messages: []
  }
})
@Injectable({
  providedIn: 'root'
})
export class WebsocketState {
  constructor() {}

  @Action(SetWebsocketConnected)
  setWebsocketConnected(ctx: StateContext<WebsocketStateModel>) {
    ctx.setState(
      patch<WebsocketStateModel>({
        status: true
      })
    );
  }

  @Action(SetWebsocketDisconnected)
  setWebsocketDisconnected(ctx: StateContext<WebsocketStateModel>) {
    ctx.setState(
      patch<WebsocketStateModel>({
        status: false
      })
    );
  }

  @Action(WebsocketMessage)
  websocketMessage(
    ctx: StateContext<WebsocketStateModel>,
    action: WebsocketMessage
  ) {
    ctx.setState(
      patch<WebsocketStateModel>({
        messages: append<any>([action.message])
      })
    );
  }

  @Selector()
  static connectionStatus(state: WebsocketStateModel): boolean {
    return state.status;
  }

  @Selector([WebsocketState.connectionStatus])
  static connected(status: boolean): boolean {
    if (status) return status;
  }

  @Selector([WebsocketState.connectionStatus])
  static disconnected(status: boolean): boolean {
    if (!status) return status;
  }

  @Selector()
  static getMessages(state: WebsocketStateModel): any[] {
    return state.messages;
  }

  @Selector([WebsocketState.getMessages])
  static getLastMessage(messages: any[]): any {
    const message = messages[messages?.length - 1];
    if (message !== undefined) {
      return message;
    }
  }

  @Selector([WebsocketState.getLastMessage])
  static ping(message: any): any {
    if (message?.id === 'ping') return message;
  }

  @Selector([WebsocketState.getLastMessage])
  static participantAtRoom(message: any): any {
    if (message?.id === 'participantAtRoom') return message;
  }

  @Selector([WebsocketState.getLastMessage])
  static existingParticipants(message: any): any {
    if (message?.id === 'existingParticipants') return message;
  }

  @Selector([WebsocketState.getLastMessage])
  static newJoin(message: any): any {
    if (message?.id === 'newJoin') return message;
  }

  @Selector([WebsocketState.getLastMessage])
  static removed(message: any): any {
    if (message?.id === 'removed') return message;
  }

  @Selector([WebsocketState.getLastMessage])
  static newParticipantArrived(message: any): any {
    if (message?.id === 'newParticipantArrived') return message;
  }

  @Selector([WebsocketState.getLastMessage])
  static participantLeft(message: any): any {
    if (message?.id === 'participantLeft') return message;
  }

  @Selector([WebsocketState.getLastMessage])
  static receiveVideoAnswer(message: any): any {
    if (message?.id === 'receiveVideoAnswer') return message;
  }

  @Selector([WebsocketState.getLastMessage])
  static newCommander(message: any): any {
    if (message?.id === 'newCommander') return message;
  }

  @Selector([WebsocketState.getLastMessage])
  static noHasCommander(message: any): any {
    if (message?.id === 'noHasCommander') return message;
  }

  @Selector([WebsocketState.getLastMessage])
  static roomNotExists(message: any): any {
    if (message?.id === 'roomNotExists') return message;
  }

  @Selector([WebsocketState.getLastMessage])
  static iceCandidate(message: any): any {
    if (message?.id === 'iceCandidate') return message;
  }

  @Selector([WebsocketState.getLastMessage])
  static removeRoom(message: any): any {
    if (message?.id === 'removeRoom') return message;
  }

  @Selector([WebsocketState.getLastMessage])
  static rooms(message: any): any {
    if (message?.id === 'rooms') return message;
  }

  @Selector([WebsocketState.getLastMessage])
  static addRoom(message: any): any {
    if (message?.id === 'addRoom') return message;
  }

  @Selector([WebsocketState.getLastMessage])
  static errorMsg(message: any): any {
    if (message?.id === 'error') return message;
  }
}
