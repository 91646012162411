import { Injectable, Injector } from '@angular/core';
import { BaseResourceService } from '../../../shared/services/base-resource.service';
import { Screenshot } from '../../../shared/models/screenshot.model';
import { Observable, from } from 'rxjs';
import { Constants } from '../../../constants';
import { catchError, concatMap, first, map, mergeMap } from 'rxjs/operators';
import { S3FileService } from '../../../shared/services/s3-file.service';

@Injectable({
  providedIn: 'root'
})
export class ScreenshotService extends BaseResourceService<Screenshot> {
  constructor(
    protected injector: Injector,
    protected s3FileService: S3FileService
  ) {
    super('screenShots', injector, Screenshot.fromJson);
  }

  async linkScreenshotURL(screenshot: Screenshot): Promise<Screenshot> {
    if (screenshot.image != null) {
      const screenshotUrl = await this.s3FileService.linkImageURL(screenshot.image, Constants.AWS_S3_SCREENSHOT);
      screenshot.image = screenshotUrl;
    }
    return screenshot;
  }

  getScreenshotMetadataByDeviceId(id: string): Observable<Screenshot[]> {
    return this.http
      .get<Screenshot[]>(`${Constants.endPoint(this.apiPath)}/device/${id}`)
      .pipe(map(this.jsonDataToResources.bind(this)));
  }

  getScreenshotsByDeviceId(id: string): Observable<Screenshot> {
    return this.http
      .get<Screenshot[]>(`${Constants.endPoint(this.apiPath)}/device/${id}`)
      .pipe(
        map(this.jsonDataToResources.bind(this)),
        mergeMap((screenshots: Screenshot[]) => from(screenshots)),
        concatMap((screenshot) => this.linkScreenshotURL(screenshot)),
        catchError(this.handleError)
      );
  }

  getAllScreenshots(): Observable<Screenshot> {
    return this.http
      .get<Screenshot[]>(`${Constants.endPoint(this.apiPath)}`)
      .pipe(
        map(this.jsonDataToResources.bind(this)),
        mergeMap((screenshots: Screenshot[]) => from(screenshots)),
        concatMap((screenshot) => this.linkScreenshotURL(screenshot)),
        catchError(this.handleError)
      );
  }
}
