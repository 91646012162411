import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { Screenshot } from '../../models/screenshot.model';
import { Constants } from '../../../constants';

@Component({
  selector: 'app-screenshot-list-element',
  templateUrl: './screenshot-list-element.component.html',
  styleUrls: ['./screenshot-list-element.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScreenshotListElementComponent {
  public readonly image = Constants.IMAGE_PLACEHOLDER;

  @Input() screenshot: Screenshot;
  @Input() selectionListActivated = false;
  @Input() selected = false;

  @Output() clickedScreenshot = new EventEmitter<Screenshot>();
  @Output() clickedIcon = new EventEmitter<Screenshot>();

  public showHoverOptions = false;
  public cdnS3Medias = Constants.CDN_S3_MEDIAS;

  constructor() {}

  emitClickedScreenshot(screenshot: Screenshot): void {
    this.clickedScreenshot.emit(screenshot);
  }

  emitClickedIcon(screenshot: Screenshot): void {
    this.clickedIcon.emit(screenshot);
  }
}
