import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { AsideMenuComponent } from './aside-menu/aside-menu.component';
import { PanelMenuModule } from 'primeng/panelmenu';
import { MenubarModule } from 'primeng/menubar';
import { ButtonModule } from 'primeng/button';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { LayoutComponent } from './layout/layout.component';
import { HeaderComponent } from './header/header.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    BreadcrumbsComponent,
    AsideMenuComponent,
    LayoutComponent,
    HeaderComponent
  ],
  imports: [
    CommonModule,
    PanelMenuModule,
    ButtonModule,
    MenubarModule,
    ToastModule,
    ConfirmDialogModule,
    TranslateModule
  ]
})
export class LayoutModule {}
