import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appCardRefresh]'
})
export class CardRefreshDirective {
  constructor(private el: ElementRef) {}
  @HostListener('mouseenter') open(): void {
    this.el.nativeElement.classList.add('rotate-refresh');
  }

  @HostListener('mouseleave') close(): void {
    this.el.nativeElement.classList.remove('rotate-refresh');
  }
}
