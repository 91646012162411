<input
  class="form-control"
  type="text"
  mask="separator.4"
  thousandSeparator=""
  allowNegativeNumbers="true"
  [dropSpecialCharacters]="false"
  [formControl]="formControl"
  [formlyAttributes]="field"
/>
