import { BaseResourceModel } from './base-resource.model';

export class RoleDevice extends BaseResourceModel {
  constructor(
    public id?: string,
    public name?: string,
    public description?: string
  ) {
    super();
  }

  static fromJson(jsonData: any): RoleDevice {
    return Object.assign(new RoleDevice(), jsonData);
  }
}