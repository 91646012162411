import { Component, Injector } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { ActivatedRoute } from '@angular/router';
import { OperatorTypeService } from '../shared/OperatorTypeService';
import { OperatorType } from '../../../shared/models/operatorType.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-operator-view',
  template: `
    <formly-form [model]="model" [fields]="fields" [options]=""> </formly-form>
  `
})
export class OperatorViewComponent extends FieldType {
  infoUser = {
    fieldGroupClassName: 'row',
    fieldGroup: [
      {
        className: 'col-12 col-md-6',
        type: 'outputLabel',
        key: 'name',
        templateOptions: {
          label:  this.translate.instant('Name'),
          type: 'text'
        }
      },
      {
        key: 'credential',
        fieldGroup: [
          {
            className: 'col-12 col-md-6',
            type: 'outputLabel',
            key: 'username',
            templateOptions: {
              label:  this.translate.instant('E-mail'),
              type: 'text'
            }
          }
        ]
      },
      {
        className: 'col-12',
        type: 'outputLabel',
        key: 'operatorTypeId',
        templateOptions: {
          label:  this.translate.instant('Operator Type'),
          valueProp: 'id',
          labelProp: 'description',
          type: 'select',
          options: []
        },
        hooks: {
          onInit: async (field) => {
            if (this.operatorTypes === null || this.operatorTypes.length <= 0) {
              this.operatorTypeService.getAll().subscribe(
                (resources) => {
                  resources = resources.map((value) => {
                    return value;
                  });
                  field.templateOptions.options = resources;
                  this.operatorTypes = resources;
                },
                (error) => console.error('Error loading list')
              );
            }
          }
        }
      }
    ]
  };

  fields: FormlyFieldConfig[] = [
    {
      className: 'section-label text-center',
      template: '<div><strong>OPERATOR DETAILS</strong></div><br>'
    },
    this.infoUser
  ];

  protected route: ActivatedRoute;
  private operatorTypes: OperatorType[] = [];

  constructor(
    protected injector: Injector,
    private translate: TranslateService,
    protected operatorTypeService: OperatorTypeService
  ) {
    super();
    this.route = this.injector.get(ActivatedRoute);
  }
}
