import { Component, Input, ViewEncapsulation } from '@angular/core';
import { cardClose, cardToggle } from './card-animation';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css'],
  animations: [cardToggle, cardClose],
  encapsulation: ViewEncapsulation.None
})
export class CardComponent {
  @Input() options = true;
  @Input() hidHeader = false;
  @Input() customHeader = false;

  @Input() headerContent: string;
  @Input() title: string;
  @Input() blockClass: string;
  @Input() cardClass: string;
  @Input() classHeader = false;
  @Input() showToolHeader = false;
  @Input() showButtonHeader = false;
  @Input() textButtonHeader = 'Button';
  @Input() classButtonHeader = 'btn-outline-primary';
  @Input() actionClickButtonHeader = '';
  @Input() authGroup: string[] = [];
  cardToggle = 'expanded';
  cardClose = 'open';
  fullCard: string;
  fullCardIcon: string;
  loadCard = false;
  isCardToggled = false;
  cardLoad: string;

  toggleCard(event): void {
    this.cardToggle =
      this.cardToggle === 'collapsed' ? 'expanded' : 'collapsed';
  }

  closeCard(event): void {
    this.cardClose = this.cardClose === 'closed' ? 'open' : 'closed';
  }

  fullScreen(event): void {
    this.fullCard = this.fullCard === 'full-card' ? '' : 'full-card';
    this.fullCardIcon =
      this.fullCardIcon === 'icofont-resize' ? '' : 'icofont-resize';
  }

  appCardRefresh(): void {
    this.loadCard = true;
    this.cardLoad = 'card-load';
    setTimeout(() => {
      this.cardLoad = '';
      this.loadCard = false;
    }, 3000);
  }
}
