export class FetchDevices {
  static readonly type = '[Dashboard Page] FetchDevices';
  constructor() {}
}

export class UpdateMqttOnlineStatus {
  static readonly type = '[MqttOnline] UpdateStatus';
  constructor(
    public readonly payload: { deviceId: string; newMqttOnline: boolean }
  ) {}
}
