import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BaseIndicatorModel } from '../base-indicator/base-indicator.model';

@Component({
  selector: 'wifi-status-indicator',
  templateUrl: './wifi-status.component.html',
  styleUrls: ['./wifi-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WifiStatusComponent extends BaseIndicatorModel {
  @Input() status: string;

  constructor() {
    super();
    this.states = new Map<string, string>([
      ['high', 'assets/icon/wifi/wifi-4.svg'],
      ['medium', 'assets/icon/wifi/wifi-3.svg'],
      ['low', 'assets/icon/wifi/wifi-2.svg'],
      ['very_low', 'assets/icon/wifi/wifi-1.svg']
    ]);
    this.defaultIcon = 'assets/icon/wifi/wifi-0.svg';
  }
}
