import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';

import { Router } from '@angular/router';

@Component({
  selector: 'app-modal-animation',
  templateUrl: './modal-animation.component.html',
  styleUrls: ['./modal-animation.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ModalAnimationComponent {
  @Input() modalClass: string;
  @Input() contentClass: string;
  @Input() modalID: string;
  @Input() backDrop = false;
  @Output() closed = new EventEmitter<boolean>();

  constructor(private router: Router) {}
  close(event): void {
    this.closed.emit(true);

    if (this.modalID === 'preCall') {
      this.router.navigate(['/dashboard']);
    }
    document.querySelector('#' + event).classList.remove('md-show');
  }
}
