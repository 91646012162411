import {
  IScheduleDevice,
  IScheduleGuest
} from 'src/app/pages/schedule/store/schedule-event.model';
import { BaseResourceModel } from './base-resource.model';

export class Schedule extends BaseResourceModel {
  constructor(
    public title?: string,
    public companyId?: string,
    public startEventAt?: Date,
    public endEventAt?: Date,
    public note?: string,
    public guest?: IScheduleGuest,
    public device?: IScheduleDevice
  ) {
    super();
  }

  static fromJson(jsonData: any): Schedule {
    return Object.assign(new Schedule(), jsonData);
  }
}
