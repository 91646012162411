import {
  AwsIotCoreService,
  MqttSendMessage,
  MqttSubscription,
  OnInitMqttService
} from '../iot/aws-iot-core.service';
import { Constants } from '../../../constants';
import { MediaChangeDetectService } from '../../../pages/device/shared/services/media-change-detect.service';
import { Injectable } from '@angular/core';
import { BaseMqttService } from './base-mqtt.service';

@Injectable({
  providedIn: 'root'
})
export class MediaMqttService
  extends BaseMqttService
  implements MqttSubscription, MqttSendMessage, OnInitMqttService
{
  private _showMedia = true;
  private _changeShowMedia = true;

  constructor(
    protected awsIotCoreService: AwsIotCoreService,
    private mediaChangeDetectService: MediaChangeDetectService
  ) {
    super(awsIotCoreService);
  }

  protected subscribe(): void {
    this.subscribeTopic(
      `${this._deviceId}/${Constants.CHANGE_MEDIA_FEED}`,
      (data, error) => {
        if (error) {
          console.log('[Constants.CHANGE_MEDIA_FEED]:Error:', error);
        } else {
          this._showMedia = data.showMedia;
          this._changeShowMedia = true;
          console.log(
            '[Constants.CHANGE_MEDIA_FEED]: Change Media Feed:',
            this._showMedia
          );
          this.mediaChangeDetectService.changeDetection$.next(true);
        }
      }
    );
    this.showMedia = false;
  }

  get showMedia(): boolean {
    return this._showMedia;
  }

  set showMedia(value: boolean) {
    this._showMedia = value;
  }

  get changeShowMedia(): boolean {
    return this._changeShowMedia;
  }

  set changeShowMedia(value: boolean) {
    this._changeShowMedia = value;
  }

  showMediaOrVideo(): string {
    return this._showMedia ? 'Video' : 'Media';
  }

  async toggleMediaOrVideo(): Promise<void> {
    console.log(
      '-*-*-*-* TOPIC CHANGE MEDIA:',
      `${this._deviceId}/${Constants.CHANGE_MEDIA}`,
      { showMedia: this._showMedia }
    );
    await this.publish(`${this._deviceId}/${Constants.CHANGE_MEDIA}`, {
      showMedia: this._showMedia
    });
  }
}
