import { Directive, HostListener } from '@angular/core';

import * as screenfull from 'screenfull';
import { GlobalSessionService } from '../services/global-session.service';

@Directive({
  selector: '[appToggleFullScreen]'
})
export class ToggleFullScreenDirective {
  constructor(private readonly globalSessionService: GlobalSessionService) {
    this.globalSessionService.menuControllerExpanded$.subscribe(
      (value: boolean) => {
        // screenfull.toggle();
      }
    );
  }
  @HostListener('click') onClick(): void {
    try {
      console.log('screenfull:', screenfull);
      if (screenfull.enabled) {
        const element = document.getElementById('videoBig');
        screenfull.toggle(element);
      }
    } catch (e) {
      console.error('Error:', e);
    }
  }
}
