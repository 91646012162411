/**
 * Created by Wilton Oliveira Ferreira on 01/05/2022
 */
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalSessionService {
  menuControllerExpanded$ = new Subject();

  menuControllerExpand(value: boolean) {
    this.menuControllerExpanded$.next(value);
  }
}
