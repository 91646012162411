import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-output-label-type',
  templateUrl: './output-label-type.component.html',
  styleUrls: ['./output-label-type.component.scss']
})
export class OutputLabelTypeComponent extends FieldType {
  getValue(value: any): string {
    let val = '';
    if (value === null || value === undefined || value === '') {
      return '';
    }
    switch (this.to.type) {
      case 'text':
        val = value;
        break;
      case 'money':
        val = Number(value.toFixed(2)).toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          style: 'currency',
          currency: 'BRL'
        });
        break;
      case 'boolean':
        val = value === true ? 'Sim' : 'Não';
        break;
      case 'date':
        if (value != null) {
          const d = value.split('-');
          val = d[2] + '/' + d[1] + '/' + d[0];
        } else {
          val = '';
        }
        break;
      case 'datepicker':
        if (value != null && value !== undefined) {
          value = new Date(value);
          const month = value.getMonth() + 1;
          const prefixDate = value.getDate() >= 10 ? '' : '0';
          const prefixMonth = month >= 10 ? '' : '0';
          val =
            prefixDate +
            value.getDate() +
            '/' +
            prefixMonth +
            month +
            '/' +
            value.getFullYear();
        } else {
          val = '';
        }
        break;
      case 'select':
        if (this.to.options !== null && this.to.options !== undefined) {
          if (this.to.multiple) {
            for (const o of this.to.options as any[]) {
              if (value === null || value === undefined) {
                return;
              }
              if (value.includes(o[this.to.valueProp].toString())) {
                if (val === '') {
                  val = o[this.to.labelProp];
                } else {
                  val += ', ' + o[this.to.labelProp];
                }
              }
            }
          } else {
            for (const o of this.to.options as any[]) {
              if (value === o[this.to.valueProp]) {
                val = o[this.to.labelProp];
              }
            }
          }
        } else {
          val = '';
        }
        break;
    }
    return val;
  }
}
