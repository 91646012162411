import { WebsocketUser } from '../../models/participants/websocket-user.model';
import { DeviceTableViewModel } from '../../../shared/store/queries/devices.queries';

export const enum WebRTCState {
  IDLE,
  CONNECTING,
  READY,
  RECEIVESTREAM,
  CONNECTED,
  STOPPED
}

export class AddParticipant {
  static readonly type = 'AddParticipant';
  constructor(public readonly participant: WebsocketUser) {}
}

export class RemoveParticipant {
  static readonly type = 'RemoveParticipant';
  constructor(public readonly participantId: string) {}
}

export class ClearParticipants {
  static readonly type = 'ClearParticipants';
  constructor() {}
}

export class AddOperatorParticipant {
  static readonly type = 'AddOperatorParticipant';
  constructor(public readonly participant: WebsocketUser) {}
}

export class RemoveOperatorParticipant {
  static readonly type = 'RemoveOperatorParticipant';
  constructor() {}
}

export class AddDeviceParticipant {
  static readonly type = 'AddDeviceParticipant';
  constructor(public readonly participant: WebsocketUser) {}
}

export class RemoveDeviceParticipant {
  static readonly type = 'RemoveDeviceParticipant';
  constructor() {}
}

export class SetCommander {
  static readonly type = 'SetCommander';
  constructor(public readonly commander: WebsocketUser) {}
}

export class RemoveCommander {
  static readonly type = 'RemoveCommander';
  constructor() {}
}

export class SetOperatorAudioStatus {
  static readonly type = 'SetOperatorAudioStatus';
  constructor(public readonly status: boolean) {}
}

export class SetOperatorVideoStatus {
  static readonly type = 'SetOperatorVideoStatus';
  constructor(public readonly status: boolean) {}
}

export class ToggleOperatorAudioStatus {
  static readonly type = 'ToggleOperatorAudioStatus';
  constructor() {}
}

export class ToggleOperatorVideoStatus {
  static readonly type = 'ToggleOperatorVideoStatus';
  constructor() {}
}

export class SetRbvDeviceMqttStatus {
  static readonly type = 'SetRbvDeviceMqttStatus';
  constructor(public readonly mqttStatus: boolean) {}
}

export class SetRbvCoreMqttStatus {
  static readonly type = 'SetRbvCoreMqttStatus';
  constructor(public readonly mqttStatus: boolean) {}
}

export class SetOperatorMqttStatus {
  static readonly type = 'SetOperatorMqttStatus';
  constructor(public readonly mqttStatus: boolean) {}
}

export class SetOperatorAudioDevice {
  static readonly type = 'SetOperatorAudioDevice';
  constructor(public readonly deviceId: string) {}
}

export class SetOperatorVideoDevice {
  static readonly type = 'SetOperatorVideoDevice';
  constructor(public readonly deviceId: string) {}
}

export class SetWebRTCCommunication {
  static readonly type = 'SetWebRTCCommunication';
  constructor(public readonly webRTCState: WebRTCState) {}
}

export class SetWebRTCCommunicationDevice {
  static readonly type = 'SetWebRTCCommunicationDevice';
  constructor(public readonly webRTCDevice: DeviceTableViewModel) {}
}
