/**
 * Created by Guilherme Beneti Martins on 22/11/2022
 */

import { Injectable, OnDestroy } from '@angular/core';
import { WebsocketService } from '../../shared/services/websocket.service';
import { Subscription } from 'rxjs';
import { Store } from '@ngxs/store';

import { WebsocketMessage } from '../../shared/store/actions/websocket.actions';

@Injectable({
  providedIn: 'root'
})
export class WebsocketHandlerService implements OnDestroy {
  private websocketSubscription: Subscription;
  constructor(
    private websocketService: WebsocketService,
    private store: Store
  ) {
    console.log('Init websocket handler service');
    this.websocketSubscription = this.websocketService.messages$.subscribe(
      (msg) => {
        const message = JSON.parse(JSON.stringify(msg));
        this.store.dispatch(new WebsocketMessage(message));
      }
    );
  }

  ngOnDestroy(): void {
    console.log('Destroying websocket handler');
    this.websocketSubscription.unsubscribe();
  }
}
