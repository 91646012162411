import { Selector } from '@ngxs/store';
import { ParticipantsState } from '../../../device-control/store/states/participants.state';
import { WebsocketState } from '../states/websocket.state';

export class PreCallQueries {
  @Selector([
    ParticipantsState.hasOperatorVideoDevice,
    ParticipantsState.hasWebRTCCommunicationCheck,
    ParticipantsState.hasOperatorAudioDevice,
    WebsocketState.connected,
    ParticipantsState.hasOperatorMqttStatus,
    ParticipantsState.hasDeviceMqttStatus
  ])
  static conditionsForCallReady(
    camCheck: boolean,
    WebRTCCheck: boolean,
    micCheck: boolean,
    websocketCheck: boolean,
    operatorMqttCheck: boolean,
    deviceMqttCheck: boolean
  ): boolean {
    return camCheck && WebRTCCheck && micCheck && websocketCheck && operatorMqttCheck && deviceMqttCheck;
  }
}
