/**
 * Created by Guilherme Beneti Martins on 06/12/2022
 */

import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { append, patch, removeItem } from '@ngxs/store/operators';
import { WebsocketUser } from '../../models/participants/websocket-user.model';
import {
  AddDeviceParticipant,
  AddOperatorParticipant,
  AddParticipant,
  ClearParticipants,
  RemoveCommander,
  RemoveDeviceParticipant,
  RemoveOperatorParticipant,
  RemoveParticipant,
  SetCommander,
  SetRbvDeviceMqttStatus,
  SetRbvCoreMqttStatus,
  SetOperatorAudioDevice,
  SetOperatorAudioStatus,
  SetOperatorMqttStatus,
  SetOperatorVideoDevice,
  SetOperatorVideoStatus,
  SetWebRTCCommunication,
  SetWebRTCCommunicationDevice,
  ToggleOperatorAudioStatus,
  ToggleOperatorVideoStatus,
  WebRTCState
} from '../actions/participants.actions';
import { ParticipantsStateModel } from '../models/participants.model';
import { DeviceTableViewModel } from '../../../shared/store/queries/devices.queries';

@State<ParticipantsStateModel>({
  name: 'participants',
  defaults: {
    participants: [],
    operatorParticipant: null,
    deviceParticipant: null,
    commander: null,
    lastAdded: null,
    lastRemoved: null,
    precallOperatorAudioStatus: true,
    operatorAudioDeviceId: '',
    precallOperatorVideoStatus: true,
    operatorVideoDeviceId: '',
    rbvDeviceMqttStatus: false,
    rbvCoreMqttStatus: false,
    operatorMqttStatus: false,
    webRTCCommunication: WebRTCState.IDLE,
    webRTCCommunicationDevice: null
  }
})
@Injectable({
  providedIn: 'root'
})
export class ParticipantsState {
  constructor() {}

  @Action(AddParticipant)
  addParticipant(
    ctx: StateContext<ParticipantsStateModel>,
    action: AddParticipant
  ) {
    const tobeAdded: WebsocketUser = JSON.parse(
      JSON.stringify(action.participant)
    );
    ctx.setState(
      patch<ParticipantsStateModel>({
        participants: append<WebsocketUser>([action.participant]),
        lastAdded: tobeAdded
      })
    );
  }

  @Action(RemoveParticipant)
  removeParticipant(
    ctx: StateContext<ParticipantsStateModel>,
    action: RemoveParticipant
  ) {
    const participant: WebsocketUser = ctx
      .getState()
      .participants.filter(
        (participant) => participant.participantId === action.participantId
      )[0];
    const tobeRemoved: WebsocketUser = JSON.parse(JSON.stringify(participant));
    ctx.setState(
      patch<ParticipantsStateModel>({
        participants: removeItem<WebsocketUser>(
          (currentParticipant) =>
            currentParticipant?.participantId === action.participantId
        ),
        lastRemoved: tobeRemoved
      })
    );
  }

  @Action(ClearParticipants)
  clearParticipants(ctx: StateContext<ParticipantsStateModel>) {
    ctx.setState(
      patch<ParticipantsStateModel>({
        participants: [],
        operatorParticipant: null,
        deviceParticipant: null,
        commander: null,
        lastAdded: null,
        lastRemoved: null,
        precallOperatorAudioStatus: false,
        precallOperatorVideoStatus: false
      })
    );
  }

  @Action(AddOperatorParticipant)
  addOperatorParticipant(
    ctx: StateContext<ParticipantsStateModel>,
    action: AddOperatorParticipant
  ) {
    const tobeAdded: WebsocketUser = JSON.parse(
      JSON.stringify(action.participant)
    );
    ctx.setState(
      patch<ParticipantsStateModel>({
        operatorParticipant: action.participant,
        precallOperatorAudioStatus: ctx.getState().precallOperatorAudioStatus,
        precallOperatorVideoStatus: ctx.getState().precallOperatorVideoStatus,
        lastAdded: tobeAdded
      })
    );
  }

  @Action(RemoveOperatorParticipant)
  removeOperatorParticipant(ctx: StateContext<ParticipantsStateModel>) {
    const participant: WebsocketUser = ctx.getState().operatorParticipant;
    const tobeRemoved: WebsocketUser = JSON.parse(JSON.stringify(participant));
    ctx.setState(
      patch<ParticipantsStateModel>({
        operatorParticipant: null,
        precallOperatorAudioStatus: false,
        precallOperatorVideoStatus: false,
        lastRemoved: tobeRemoved
      })
    );
  }

  @Action(AddDeviceParticipant)
  addDeviceParticipant(
    ctx: StateContext<ParticipantsStateModel>,
    action: AddDeviceParticipant
  ) {
    const tobeAdded: WebsocketUser = JSON.parse(
      JSON.stringify(action.participant)
    );
    ctx.setState(
      patch<ParticipantsStateModel>({
        deviceParticipant: action.participant,
        lastAdded: tobeAdded
      })
    );
  }

  @Action(RemoveDeviceParticipant)
  removeDeviceParticipant(ctx: StateContext<ParticipantsStateModel>) {
    const participant: WebsocketUser = ctx.getState().deviceParticipant;
    const tobeRemoved: WebsocketUser = JSON.parse(JSON.stringify(participant));
    ctx.setState(
      patch<ParticipantsStateModel>({
        deviceParticipant: null,
        lastRemoved: tobeRemoved
      })
    );
  }

  @Action(SetCommander)
  setCommander(
    ctx: StateContext<ParticipantsStateModel>,
    action: SetCommander
  ) {
    ctx.setState(
      patch<ParticipantsStateModel>({
        commander: action.commander
      })
    );
  }

  @Action(RemoveCommander)
  removeCommander(ctx: StateContext<ParticipantsStateModel>) {
    ctx.setState(
      patch<ParticipantsStateModel>({
        commander: null
      })
    );
  }

  @Action(SetOperatorAudioStatus)
  setOperatorAudioStatus(
    ctx: StateContext<ParticipantsStateModel>,
    action: SetOperatorAudioStatus
  ) {
    ctx.setState(
      patch<ParticipantsStateModel>({
        precallOperatorAudioStatus: action.status
      })
    );
  }

  @Action(SetOperatorAudioStatus)
  setOperatorVideoStatus(
    ctx: StateContext<ParticipantsStateModel>,
    action: SetOperatorVideoStatus
  ) {
    ctx.setState(
      patch<ParticipantsStateModel>({
        precallOperatorVideoStatus: action.status
      })
    );
  }

  @Action(ToggleOperatorAudioStatus)
  toggleOperatorAudioStatus(ctx: StateContext<ParticipantsStateModel>) {
    ctx.setState(
      patch<ParticipantsStateModel>({
        precallOperatorAudioStatus: !ctx.getState().precallOperatorAudioStatus
      })
    );
  }

  @Action(ToggleOperatorVideoStatus)
  toggleOperatorVideoStatus(ctx: StateContext<ParticipantsStateModel>) {
    ctx.setState(
      patch<ParticipantsStateModel>({
        precallOperatorVideoStatus: !ctx.getState().precallOperatorVideoStatus
      })
    );
  }

  @Action(SetWebRTCCommunication)
  SetWebRTCCommunication(
    ctx: StateContext<ParticipantsStateModel>,
    action: SetWebRTCCommunication
  ) {
    ctx.setState(
      patch<ParticipantsStateModel>({
        webRTCCommunication: action.webRTCState
      })
    );
  }

  @Action(SetOperatorAudioDevice)
  setOperatorAudioDevice(
    ctx: StateContext<ParticipantsStateModel>,
    action: SetOperatorAudioDevice
  ) {
    ctx.setState(
      patch<ParticipantsStateModel>({
        operatorAudioDeviceId: action.deviceId
      })
    );
  }

  @Action(SetOperatorVideoDevice)
  setOperatorVideoDevice(
    ctx: StateContext<ParticipantsStateModel>,
    action: SetOperatorVideoDevice
  ) {
    ctx.setState(
      patch<ParticipantsStateModel>({
        operatorVideoDeviceId: action.deviceId
      })
    );
  }

  @Action(SetWebRTCCommunicationDevice)
  SetWebRTCCommunicationDevice(
    ctx: StateContext<ParticipantsStateModel>,
    action: SetWebRTCCommunicationDevice
  ) {
    ctx.setState(
      patch<ParticipantsStateModel>({
        webRTCCommunicationDevice: action.webRTCDevice
      })
    );
  }

  @Action(SetRbvDeviceMqttStatus)
  SetRbvDeviceMqttStatus(
    ctx: StateContext<ParticipantsStateModel>,
    action: SetRbvDeviceMqttStatus
  ) {
    ctx.setState(
      patch<ParticipantsStateModel>({
        rbvDeviceMqttStatus: action.mqttStatus
      })
    );
  }

  @Action(SetRbvCoreMqttStatus)
  SetRbvCoreMqttStatus(
    ctx: StateContext<ParticipantsStateModel>,
    action: SetRbvCoreMqttStatus
  ) {
    ctx.setState(
      patch<ParticipantsStateModel>({
        rbvCoreMqttStatus: action.mqttStatus
      })
    );
  }

  @Action(SetOperatorMqttStatus)
  SetOperatorMqttStatus(
    ctx: StateContext<ParticipantsStateModel>,
    action: SetOperatorMqttStatus
  ) {
    ctx.setState(
      patch<ParticipantsStateModel>({
        operatorMqttStatus: action.mqttStatus
      })
    );
  }

  @Selector()
  static getOtherParticipants(state: ParticipantsStateModel): WebsocketUser[] {
    return state.participants;
  }

  @Selector()
  static getOperatorParticipant(state: ParticipantsStateModel): WebsocketUser {
    return state.operatorParticipant;
  }

  @Selector()
  static getDeviceParticipant(state: ParticipantsStateModel): WebsocketUser {
    return state.deviceParticipant;
  }

  @Selector()
  static getCommander(state: ParticipantsStateModel): WebsocketUser {
    return state.commander;
  }

  @Selector([ParticipantsState.getOperatorParticipant])
  static isOperatorParticipantConnected(operatorParticipant: string): boolean {
    return operatorParticipant != null;
  }

  @Selector([ParticipantsState.getDeviceParticipant])
  static isDeviceParticipantConnected(deviceParticipant: string): boolean {
    return deviceParticipant != null;
  }

  @Selector([ParticipantsState.getCommander])
  static hasCommander(commander: WebsocketUser): boolean {
    return commander != null;
  }

  @Selector()
  static getOperatorAudioStatus(state: ParticipantsStateModel): boolean {
    return state.precallOperatorAudioStatus;
  }

  @Selector()
  static getOperatorVideoStatus(state: ParticipantsStateModel): boolean {
    return state.precallOperatorVideoStatus;
  }

  @Selector()
  static getLastAdded(state: ParticipantsStateModel): WebsocketUser {
    return state.lastAdded;
  }

  @Selector()
  static getLastRemoved(state: ParticipantsStateModel): WebsocketUser {
    return state.lastRemoved;
  }

  @Selector()
  static getOperatorAudioDevice(state: ParticipantsStateModel): string {
    return state.operatorAudioDeviceId;
  }

  @Selector()
  static hasOperatorAudioDevice(state: ParticipantsStateModel): boolean {
    return state.operatorAudioDeviceId !== '';
  }

  @Selector()
  static getOperatorVideoDevice(state: ParticipantsStateModel): string {
    return state.operatorVideoDeviceId;
  }

  @Selector()
  static hasOperatorVideoDevice(state: ParticipantsStateModel): boolean {
    return state.operatorVideoDeviceId !== '';
  }

  @Selector()
  static getWebRTCCommunication(state: ParticipantsStateModel): WebRTCState {
    return state.webRTCCommunication;
  }

  @Selector()
  static hasWebRTCCommunicationReady(state: ParticipantsStateModel): boolean {
    return state.webRTCCommunication === WebRTCState.READY;
  }

  @Selector()
  static hasWebRTCCommunicationCheck(state: ParticipantsStateModel): boolean {
    return (
      state.webRTCCommunication === WebRTCState.READY ||
      state.webRTCCommunication === WebRTCState.CONNECTED ||
      state.webRTCCommunication === WebRTCState.RECEIVESTREAM ||
      state.webRTCCommunication === WebRTCState.CONNECTING
    );
  }

  @Selector()
  static hasWebRTCCommunicationConnected(
    state: ParticipantsStateModel
  ): boolean {
    return state.webRTCCommunication === WebRTCState.CONNECTED;
  }

  @Selector()
  static hasWebRTCCommunicationConnecting(
    state: ParticipantsStateModel
  ): boolean {
    return state.webRTCCommunication === WebRTCState.CONNECTING;
  }

  @Selector()
  static hasWebRTCCommunicationIdle(state: ParticipantsStateModel): boolean {
    return state.webRTCCommunication === WebRTCState.IDLE;
  }

  @Selector()
  static hasWebRTCCommunicationReceiveStream(
    state: ParticipantsStateModel
  ): boolean {
    return state.webRTCCommunication === WebRTCState.RECEIVESTREAM;
  }

  @Selector()
  static hasWebRTCCommunicationStopped(state: ParticipantsStateModel): boolean {
    return state.webRTCCommunication === WebRTCState.STOPPED;
  }

  @Selector()
  static hasWebRTCCommunicationDevice(state: ParticipantsStateModel): boolean {
    return state.webRTCCommunicationDevice !== null;
  }

  @Selector()
  static hasOperatorMqttStatus(state: ParticipantsStateModel): boolean {
    return state.operatorMqttStatus;
  }

  @Selector()
  static hasDeviceMqttStatus(state: ParticipantsStateModel): boolean {
    return state.rbvDeviceMqttStatus;
  }

  @Selector()
  static getWebRTCCommunicationDevice(
    state: ParticipantsStateModel
  ): DeviceTableViewModel {
    return state.webRTCCommunicationDevice;
  }
}
