<app-modal-animation [modalID]="'screenshots'" [modalClass]="'md-effect-11'">
  <h3>{{ 'screenshots' | translate }}</h3>
  <div style="position: absolute; top: 0; left: 10px">
    <button
      class="p-button-rounded p-button-outlined opacity-75 screenshots-buttons"
      (click)="deleteSelectedScreenshotsConfirmation()"
      [hidden]="this.selectedScreenshots.size <= 0"
    >
      <i class="pi pi-trash"></i>
    </button>
  </div>
  <div style="position: absolute; top: 0; left: 80px">
    <button
      class="p-button-rounded p-button-outlined opacity-75 screenshots-buttons"
      (click)="this.selectedScreenshots.clear()"
      [hidden]="this.selectedScreenshots.size <= 0"
      alt="Deselect items"
    >
      <i class="pi pi-times shadow-sm"></i>
    </button>
  </div>
  <div class="col-12 row" [style]="'max-height: 600px; overflow:auto; '">
    <div
      class="col-12 text-center"
      *ngIf="screenshotsFilter(device)?.length <= 0"
    >
      <h4>{{ 'noAccessToScreenshots' | translate }}</h4>
    </div>
    <div
      class="col-6 container-fluid"
      *ngFor="let screenshot of screenshotsFilter(device)"
    >
      <div class="row">
        <div class="col-md-12">
          <app-screenshot-list-element
            [screenshot]="screenshot"
            [selected]="this.selectedScreenshots.has(screenshot)"
            [selectionListActivated]="selectedScreenshots.size > 0"
            (clickedIcon)="onClickedIcon($event)"
            (clickedScreenshot)="onClickedScreenshot($event)"
          >
          </app-screenshot-list-element>
        </div>
      </div>
    </div>
  </div>
  <button (click)="closeModal()" aria-label="Close" class="md-close-btn">
    <i class="icofont icofont-ui-close"></i>
  </button>
</app-modal-animation>
<app-galleria
  [screenshots]="screenshotsFilter(device)"
  [show]="showGallery"
  [initialScreenshot$]="galleriaScreenshotNotifier$"
  (close)="onCloseGalleria($event)"
  (delete)="onDeleteScreenshot($event)"
  (download)="onDownloadScreenshot($event)"
></app-galleria>
<p-toast position="bottom-right"></p-toast>
<p-confirmDialog
  key="screenshotListDialog"
  header="Delete screenshots"
  icon="pi pi-exclamation-triangle"
  [baseZIndex]="10000"
></p-confirmDialog>
