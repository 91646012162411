<div class="row">
  <div class="col-sm-12">
    <div class="card">
      <div class="card-header">
        <a
          [routerLink]="'/company'"
          type="button"
          class="btn btn-secondary waves-effect waves-light f-right d-inline-block md-trigger"
        >
          << {{ 'back' | translate }}</a
        >
      </div>
      <div class="card-body">
        <form [formGroup]="form" (ngSubmit)="submit($event, 'form')">
          <aw-wizard #wizard [navBarLayout]="'large-empty-symbols'">
            <aw-wizard-step
              [stepTitle]="
                getTitle(index) | translate : { value: getTitle(index) }
              "
              *ngFor="let step of steps; let index = index; let last = last"
            >
              <ng-template awWizardStepSymbol
                ><i class="container-fluid" [class]="getIcon(index)"></i
              ></ng-template>
              <hr />
              <formly-form
                [form]="form.at(index)"
                [model]="model"
                [fields]="step.fields"
                [options]="options[index]"
              >
              </formly-form>
              <hr />
              <div class="d-block row text-center">
                <div class="col-sm-12 centered-content m-t-10">
                  <div class="btn-group">
                    <button
                      *ngIf="index !== 0"
                      awPreviousStep
                      class="btn btn-secondary"
                      type="button"
                      (click)="prevStep(index)"
                    >
                      << {{ 'back' | translate }}
                    </button>
                    <button
                      *ngIf="!last"
                      awNextStep
                      class="btn btn-primary"
                      [ngClass]="{ disabled: !validateStep(index) }"
                      type="button"
                      [disabled]="!validateStep(index)"
                      (click)="nextStep(index)"
                    >
                      {{ 'next' | translate }} >>
                    </button>
                    <button
                      *ngIf="last"
                      class="btn btn-primary"
                      [disabled]="!form.valid"
                      type="submit"
                      awResetWizard
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </aw-wizard-step>
          </aw-wizard>
        </form>
      </div>
    </div>
  </div>
</div>
<div toastContainer></div>
