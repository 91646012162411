import { BaseResourceModel } from './base-resource.model';
import { IOption } from './address.model';

export class OperatorType extends BaseResourceModel implements IOption {
  constructor(
    public id?: string,
    public name?: string,
    public description?: string
  ) {
    super();
  }

  get value(): string {
    return this.id;
  }

  get label(): string {
    return this.description;
  }

  static fromJson(jsonData: any): OperatorType {
    return Object.assign(new OperatorType(), jsonData);
  }
}
