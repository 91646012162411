export class SetWebsocketDisconnected {
  static readonly type = 'SetWebsocketDisconnected';

  constructor() {}
}

export class SetWebsocketConnected {
  static readonly type = 'SetWebsocketConnected';

  constructor() {}
}

export class WebsocketMessage {
  static readonly type = 'WebsocketMessage';

  constructor(public readonly message: any) {}
}
