/**
 * Created by Lucas Henrique Milhomem Meira on 21/02/2021
 */

import { Injectable, Injector } from '@angular/core';
import { BaseResourceService } from '../../../shared/services/base-resource.service';
import { Company } from '../../../shared/models/company.model';
import { Subject } from 'rxjs';
import { Constants } from 'src/app/constants';
import { S3FileService } from '../../../shared/services/s3-file.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyService extends BaseResourceService<Company> {
  constructor(
    protected injector: Injector,
    protected s3FileService: S3FileService
  ) {
    super('companies', injector, Company.fromJson);
  }
  public countryValueSubject = new Subject<void>();

  async getCompaniesImagesPromise(
    companies: Company[],
    imageURLsDict: Map<string, string>
  ): Promise<void[]> {
    if (companies.length > 0) {
      const promises: Promise<void>[] = companies
        .filter(async (device) => !imageURLsDict.has(device.image))
        .map(async (company) => {
          let getDefaultImg;
          if (company.image === null && getDefaultImg === true) {
            return;
          }
          if (company.image === null) {
            getDefaultImg = true;
          }
          const result = await this.s3FileService.linkImageURL(
            company.image ? company.image : 'default.jpeg',
            Constants.AWS_S3_COMPANY
          );
          imageURLsDict.set(company.image ? company.image : 'default', result);
        });
      return Promise.all(promises);
    } else {
      return;
    }
  }

  async getCompaniesImages(companies: Company[]): Promise<Company[]> {
    if (companies.length > 0) {
      await Promise.all(
        companies.map(async (company) => {
          const companyImageFile = await this.s3FileService
            .findByName(
              company.image ? company.image : 'default.jpeg',
              Constants.AWS_S3_COMPANY
            )
            .toPromise();
          company.image = companyImageFile.url;
        })
      );
      return companies;
    } else {
      return;
    }
  }
}
