<header class="layout-header">
  <div class="robovisor-logo" #logoContainer>
    <img src="assets/images/logo.png" alt="Robovisor Logo" />
    <p-button
      styleClass="toggleMenuButton"
      icon="ti-menu"
      (onClick)="toggleMenu($event)"
    ></p-button>
  </div>
  <p-menubar [model]="headerOptions"></p-menubar>
</header>
