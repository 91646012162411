import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {
  AccordionAnchorDirective,
  AccordionDirective,
  AccordionLinkDirective
} from './accordion';
import { ToggleFullScreenDirective } from './fullscreen/toggle-fullscreen.directive';
import { CardRefreshDirective } from './card/card-refresh.directive';
import { CardToggleDirective } from './card/card-toggle.directive';
import { SpinnerComponent } from './spinner/spinner.component';
import { CardComponent } from './card/card.component';
import { ModalAnimationComponent } from './modal-animation/modal-animation.component';
import { ModalBasicComponent } from './modal-basic/modal-basic.component';
import { MenuItems } from './menu-items/menu-items';
import { ParentRemoveDirective } from './element/parent-remove.directive';
import { ClickOutsideModule } from 'ng-click-outside';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  CURRENCY_MASK_CONFIG,
  CurrencyMaskConfig,
  CurrencyMaskModule
} from 'ng2-currency-mask';
import { DataTablesModule } from 'angular-datatables';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { ChartModule } from 'angular2-chartjs';
import { HttpClientModule } from '@angular/common/http';
import {
  AbstractControl,
  FormControl,
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import { CellphoneMaskTypeComponent } from './custom-types/cellphone-mask-type/cellphone-mask-type.component';
import { PhoneMaskTypeComponent } from './custom-types/phone-mask-type/phone-mask-type.component';
import { ZipcodeMaskTypeComponent } from './custom-types/zipcode-mask-type/zipcode-mask-type.component';
import { InputCheckTypeComponent } from './custom-types/input-check-type/input-check-type.component';
import { CnpjMaskTypeComponent } from './custom-types/cnpj-mask-type/cnpj-mask-type.component';
import { InputDateTypeComponent } from './custom-types/input-date-type/input-date-type.component';
import { CpfMaskTypeComponent } from './custom-types/cpf-mask-type/cpf-mask-type.component';
import { RepeatTypeComponent } from './custom-types/repeat-type/repeat-type.component';
import { ImageUploadTypeComponent } from './custom-types/image-upload-type/image-upload-type.component';
import { WizardButtonTypeComponent } from './custom-types/wizard-button-type/wizard-button-type.component';
import { WizardButtonNextTypeComponent } from './custom-types/wizard-button-next-type/wizard-button-next-type.component';
import { OutputLabelTypeComponent } from './output-label-type/output-label-type.component';
import { SelectMultipleTypeComponent } from './custom-types/select-multiple-type/select-multiple-type.component';
import { PanelWrapperComponent } from './custom-types/panel-wrapper/panel-wrapper.component';
import { FileUploadModule } from 'ng2-file-upload';
import { RouterModule } from '@angular/router';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { ArchwizardModule } from 'angular-archwizard';
import { KnobModule } from 'primeng/knob';
import { FormWizardModule } from 'angular2-wizard';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { IsLoadingModule } from '@service-work/is-loading';
import { InputMoneyTypeComponent } from './custom-types/input-money-type/input-money-type.component';
import { MyHideIfUnauthorizedDirective } from '../core/shared/hideIfUnauthorized.directive';
import { MyDisableIfUnauthorizedDirective } from '../core/shared/disableIfUnauthorized.directive';
import * as EmailValidator from 'email-validator';
import { PickListTypeComponent } from './custom-types/pick-list-type/pick-list-type.component';
import { PickListModule } from 'primeng/picklist';
import { DeviceViewComponent } from '../pages/device/device-view/device-view.component';
import { OperatorViewComponent } from '../pages/operator/operator-view/operator-view.component';
import { CompanyViewComponent } from '../pages/company/company-view/company-view.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { DeviceScreenshotComponent } from '../pages/device/device-screenshot/device-screenshot.component';
import { AutoFocusDirective } from '../pages/device/device-control/directives/auto-focus.directive';
import { LongPressDirective } from './element/long-press.directive';
import { ToastContainerModule, ToastrModule } from 'ngx-toastr';
import { NgxLongPressModule } from './ngx-long-press/ngx-long-press.module';
import { PrimetableComponent } from './custom-types/primetable/primetable.component';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { ToastModule } from 'primeng/toast';
import { SidebarModule } from 'primeng/sidebar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ShowTablePipe } from './pipe/showtable.pipe';
import { RippleModule } from 'primeng/ripple';
import { DialogModule } from 'primeng/dialog';
import { WifiStatusComponent } from './custom-indicators/wifi-status/wifi-status.component';
import { BatteryStatusComponent } from './custom-indicators/battery-status/battery-status.component';
import { DevicePreCallComponent } from '../pages/device/device-pre-call/device-pre-call.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';
import { CoordinateMaskTypeComponent } from './custom-types/coordinate-mask-type/coordinate-mask-type.component';
import { GalleriaModule } from 'primeng/galleria';
import { GalleriaComponent } from './screenshot/galleria/galleria.component';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ScreenshotListElementComponent } from './screenshot/screenshot-list-element/screenshot-list-element.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TranslateModule } from '@ngx-translate/core';
import { LayoutModule } from '../ui/layout.module';

const maskConfig: Partial<IConfig> = {
  validation: false
};

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: 'left',
  allowNegative: true,
  decimal: ',',
  precision: 2,
  prefix: 'R$ ',
  suffix: '',
  thousands: '.'
};

export function EmailValidatorFormly(control: FormControl): boolean {
  return EmailValidator.validate(control.value);
}

export function EmailValidatorFormlyMessage(
  err,
  field: FormlyFieldConfig
): string {
  return `Invalid e-mail.`;
}

export function EntityNumberValidatorFormly(control: FormControl): boolean {
  return (
    /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/.test(control.value) || // CNPJ
    /^\d{2}-\d{7}$/.test(control.value) || // EIN
    /^\d{3}\.\d{3}\.\d{3}-\d{2}$/.test(control.value) || // CPF
    /^\d{3}-\d{2}-\d{4}$/.test(control.value) || // SSN
    !control.value
  );
}

export function VideoURLValidatorFormly(control: FormControl): boolean {
  return (
    /(?:https?:\/\/)?(?:www\.)?youtube\.com\/embed\/[a-zA-Z0-9_-]+(\?[\w=&]*)?(?:#t=[0-9]*)?/.test(
      control.value
    ) || !control.value
  );
}

export function EntityNumberValidatorFormlyMessage(
  err,
  field: FormlyFieldConfig
): string {
  return `"${field.formControl.value}" is not a valid tax number.`;
}

export function PhoneValidatorFormly(control: FormControl): boolean {
  return /^\+\d{1,3}\d+$/.test(control.value) || !control.value;
}

export function PhoneValidatorFormlyMessage(
  err,
  field: FormlyFieldConfig
): string {
  return `"${field.formControl.value}" is not a valid phone number.`;
}

export function VideoURLValidatorFormlyMessage(
  err,
  field: FormlyFieldConfig
): string {
  return `"${field.formControl.value}" is not a valid video URL. URL must be in embed format.`;
}

export function minlengthValidationMessages(err, field): string {
  return `Must contain at least ${field.templateOptions.minLength} characters.`;
}

export function fieldMatchValidator(control: AbstractControl): any {
  const { password, rewritePassword } = control.value;

  // avoid displaying the message error when values are empty
  if (!rewritePassword || !password) {
    return null;
  }

  if (rewritePassword === password) {
    return null;
  }

  return { fieldMatch: { message: 'Passwords do not match.' } };
}

@NgModule({
  imports: [
    ToastContainerModule,
    ConfirmDialogModule,
    DialogModule,
    RippleModule,
    FontAwesomeModule,
    SidebarModule,
    TableModule,
    ButtonModule,
    CommonModule,
    ClickOutsideModule,
    NgbModule,
    ToastrModule.forRoot(),
    NgSelectModule,
    CurrencyMaskModule,
    FormWizardModule,
    NgxDatatableModule,
    DataTablesModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    ChartModule,
    KnobModule,
    ClickOutsideModule,
    HttpClientModule,
    ArchwizardModule,
    CardModule,
    FormsModule,
    ReactiveFormsModule,
    CalendarModule,
    NgxMaskModule.forRoot(maskConfig),
    FormlyModule.forRoot({
      validators: [
        { name: 'email', validation: EmailValidatorFormly },
        { name: 'fieldMatch', validation: fieldMatchValidator },
        { name: 'entityId', validation: EntityNumberValidatorFormly },
        { name: 'phone', validation: PhoneValidatorFormly },
        { name: 'videoURL', validation: VideoURLValidatorFormly }
      ],
      validationMessages: [
        { name: 'required', message: 'This field is required!' },
        { name: 'email', message: EmailValidatorFormlyMessage },
        { name: 'minlength', message: minlengthValidationMessages },
        { name: 'entityId', message: EntityNumberValidatorFormlyMessage },
        { name: 'phone', message: PhoneValidatorFormlyMessage },
        { name: 'videoURL', message: VideoURLValidatorFormlyMessage }
      ],
      types: [
        {
          name: 'coordinateMask',
          extends: 'input',
          component: CoordinateMaskTypeComponent
        },
        {
          name: 'emailMask',
          extends: 'input',
          defaultOptions: {
            templateOptions: {
              label: 'E-mail',
              placeholder: 'Enter your e-mail'
            },
            validators: ['email']
          }
        },
        {
          name: 'cellphoneMask',
          extends: 'input',
          component: CellphoneMaskTypeComponent,
          defaultOptions: {
            validators: ['phone']
          }
        },
        {
          name: 'phoneMask',
          extends: 'input',
          component: PhoneMaskTypeComponent,
          defaultOptions: {
            validators: ['phone']
          }
        },
        {
          name: 'videoURLMask',
          extends: 'input',
          defaultOptions: {
            templateOptions: { label: 'Video URL' },
            validators: ['videoURL']
          }
        },
        {
          name: 'zipCodeMask',
          extends: 'input',
          component: ZipcodeMaskTypeComponent,
          defaultOptions: {
            templateOptions: { label: 'Zip Code' }
          }
        },
        { name: 'inputCheck', component: InputCheckTypeComponent },
        {
          name: 'cnpjMask',
          extends: 'input',
          component: CnpjMaskTypeComponent,
          defaultOptions: {
            templateOptions: { label: 'Entity Number' },
            validators: ['entityId']
          }
        },
        {
          name: 'datepicker',
          component: InputDateTypeComponent,
          defaultOptions: {
            // defaultValue: new Date(),
            templateOptions: {
              datepickerOptions: {}
            }
          }
        },
        {
          name: 'money',
          component: InputMoneyTypeComponent,
          defaultOptions: {
            templateOptions: {
              min: 0
            }
          }
        },
        { name: 'cpfMask', component: CpfMaskTypeComponent },
        {
          name: 'pickList',
          component: PickListTypeComponent,
          defaultOptions: {
            templateOptions: {
              source: [],
              target: []
            }
          }
        },
        {
          name: 'repeat',
          component: RepeatTypeComponent,
          defaultOptions: {
            templateOptions: {
              addText: '',
              disabledAdd: true,
              canDelete: false
            }
          }
        },
        {
          name: 'image-upload',
          component: ImageUploadTypeComponent,
          defaultOptions: {
            templateOptions: {
              profileImage: ''
            }
          }
        },
        {
          name: 'button',
          component: WizardButtonTypeComponent,
          wrappers: ['form-field'],
          defaultOptions: {
            templateOptions: {
              btnType: 'default',
              type: 'button'
            }
          }
        },
        {
          name: 'next_previo',
          component: WizardButtonNextTypeComponent,
          defaultOptions: {
            templateOptions: {
              btnType: 'default',
              btnTypeSubmit: 'secondary',
              type: 'button',
              action: 'next_previo'
            }
          }
        },
        {
          name: 'outputLabel',
          component: OutputLabelTypeComponent,
          defaultOptions: {
            templateOptions: {
              label: ''
            }
          }
        },
        { name: 'selectMultiple', component: SelectMultipleTypeComponent },
        {
          name: 'companyView',
          component: CompanyViewComponent,
          wrappers: ['form-field']
        },
        {
          name: 'deviceView',
          component: DeviceViewComponent,
          wrappers: ['form-field']
        },
        {
          name: 'userView',
          component: OperatorViewComponent,
          wrappers: ['form-field']
        }
      ],
      wrappers: [{ name: 'panel', component: PanelWrapperComponent }]
    }),
    FormlyBootstrapModule,
    FileUploadModule,
    RouterModule,
    IsLoadingModule,
    PickListModule,
    LeafletModule,
    NgxLongPressModule,
    MessageModule,
    MessagesModule,
    ToastModule,
    MultiSelectModule,
    DropdownModule,
    GalleriaModule,
    ConfirmPopupModule,
    ConfirmDialogModule,
    LayoutModule,
    TranslateModule
  ],
  declarations: [
    ShowTablePipe,
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    ToggleFullScreenDirective,
    CardRefreshDirective,
    CardToggleDirective,
    SpinnerComponent,
    CardComponent,
    ModalAnimationComponent,
    ModalBasicComponent,
    ParentRemoveDirective,
    LongPressDirective,
    PhoneMaskTypeComponent,
    CellphoneMaskTypeComponent,
    ZipcodeMaskTypeComponent,
    CnpjMaskTypeComponent,
    CpfMaskTypeComponent,
    PickListTypeComponent,
    PanelWrapperComponent,
    WizardButtonTypeComponent,
    WizardButtonNextTypeComponent,
    InputCheckTypeComponent,
    SelectMultipleTypeComponent,
    ImageUploadTypeComponent,
    OutputLabelTypeComponent,
    InputDateTypeComponent,
    InputMoneyTypeComponent,
    RepeatTypeComponent,
    MyHideIfUnauthorizedDirective,
    MyDisableIfUnauthorizedDirective,
    DeviceScreenshotComponent,
    DevicePreCallComponent,
    AutoFocusDirective,
    LongPressDirective,
    PrimetableComponent,
    WifiStatusComponent,
    BatteryStatusComponent,
    CoordinateMaskTypeComponent,
    ScreenshotListElementComponent,
    GalleriaComponent
  ],
  exports: [
    ToastContainerModule,
    ConfirmDialogModule,
    DialogModule,
    RippleModule,
    ButtonModule,
    FontAwesomeModule,
    SidebarModule,
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    ToggleFullScreenDirective,
    CardRefreshDirective,
    CardToggleDirective,
    SpinnerComponent,
    CardComponent,
    ModalAnimationComponent,
    ModalBasicComponent,
    ParentRemoveDirective,
    LongPressDirective,
    NgbModule,
    ClickOutsideModule,
    ToastrModule,
    NgSelectModule,
    CurrencyMaskModule,
    FormWizardModule,
    NgxDatatableModule,
    DataTablesModule,
    FroalaEditorModule,
    FroalaViewModule,
    ChartModule,
    KnobModule,
    ClickOutsideModule,
    HttpClientModule,
    ArchwizardModule,
    CardModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyModule,
    FormlyBootstrapModule,
    FileUploadModule,
    PanelWrapperComponent,
    InputDateTypeComponent,
    InputMoneyTypeComponent,
    CalendarModule,
    MyHideIfUnauthorizedDirective,
    MyDisableIfUnauthorizedDirective,
    DeviceScreenshotComponent,
    DevicePreCallComponent,
    AutoFocusDirective,
    LongPressDirective,
    NgxLongPressModule,
    PrimetableComponent,
    MessageModule,
    MessagesModule,
    ToastModule,
    WifiStatusComponent,
    BatteryStatusComponent,
    MultiSelectModule,
    DropdownModule,
    ScreenshotListElementComponent,
    GalleriaComponent,
    TranslateModule,
    LayoutModule,
    HttpClientModule
  ],
  providers: [
    MenuItems,
    { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig },
    DeviceScreenshotComponent,
    DevicePreCallComponent
  ],
  schemas: [NO_ERRORS_SCHEMA]
})
export class SharedModule { }
