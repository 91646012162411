import { Observable } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { catchError, map, first } from 'rxjs/operators';
import { Constants } from '../../constants';
import { File } from '../models/file.model';
import { BaseResourceService } from './base-resource.service';

@Injectable({
  providedIn: 'root'
})
export class S3FileService extends BaseResourceService<File> {
  constructor(protected injector: Injector) {
    super('files', injector, File.fromJson);
  }

  findByName(fileName: string, modelName: string): Observable<File> {
    return this.http
      .get(`${Constants.endPoint(this.apiPath, '')}/${modelName}/${fileName}`)
      .pipe(
        map(this.jsonDataToResource.bind(this)),
        catchError(this.handleError)
      );
  }

  async linkImageURL(image: string, module: string): Promise<string> {
    if (image != null) {
      const resource = await this.findByName(image, module)
        .pipe(first())
        .toPromise();
      return resource.url;
    }
  }
}
