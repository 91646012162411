import { Injectable } from '@angular/core';
import { Constants } from '../../../constants';
import {
  AwsIotCoreService,
  MqttSendMessage,
  MqttSubscription,
  OnInitMqttService
} from '../iot/aws-iot-core.service';
import { MediaChangeDetectService } from '../../../pages/device/shared/services/media-change-detect.service';
import { BaseMqttService } from './base-mqtt.service';

@Injectable({
  providedIn: 'root'
})
export class ScreenSharingMqttService
  extends BaseMqttService
  implements MqttSubscription, MqttSendMessage, OnInitMqttService
{
  private _isScreenSharing = false;

  constructor(
    protected awsIotCoreService: AwsIotCoreService,
    private mediaChangeDetectService: MediaChangeDetectService
  ) {
    super(awsIotCoreService);
  }

  async toggleScreenSharing(): Promise<void> {
    await this.publish(`${this._deviceId}/${Constants.SCREEN_SHARING}`, {
      isScreenSharing: this.isScreenSharing
    });
  }

  protected subscribe(): void {
    this.subscribeTopic(
      `${this._deviceId}/${Constants.SCREEN_SHARING_FEED}`,
      (data, error) => {
        if (error) {
          console.error('[Constants.SCREEN_SHARING_FEED]:Error:', error);
        } else {
          this.isScreenSharing = data.value.isScreenSharing;
          this.mediaChangeDetectService.changeDetection$.next(true);
        }
      }
    );
  }

  get isScreenSharing(): boolean {
    return this._isScreenSharing;
  }

  set isScreenSharing(value: boolean) {
    this._isScreenSharing = value;
  }
}
