import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { CompanyFormComponent } from '../../../pages/company/company-form/company-form.component';

@Component({
  selector: 'app-cnpj-mask-type',
  templateUrl: './cnpj-mask-type.component.html'
})
export class CnpjMaskTypeComponent extends FieldType {
  constructor(
    public companyFormComponent: CompanyFormComponent
  ) {
    super();
  }

  entityMaskType(country, businessAccount): string {
    switch(country){
      case('BR'):
        if(businessAccount){
          return '00.000.000/0000-00'
        }
        return '000.000.000-00'
      case('US'):
        if(businessAccount){
          return '00-0000000'
        }
        return '000-00-0000'
      default:
        return '00-0000000'
    }
  }
}
