import { Action, Selector, State, StateContext } from '@ngxs/store';
import { RoomsStateModel } from '../models/rooms.model';
import { Injectable } from '@angular/core';
import {
  AddRoom,
  AddRooms,
  ClearRooms,
  RemoveRoom
} from '../actions/rooms.actions';
import { append, patch, removeItem } from '@ngxs/store/operators';
import { Room } from '../models/room.model';

@State<RoomsStateModel>({
  name: 'rooms',
  defaults: {
    items: [],
    ready: false
  }
})
@Injectable({
  providedIn: 'root'
})
export class RoomsState {
  constructor() {
    console.log('creating room store');
  }

  @Action(AddRoom)
  addRoom(ctx: StateContext<RoomsStateModel>, action: AddRoom) {
    ctx.setState(
      patch<RoomsStateModel>({
        items: append<Room>([{ name: action.name }])
      })
    );
  }

  @Action(RemoveRoom)
  removeRoom(ctx: StateContext<RoomsStateModel>, action: RemoveRoom) {
    ctx.setState(
      patch<RoomsStateModel>({
        items: removeItem<Room>((room) => room.name === action.name)
      })
    );
  }

  @Action(AddRooms)
  addRooms(ctx: StateContext<RoomsStateModel>, action: AddRooms) {
    const rooms: Room[] = action.names.map((roomName) => ({
      name: roomName
    }));
    ctx.patchState({
      items: rooms,
      ready: true
    });
  }

  @Action(ClearRooms)
  clearRooms(ctx: StateContext<RoomsStateModel>) {
    ctx.setState(
      patch<RoomsStateModel>({
        items: []
      })
    );
  }

  @Selector()
  static getAllRooms(state: RoomsStateModel): Room[] {
    return state.items;
  }

  @Selector()
  static ready(state: RoomsStateModel): boolean {
    return state.ready;
  }
}
