import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseResourceModel } from '../models/base-resource.model';
import { Authorization } from '../models/authorization.model';
import { Constants } from '../../constants';
import { AuthenticationService } from '../../core/services/authentication.service';

export abstract class Response {
  count: number;
  rows: any[];
}

interface Data {
  data: any;
}

export abstract class BaseResourceService<T extends BaseResourceModel> {
  protected http: HttpClient;
  protected authenticationService: AuthenticationService;
  protected constructor(
    protected apiPath: string,
    protected injector: Injector,
    protected jsonDataToResourceFn: (jsonData: any) => T
  ) {
    this.http = injector.get(HttpClient);
    this.authenticationService = injector.get(AuthenticationService);
  }

  get getAuthenticationCurrent(): Authorization {
    return this.authenticationService.currentAuthorizationValue;
  }

  getAll(): Observable<T[]> {
    return this.http
      .get<T[]>(Constants.endPoint(this.apiPath))
      .pipe(
        map(this.jsonDataToResources.bind(this)),
        catchError(this.handleError)
      );
  }

  getById(id: string): Observable<T> {
    return this.http
      .get(`${Constants.endPoint(this.apiPath)}/${id}`)
      .pipe(
        map(this.jsonDataToResource.bind(this)),
        catchError(this.handleError)
      );
  }

  create(resource: T): Observable<T> {
    return this.http
      .post(Constants.endPoint(this.apiPath), resource)
      .pipe(
        map(this.jsonDataToResource.bind(this)),
        catchError(this.handleError)
      );
  }

  createOnlyData(resource: T): Observable<T> {
    const database = '/database';
    console.log(`${Constants.endPoint(this.apiPath) + database}`)
    return this.http
      .post(`${Constants.endPoint(this.apiPath) + database}`, resource)
      .pipe(
        map(this.jsonDataToResource.bind(this)),
        catchError(this.handleError)
      );
  }

  update(resource: T): Observable<T> {
    return this.http
      .put(`${Constants.endPoint(this.apiPath)}/${resource.id}`, resource)
      .pipe(
        map(() => resource),
        catchError(this.handleError)
      );
  }

  delete(id: string): Observable<any> {
    return this.http.delete(`${Constants.endPoint(this.apiPath)}/${id}`).pipe(
      map(() => null),
      catchError(this.handleError)
    );
  }

  getAllPagination(
    start: number,
    limit: number,
    search: string
  ): Observable<Response> {
    return this.http
      .get<Response>(
        Constants.endPointFilter(this.apiPath, start, limit, search)
      )
      .pipe(
        map(this.jsonDataToResource.bind(this)),
        catchError(this.handleError)
      );
  }

  protected jsonDataToResources(jsonData: any): T[] {
    return jsonData.map((resource) => this.jsonDataToResourceFn(resource));
  }

  protected jsonDataToResource(jsonData: any): T {
    return this.jsonDataToResourceFn(jsonData);
  }

  protected handleError(error: any): Observable<any> {
    return throwError(error);
  }
}
