/**
 * Created by Lucas Henrique Milhomem Meira on 04/06/2021
 */

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { BaseResourceModel } from '../../../shared/models/base-resource.model';
import { Screenshot } from '../../../shared/models/screenshot.model';
import { ScreenshotService } from '../../screenshot/services/screenshot.service';
import { IsLoadingService } from '@service-work/is-loading';
import { Device } from '../../../shared/models/device.model';
import { Select, Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BaseResourceListComponent } from '../../../shared/base-resource-list/base-resource-list.component';
import { ConfirmationService, MessageService } from 'primeng/api';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../../constants';
import { ScreenshotsState } from '../../screenshot/store/screenshot.state';
import { DeleteScreenshot } from '../../screenshot/store/screenshot.actions';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-device-screenshot',
  templateUrl: './device-screenshot.component.html',
  styleUrls: ['./device-screenshot.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceScreenshotComponent
  extends BaseResourceListComponent<BaseResourceModel>
  implements OnInit, OnDestroy
{
  // Utility Subject to unsubscribe from all observables
  protected destroy$: Subject<boolean> = new Subject<boolean>();

  @Select(ScreenshotsState.getDeviceScreenshots)
  private screenshotsFilterFn$: Observable<(device: Device) => Screenshot[]>;
  public screenshotsFilter: (device: Device) => Screenshot[];

  @Input() device$: Observable<Device>;
  public device: Device;
  @Input() notifier$: Observable<any>;
  screenshots: Screenshot[];
  public readonly image = Constants.IMAGE_PLACEHOLDER;
  public showGallery = false;
  private initialGalleriaScreenshot$: Subject<Screenshot> =
    new Subject<Screenshot>();
  public galleriaScreenshotNotifier$: Observable<Screenshot> =
    this.initialGalleriaScreenshot$.asObservable();

  public selectedScreenshots: Set<Screenshot> = new Set<Screenshot>();

  constructor(
    protected injector: Injector,
    @Inject(DOCUMENT) document,
    protected router: Router,
    protected changeDetector: ChangeDetectorRef,
    private isLoadingService: IsLoadingService,
    protected screenshotService: ScreenshotService,
    protected store: Store,
    protected messageService: MessageService,
    private httpClient: HttpClient,
    protected confirmationService: ConfirmationService,
    private translate: TranslateService
  ) {
    super(injector, screenshotService);
  }

  ngOnInit(): void {
    this.changeDetector.reattach();
    setInterval(() => {
      this.changeDetector.detectChanges();
    }, 200);

    this.device$.pipe(takeUntil(this.destroy$)).subscribe((device) => {
      this.device = device;
      this.changeDetector.markForCheck();
    });

    this.screenshotsFilterFn$
      .pipe(takeUntil(this.destroy$))
      .subscribe((screenshotsFilter: (device: Device) => Screenshot[]) => {
        this.screenshotsFilter = screenshotsFilter;
      });

    this.changeDetector.detach();
    this.notifier$.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      if (value) {
        this.changeDetector.reattach();
      } else {
        this.changeDetector.detach();
      }
    });
  }

  ngOnDestroy() {
    this.initialGalleriaScreenshot$.complete();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  async openModal(): Promise<void> {
    this.isLoadingService.add({ key: 'default' });
    document.querySelector('#screenshots').classList.add('md-show');
    this.isLoadingService.remove({ key: 'default' });
  }

  async closeModal(): Promise<void> {
    document.querySelector('#screenshots').classList.remove('md-show');
  }

  onCloseGalleria(close: boolean): void {
    this.showGallery = !close;
  }

  showGalleria(screenshot: Screenshot): void {
    this.showGallery = true;
    this.initialGalleriaScreenshot$.next(screenshot);
  }

  onDeleteScreenshot(screenshot: Screenshot): void {
    if (screenshot != undefined) {
      this.resourceService.delete(screenshot.id).subscribe(
        () => {
          this.store.dispatch(new DeleteScreenshot(screenshot.id));
          this.messageService.add({
            severity: 'success',
            summary: this.translate.instant('Successfully deleted screenshot')
          });
        },
        () =>
          this.messageService.add({
            severity: 'error',
            summary: 'Could not delete screenshot!'
          })
      );
    }
  }

  deleteSelectedScreenshotsConfirmation(): void {
    console.log(`deleting ${this.selectedScreenshots.size} screenshots`);
    this.confirmationService.confirm({
      key: 'screenshotListDialog',
      message: this.translate.instant(
        'Are you sure you want to delete selected screenshots?'
      ),
      accept: () => this.deleteScreenshots()
    });
  }

  private deleteScreenshots(): void {
    let amountDeleted = 0;
    const successfullyDeleted: Screenshot[] = [];

    this.messageService.add({
      severity: 'success',
      sticky: true,
      summary: this.translate.instant('Deleting screenshots'),
      detail: `Successfully deleted ${amountDeleted}/${this.selectedScreenshots.size}`
    });

    Promise.all(
      Array.from(this.selectedScreenshots).map((screenshot) => {
        return this.resourceService
          .delete(screenshot.id)
          .toPromise()
          .then(() => {
            this.store.dispatch(new DeleteScreenshot(screenshot.id));
            amountDeleted += 1;
            if (amountDeleted == this.selectedScreenshots.size) {
              this.messageService.clear();
            }
            successfullyDeleted.push(screenshot);
          })
          .catch((error) =>
            this.messageService.add({
              severity: 'error',
              summary: 'Failed to delete screenshot!'
            })
          );
      })
    ).then(() => {
      this.messageService.clear();
      successfullyDeleted.forEach((screenshot) =>
        this.selectedScreenshots.delete(screenshot)
      );
    });
  }

  onDownloadScreenshot(screenshot: Screenshot): void {
    // Source: https://stackoverflow.com/a/62437624/9811491
    
    if (
      screenshot.image &&
      screenshot.image.includes(Constants.CDN_S3_MEDIAS)
    ) {
      this.httpClient
        .get(screenshot.image, { responseType: 'blob' as 'json' })
        .subscribe((res: any) => {
          const file = new Blob([res], { type: res.type });

          const blob = window.URL.createObjectURL(file);
          const link = document.createElement('a');
          link.href = blob;
          link.download = screenshot.id;

          link.dispatchEvent(
            new MouseEvent('click', {
              bubbles: true,
              cancelable: true,
              view: window
            })
          );

          setTimeout(() => {
            // firefox
            window.URL.revokeObjectURL(blob);
            link.remove();
          }, 100);
        });
    } else {
      this.messageService.add({
        severity: 'error',
        summary: this.translate.instant('Image download'),
        detail: this.translate.instant('Could not locate image URL!')
      });
    }
  }

  onClickedIcon(screenshot: Screenshot): void {
    if (this.selectedScreenshots.has(screenshot)) {
      this.selectedScreenshots.delete(screenshot);
    } else {
      this.selectedScreenshots.add(screenshot);
    }
  }

  onClickedScreenshot(screenshot: Screenshot): void {
    if (this.selectedScreenshots.size > 0) {
      if (!this.selectedScreenshots.has(screenshot)) {
        this.selectedScreenshots.add(screenshot);
      } else {
        this.selectedScreenshots.delete(screenshot);
      }
    } else {
      this.showGalleria(screenshot);
    }
  }
}
