import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent {
  tempState = [];
  breadcrumbs: Array<any>;

  constructor(private router: Router, private route: ActivatedRoute) {
    this.router.events
      .filter((event) => event instanceof NavigationEnd)
      .subscribe(() => {
        this.breadcrumbs = [];
        this.tempState = [];
        let currentRoute = this.route.root;
        let url = '';
        do {
          const childrenRoutes = currentRoute.children;
          currentRoute = null;
          childrenRoutes.forEach((routes) => {
            if (routes.outlet === 'primary') {
              const routeSnapshot = routes.snapshot;
              url +=
                '/' +
                routeSnapshot.url.map((segment) => segment.path).join('/');
              if (routes.snapshot.data.breadcrumb !== undefined) {
                let status = true;
                if (routes.snapshot.data.status !== undefined) {
                  status = routes.snapshot.data.status;
                }

                let icon = false;
                if (routes.snapshot.data.icon !== undefined) {
                  icon = routes.snapshot.data.icon;
                }

                let breadcrumbCaption = false;
                if (routes.snapshot.data.breadcrumb_caption !== undefined) {
                  breadcrumbCaption = routes.snapshot.data.breadcrumb_caption;
                }

                if (!this.tempState.includes(routes.snapshot.data.breadcrumb)) {
                  this.tempState.push(routes.snapshot.data.breadcrumb);
                  this.breadcrumbs.push({
                    label:
                      routes.snapshot.data.breadcrumb !== undefined
                        ? routes.snapshot.data.breadcrumb
                        : 'Welcome',
                    icon,
                    caption: breadcrumbCaption,
                    status,
                    url
                  });
                }
              }
              currentRoute = routes;
            }
          });
        } while (currentRoute);
      });
  }
}
