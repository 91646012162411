<strong
  ><label class="form-label">{{ to.label }}:</label></strong
>
<label *ngIf="this.to.type !== 'pickList'; else select"
  >&nbsp;{{ getValue(this.model[this.key.toString()]) }}</label
>
<ng-template #select>
  <ul class="operators-list list-group w-50 d-inline-flex" aria-readonly="true">
    <li
      class="list-group-item"
      [value]="operator.id"
      *ngFor="let operator of this.model.operators"
    >
      {{ operator.name }}
    </li>
  </ul>
</ng-template>
