import { Injectable } from '@angular/core';
import { Constants } from '../../../constants';
import {
  AwsIotCoreService,
  MqttSendMessage,
  MqttSubscription,
  OnInitMqttService
} from '../iot/aws-iot-core.service';
import { MediaChangeDetectService } from '../../../pages/device/shared/services/media-change-detect.service';
import { BaseMqttService } from './base-mqtt.service';

@Injectable({
  providedIn: 'root'
})
export class SendSpeechMqttService
  extends BaseMqttService
  implements MqttSubscription, MqttSendMessage, OnInitMqttService
{
  private _sendSpeech = false;

  constructor(protected awsIotCoreService: AwsIotCoreService) {
    super(awsIotCoreService);
  }

  async toggleSendSpeech(text: string): Promise<void> {
    await this.publish(`${this._deviceId}/${Constants.SPEAK}`, {
      sendSpeech: this.sendSpeech,
      text: text,
      language: 'en-US'
    });
  }

  protected subscribe(): void {
    this.subscribeTopic(
      `${this._deviceId}/${Constants.SPEAK_FEED}`,
      (data, error) => {
        if (error) {
          console.error('[Constants.SPEAK_FEED]:Error:', error);
        } else {
          this.sendSpeech = data.sendSpeech;
        }
      }
    );
  }

  get sendSpeech(): boolean {
    return this._sendSpeech;
  }

  set sendSpeech(value: boolean) {
    this._sendSpeech = value;
  }
}
