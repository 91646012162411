import { ElementRef, Directive, HostListener } from '@angular/core';
@Directive({
  selector: '[appParentRemove]'
})
export class ParentRemoveDirective {
  alertParent: any;
  constructor(private elements: ElementRef) {}

  @HostListener('click', ['$event'])
  onToggle($event: any): void {
    $event.preventDefault();
    this.alertParent = this.elements.nativeElement.parentElement;
    this.alertParent.remove();
  }
}
