import { BaseResourceService } from './base-resource.service';
import { Injectable, Injector } from '@angular/core';
import { Upload } from '../models/upload.model';

@Injectable({
  providedIn: 'root'
})
export class BaseResourceUploadService extends BaseResourceService<Upload> {
  constructor(protected injector: Injector) {
    super('files', injector, Upload.fromJson);
  }

  public getBase64(file, callback): void {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      callback(reader.result);
    };
    reader.onerror = (error) => {
      console.log('Error: ', error);
      return error;
    };
  }
}
