import { BaseResourceModel } from './base-resource.model';
import { Address, IOption } from './address.model';
import { Operator } from './operator.model';

export class Company extends BaseResourceModel implements IOption {
  constructor(
    public id?: string,
    public name?: string,
    public cnpj?: string,
    public email?: string,
    public phone?: string,
    public cellphone?: string,
    public image?: string,
    public country?: string,
    public latitude?: number,
    public longitude?: number,
    public isEnable?: boolean,
    public addressId?: string,
    public address?: Address,
    public operators?: Operator[]
  ) {
    super();
  }

  get value(): string {
    return this.id;
  }

  get label(): string {
    return this.name;
  }

  static fromJson(jsonData: any): Company {
    return Object.assign(new Company(), jsonData);
  }

  static getEntityType(country: string, businessAccount: boolean) {
    switch (country) {
      case 'BR':
        if (businessAccount) {
          return 'CNPJ';
        }
        return 'CPF';
      case 'US':
        if (businessAccount) {
          return 'EIN';
        }
        return 'SSN';
      default:
        return 'Entity Number';
    }
  }

  static getCountries(): Company[] {
    const countries: Company[] = [];
    countries.push({ label: 'Brazil', value: 'BR' });
    countries.push({ label: 'United States of America', value: 'US' });
    return countries;
  }
}
