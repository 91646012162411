import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { TokenType } from 'src/app/shared/models/authorization.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  headerOptions: MenuItem[];
  @ViewChild('logoContainer')
  logoContainer: ElementRef;
  countries: any[] | undefined;

  constructor(
    public authService: AuthenticationService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.countries = [
      { name: 'Brazil', code: 'br', language: 'pt' },
      { name: 'France', code: 'fr', language: 'fr' },
      { name: 'Spain', code: 'es', language: 'es' },
      { name: 'United States', code: 'us', language: 'en' }
    ];
    this.translate.onDefaultLangChange.subscribe(() =>
      this._updateSelectedCountry()
    );
    this._updateSelectedCountry();
  }

  get selectedCountry(): string | undefined {
    return this.countries.find(
      (country) => country.language === this.translate.getDefaultLang()
    ).code;
  }

  private _updateSelectedCountry(): void {
    const companies = this.authService.currentAuthorizationValue.companies;
    let companyName;
    companies.map((company) => {
      if(company.id === this.authService.currentAuthorizationValue.companyId){
        companyName = company.name;
        return;
      }
    })
    this.headerOptions = [
      { label: companyName, routerLink: '/auth/login/company' },
      {
        label: this.authService.currentAuthorizationValue.name,
        items: [
          {
            ...(this.authService.currentAuthorizationValue.operatorType !==
              TokenType.GUEST_TOKEN && {
              label: this.translate.instant('changePassword'),
              icon: 'ti-key',
              routerLink: '/operator/profile/rewrite_password/add'
            })
          },
          {
            ...(this.authService.currentAuthorizationValue.operatorType !==
              TokenType.GUEST_TOKEN && {
              label: this.translate.instant('deleteAccount'),
              icon: 'ti-trash',
              routerLink: '/operator/profile/delete_account'
            })
          },
          {
            label: this.translate.instant('logout'),
            icon: 'ti-layout-sidebar-left',
            command: () => this.logout()
          }
        ]
      },
      {
        label: this.countries.find(
          (country) => country.code === this.selectedCountry
        ).name,
        icon: `flag flag-${this.selectedCountry}`,
        iconClass: 'country-icon',
        items: this.countries.map((country) => ({
          label: country.name,
          icon: `flag flag-${country.code.toLowerCase()}`,
          iconClass: 'country-icon',
          command: async () => {
            const defaultLang = country.language.toLowerCase();
            this.translate.setDefaultLang(defaultLang);
            localStorage.setItem('language', defaultLang);
            window.location.reload();
          }
        }))
      }
    ];
  }

  toggleMenu(value: any) {
    const logoContainerWidth = this.logoContainer.nativeElement.offsetWidth;
    const asideMenuWidth =
      document.documentElement.style.getPropertyValue('--asideMenuWidth');
    if (asideMenuWidth == '0px') {
      document.documentElement.style.setProperty(
        '--asideMenuWidth',
        `${logoContainerWidth}px`
      );
    } else {
      document.documentElement.style.setProperty('--asideMenuWidth', '0px');
      this.logoContainer.nativeElement.style.width = `${logoContainerWidth}px`;
    }
  }

  logout(): void {
    this.authService.clearAuthorization();
    location.reload();
  }
}
