import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-wizard-button-type',
  templateUrl: './wizard-button-type.component.html',
  styleUrls: ['./wizard-button-type.component.css']
})
export class WizardButtonTypeComponent extends FieldType {
  onClick($event): void {
    if (this.to.onClick) {
      this.to.onClick($event);
    }
  }
}
