<div class="p-field form-group">
  <label>{{ to.label }} {{ to.required ? '*' : '' }}</label>
  <p-calendar
    [class.ng-dirty]="showError"
    [monthNavigator]="true"
    [yearNavigator]="true"
    [formControl]="formControl"
    [formlyAttributes]="field"
    [placeholder]="to.placeholder"
    [style]="{ width: '100px' }"
    [inputStyle]="{ width: '100%' }"
    [inline]="false"
    [minDate]="to.minDate"
    [maxDate]="to.maxDate"
    [yearRange]="to.yearRange"
    [yearNavigator]="to.yearRange !== null ? true : false"
    [readonlyInput]="true"
    [showIcon]="false"
    [locale]="pt"
    [showTime]="true"
    [touchUI]="true"
    [showWeek]="true"
    dateFormat="dd/mm/yy"
  ></p-calendar>
</div>
