import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Injector,
  OnInit,
  OnDestroy
} from '@angular/core';
import {
  BaseResourceFormComponent,
  StepType
} from '../../../shared/base-resource-form/base-resource-form.component';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { Address } from '../../../shared/models/address.model';
import { FormArray, FormGroup } from '@angular/forms';
import { CompanyService } from '../shared/CompanyService';
import { Company } from '../../../shared/models/company.model';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-company-form',
  templateUrl: './company-form.component.html',
  styleUrls: ['./company-form.component.scss']
})
export class CompanyFormComponent
  extends BaseResourceFormComponent<Company>
  implements OnInit, AfterViewInit, OnDestroy
{
  activedStep = 0;
  model: any = {};
  options: FormlyFormOptions = {};

  ufField;
  EntityIdField;
  zipCodeField;

  datasubject: Subscription | undefined;

  stepCompany: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          className: 'col-12',
          type: 'image-upload',
          key: 'image',
          templateOptions: {
            modelName: 'company',
            required: false,
            changeValue: (event, key) => {
              this.form.controls[0].get(key).setValue(event);
            }
          }
        },

        {
          className: 'col-12 col-md-12 align-self-center',
          key: 'businessAccount',
          type: 'checkbox',
          defaultValue: false,
          templateOptions: {
            label: this.translate.instant('Is Business Account?'),
            required: true,
            change: () => {
              if (this.model.country) {
                this.EntityIdField.templateOptions.label =
                  this.translate.instant(
                    Company.getEntityType(
                      this.model.country,
                      this.model.businessAccount
                    )
                  );
                // SSN not required to USA citizens
                if (
                  this.model.country === 'US' &&
                  !this.model.businessAccount
                ) {
                  this.EntityIdField.templateOptions.required = false;
                } else {
                  this.EntityIdField.templateOptions.required = true;
                }
              }
            }
          }
        },
        {
          className: 'col-12 col-md-6',
          type: 'select',
          key: 'country',
          templateOptions: {
            options: Company.getCountries(),
            label: this.translate.instant('Country'),
            placeholder: this.translate.instant('Country'),
            required: true,
            change: () => {
              this.companyService.countryValueSubject.next();
              this.EntityIdField.templateOptions.label = this.translate.instant(
                Company.getEntityType(
                  this.model.country,
                  this.model.businessAccount
                )
              );
              // Disable entityId field if country is not selected
              this.EntityIdField.templateOptions.disabled =
                this.model.country == undefined || this.model.country == null;
              this.ufField.templateOptions.options = Address.getStates(
                this.model.country
              );
              this.zipCodeField.templateOptions.label = Address.getPostalCode(
                this.model.country
              );
              // SSN not required to USA people
              if (this.model.country === 'US' && !this.model.businessAccount) {
                this.EntityIdField.templateOptions.required = false;
              } else {
                this.EntityIdField.templateOptions.required = true;
              }
            }
          }
        },
        {
          className: 'col-12 col-md-6',
          type: 'input',
          key: 'name',
          templateOptions: {
            label: this.translate.instant('Name'),
            placeholder: this.translate.instant('Name'),
            required: true
          }
        },
        {
          className: 'col-12 col-md-6',
          type: 'input',
          key: 'description',
          templateOptions: {
            label: this.translate.instant('Description'),
            required: false,
            placeholder: this.translate.instant('Description')
          }
        },
        {
          className: 'col-12 col-md-6',
          type: 'cnpjMask',
          key: 'entityId',
          templateOptions: {
            label: this.translate.instant('Entity Number'),
            required: true
          }
        },
        {
          className: 'col-12 col-md-6',
          type: 'emailMask',
          key: 'email',
          templateOptions: {
            required: true
          }
        },
        {
          className: 'col-12 col-md-6',
          type: 'phoneMask',
          key: 'phone',
          templateOptions: {
            label: this.translate.instant('Primary Phone'),
            required: true
          }
        },
        {
          className: 'col-12 col-md-6',
          type: 'cellphoneMask',
          key: 'cellphone',
          templateOptions: {
            label: this.translate.instant('Secondary Phone'),
            required: false
          }
        },
        {
          className: 'col-12 col-md-6 align-self-center',
          key: 'isEnable',
          type: 'checkbox',
          defaultValue: true,
          templateOptions: {
            label: this.translate.instant('Is Enabled?'),
            required: true
          }
        }
      ]
    }
  ];

  stepAddress: FormlyFieldConfig[] = [
    {
      key: 'address',
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          className: 'col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6',
          type: 'input',
          key: 'street',
          templateOptions: {
            label: this.translate.instant('Street'),
            placeholder: this.translate.instant('Street'),
            required: true
          }
        },
        {
          className: 'col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-2',
          type: 'input',
          key: 'numberValue',
          templateOptions: {
            label: this.translate.instant('Number'),
            placeholder: this.translate.instant('Number'),
            type: 'number',
            required: true
          }
        },
        {
          className: 'col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4',
          type: 'input',
          key: 'neighborhood',
          templateOptions: {
            type: 'text',
            label: this.translate.instant('Neighborhood'),
            placeholder: this.translate.instant('Neighborhood'),
            required: true
          }
        },
        {
          className: 'col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4',
          type: 'input',
          key: 'complement',
          templateOptions: {
            type: 'text',
            label: this.translate.instant('Complement'),
            placeholder: this.translate.instant('Complement')
          }
        },
        {
          className: 'col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4',
          type: 'input',
          key: 'city',
          templateOptions: {
            type: 'text',
            label: this.translate.instant('City'),
            placeholder: this.translate.instant('City'),
            required: true
          }
        },
        {
          className: 'col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-2',
          type: 'select',
          key: 'state',
          templateOptions: {
            label: this.translate.instant('State'),
            placeholder: this.translate.instant('State'),
            required: true
          }
        },
        {
          className: 'col-xs-12 col-sm-12 col-md-6 lg-6 col-xl-2',
          type: 'zipCodeMask',
          key: 'postalCode',
          templateOptions: {
            required: true,
            hide: true
          }
        }
      ]
    },
    {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          className: 'col-12 col-md-6',
          type: 'input',
          key: 'latitude',
          templateOptions: {
            label: this.translate.instant('Latitude'),
            placeholder: this.translate.instant('Latitude'),
            type: 'number',
            required: true
          }
        },
        {
          className: 'col-12 col-md-6',
          type: 'input',
          key: 'longitude',
          templateOptions: {
            label: this.translate.instant('Longitude'),
            placeholder: this.translate.instant('Longitude'),
            type: 'number',
            required: true
          }
        }
      ]
    }
  ];

  stepFinal: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          className: 'col-12',
          type: 'companyView',
          key: 'companyFinish'
        }
      ]
    }
  ];

  steps: StepType[] = [
    {
      label: 'Company',
      icon: 'ti-flag-alt',
      fields: this.stepCompany
    },
    {
      label: 'Address',
      icon: 'ti-home',
      fields: this.stepAddress
    },
    {
      label: 'Finish',
      icon: 'ti-check',
      fields: this.stepFinal
    }
  ];

  form = new FormArray(this.steps.map(() => new FormGroup({})));

  constructor(
    protected injector: Injector,
    protected companyService: CompanyService,
    protected cd: ChangeDetectorRef,
    protected messageService: MessageService,
    private translate: TranslateService
  ) {
    super(injector, new Company(), companyService, Company.fromJson);
  }

  getTitle(index: number): string {
    return this.steps[index].label;
  }

  getIcon(index: number): string {
    return this.steps[index].icon;
  }

  prevStep(step): void {
    this.form.at(step).markAsUntouched();
    this.activedStep = step - 1;
  }

  nextStep(step): void {
    this.activedStep = step + 1;
  }

  validateStep(index: number): boolean {
    if (!this.form.at(index).valid) {
      return false;
    } else {
      return true;
    }
  }

  async submit(event, origin): Promise<void> {
    if (this.form.valid && origin === 'form') {
      await super.submitForm('company');
    }
  }

  ngAfterViewInit(): void {
    this.cd.detectChanges();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.buildResourceForm();

    this.ufField = this.stepAddress[0].fieldGroup.find(
      (item) => item.key === 'state'
    );
    this.EntityIdField = this.stepCompany[0].fieldGroup.find(
      (item) => item.key === 'entityId'
    );
    this.zipCodeField = this.stepAddress[0].fieldGroup.find(
      (item) => item.key === 'postalCode'
    );

    // Disable entityId field if country is not selected
    this.EntityIdField.templateOptions.disabled =
      this.model.country == undefined || this.model.country == null;

    // Disable entityId field if country is not selected
    this.EntityIdField.templateOptions.disabled =
      this.model.country == undefined || this.model.country == null;

    this.datasubject = this.formDatasubject.subscribe(() => {
      this.companyService.countryValueSubject.next();
      this.EntityIdField.templateOptions.label = this.translate.instant(
        Company.getEntityType(this.model.country, this.model.businessAccount)
      );
      // Disable entityId field if country is not selected
      this.EntityIdField.templateOptions.disabled =
        this.model.country == undefined || this.model.country == null;
      this.ufField.templateOptions.options = Address.getStates(
        this.model.country
      );

      this.zipCodeField.templateOptions.label = Address.getPostalCode(
        this.model.country
      );

      // Used only for update module, verify entityID mask type
      if (this.model.country) {
        if (this.model.country === 'US' && !this.model.businessAccount) {
          this.EntityIdField.templateOptions.required = false;
        } else {
          this.EntityIdField.templateOptions.required = true;
        }
      }
    });
  }

  ngOnDestroy() {
    this.datasubject.unsubscribe();
  }
}
