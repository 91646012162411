import { Injectable } from '@angular/core';

import { Constants } from '../../../constants';
import {
  AwsIotCoreService,
  MqttSendMessage,
  MqttSubscription,
  OnInitMqttService
} from '../iot/aws-iot-core.service';
import { MediaChangeDetectService } from '../../../pages/device/shared/services/media-change-detect.service';
import { BaseMqttService } from './base-mqtt.service';

@Injectable({
  providedIn: 'root'
})
export class EyesMqttService
  extends BaseMqttService
  implements MqttSubscription, MqttSendMessage, OnInitMqttService
{
  private _showEyes = false;

  constructor(
    protected awsIotCoreService: AwsIotCoreService,
    private mediaChangeDetectService: MediaChangeDetectService
  ) {
    super(awsIotCoreService);
  }

  async toggleEyes(): Promise<void> {
    await this.publish(`${this._deviceId}/${Constants.CHANGE_EYES}`, {
      showEyes: this.showEyes
    });
  }

  protected subscribe(): void {
    this.subscribeTopic(
      `${this._deviceId}/${Constants.CHANGE_EYES_FEED}`,
      (data, error) => {
        if (error) {
          console.log('[Constants.CHANGE_EYES_FEED]:Error:', error);
        } else {
          this.showEyes = data.showEyes;
          console.log('Change Eyes Feed:', this.showEyes);
          this.mediaChangeDetectService.changeDetection$.next(true);
        }
      }
    );
    this.showEyes = true;
  }

  get showEyes(): boolean {
    return this._showEyes;
  }

  set showEyes(value: boolean) {
    this._showEyes = value;
  }
}
