import { Injectable, Injector } from '@angular/core';
import { BaseResourceService } from 'src/app/shared/services/base-resource.service';
import { Schedule } from '../../../shared/models/schedule.model';
import { GuestService } from '../../device/device-control/services/guest.service';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Constants } from 'src/app/constants';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService extends BaseResourceService<Schedule> {
  private _apiURL: string;

  constructor(
    protected injector: Injector
  ) {
    super('schedulers', injector, Schedule.fromJson);
    this._apiURL = `${Constants.SCHEDULER_BASE_URL}${this.apiPath}`;
  }

  override create(resource: Schedule): Observable<Schedule> {
    return this.http
      .post(`${this._apiURL}`, resource)
      .pipe(
        map(this.jsonDataToResource.bind(this)),
        catchError(this.handleError)
      );
  }

  getByPagination(
    start: number,
    limit: number,
    search: string
  ): Observable<{ count: number; schedules: Schedule[] }> {
    return this.http
      .get<Schedule[]>(
        `${this._apiURL}?offset=${start}&limit=${limit}&search=${search}`
      )
      .pipe(
        map((jsonData: any) => ({
          count: jsonData.count,
          schedules: this.jsonDataToResources(jsonData.data)
        })),
        catchError(this.handleError)
      );
  }

  override update(resource: Schedule): Observable<Schedule> {
    return this.http.put(`${this._apiURL}/${resource.id}`, resource).pipe(
      map(() => resource),
      catchError(this.handleError)
    );
  }

  override delete(id: string): Observable<void> {
    return this.http
      .delete(`${this._apiURL}/${id}`)
      .pipe(catchError(this.handleError));
  }

  getByDate(startAt: Date, endAt: Date): Observable<Schedule[]> {
    return this.http
      .get<Schedule[]>(`${this._apiURL}/?startAt=${startAt}&endAt=${endAt}`)
      .pipe(
        map((jsonData: any) => this.jsonDataToResources(jsonData.data)),
        catchError(this.handleError)
      );
  }
}
