import { DoCheck, Injectable, Injector, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseResourceService } from '../services/base-resource.service';
import { BaseResourceModel } from '../models/base-resource.model';
import { BaseResourceNotifications } from '../base-resource-notifications/base-resource-notifications.component';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

export interface ReloadDelegate {
  currentAction: string;
  id: number;

  ngOnInit(): void;
}

export interface StepType {
  label: string;
  icon: string;
  fields: FormlyFieldConfig[];
}

@Injectable({
  providedIn: 'root'
})
export abstract class BaseResourceFormComponent<T extends BaseResourceModel>
  extends BaseResourceNotifications<T>
  implements OnInit, DoCheck
{
  @Input() delegate: ReloadDelegate;
  currentAction: 'add' | 'edit';
  resourceForm: FormGroup;
  pageTitle = '';
  serverErrorMessages: string[] = null;
  submittingForm = false;
  model: any = { id: null };
  pathRedirect: string = null;
  activatedRoute: ActivatedRoute;

  protected route: ActivatedRoute;
  protected router: Router;
  protected formBuilder: FormBuilder;

  public formDatasubject = new Subject<void>();

  protected constructor(
    protected injector: Injector,
    public resource: T,
    protected resourceService: BaseResourceService<T>,
    protected jsonDataToResourceFN: (jsonData) => T
  ) {
    super(injector.get(MessageService));
    this.route = this.injector.get(ActivatedRoute);
    this.router = this.injector.get(Router);
    this.formBuilder = this.injector.get(FormBuilder);
    this.activatedRoute = this.injector.get(ActivatedRoute);
  }

  reload(): void {
    this.setCurrentAction();
    this.loadResource();
  }

  ngOnInit(): void {
    this.reload();
  }

  ngDoCheck(): void {
    this.setPageTitle();
  }

  buildResourceForm(): void {
    console.log('Call BuildResourceForm');
  }

  async submitForm(pathRedirect: string = null): Promise<void> {
    this.pathRedirect = pathRedirect;
    this.submittingForm = true;
    if (this.currentAction === 'add') {
      await this.createResourceFormFormly();
    } else {
      await this.updateResourceFromFormly();
    }
  }

  protected loadResource(): void {
    if (this.currentAction === 'edit') {
      this.resourceService
        .getById(this.activatedRoute.snapshot.params.id)
        .subscribe(
          (resource) => {
            this.resource = resource;
            this.model = resource;
            this.formDatasubject.next();
          },
          (error) =>
            console.error(
              'An error has occurred on the server, please try later. ',
              error
            )
        );
    }
  }

  protected setCurrentAction(): void {
    if (this.route.snapshot.url[0] !== undefined) {
      if (this.route.snapshot.url[0].path === 'add') {
        this.currentAction = 'add';
      } else {
        this.currentAction = 'edit';
      }
    }
  }

  protected setPageTitle(): void {
    if (this.currentAction === 'add') {
      this.pageTitle = this.creationPageTitle();
    } else {
      this.pageTitle = this.editionPageTitle();
    }
  }

  protected updateResourceFromFormly(): void {
    this.resourceService.update(this.model).subscribe(
      (resourceReceived) =>
        this.actionsFormSuccessCreateOrUpdate(resourceReceived),
      (error) => this.actionsForError(error)
    );
  }

  private actionsFormSuccessCreateOrUpdate(resource: T): void {
    this.resource = null;
    this.model = { id: null };
    this.actionsForSuccess(resource);
  }

  protected async updateResource(): Promise<void> {
    const resource: T = this.jsonDataToResourceFN(this.resourceForm.value);
    await this.resourceService
      .update(resource)
      .toPromise()
      .then((resourceReceived) => this.actionsForSuccess(resourceReceived))
      .catch((error) => this.actionsForError(error));
  }

  protected async createResourceFormFormly(): Promise<void> {
    const resource: T = this.resource;
    await this.resourceService
      .create(this.model)
      .toPromise()
      .then((resourceReceived) =>
        this.actionsFormSuccessCreateOrUpdate(resourceReceived)
      )
      .catch((error) => this.actionsForError(error));
  }

  protected createResource(): void {
    const resource: T = this.jsonDataToResourceFN(this.resourceForm.value);

    this.resourceService.create(resource).subscribe(
      (resourceReceived) => this.actionsForSuccess(resourceReceived),
      (error) => this.actionsForError(error)
    );
    this.ngOnInit();
  }

  protected creationPageTitle(): string {
    return 'New';
  }

  protected editionPageTitle(): string {
    return 'Edition';
  }

  private actionsForSuccess(resource: T): void {
    this.addToast({
      severity: 'success',
      summary: 'Registration success!',
      key: 'bottom-actions-toast'
    });
    this.router.navigateByUrl(this.pathRedirect, { skipLocationChange: false });
  }

  protected actionsForError(error): void {
    this.submittingForm = false;
    if (error === 'CREDENTIAL_ALREADY_EXISTS') {
      this.serverErrorMessages = [
        'This email address is already in use. Please try using another email.'
      ];
    } else {
      this.serverErrorMessages = [
        'Communication with server failed. Please try again later.'
      ];
    }

    this.addToast({
      severity: 'error',
      summary: 'Registration error!',
      detail: this.serverErrorMessages[0],
      key: 'bottom-actions-toast'
    });
  }

  protected getBase64(file, callback): void {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      callback(reader.result);
    };
    reader.onerror = (error) => {
      return error;
    };
  }
}
