import { Injectable } from '@angular/core';
import {
  AwsIotCoreService,
  MqttSendMessage,
  MqttSubscription,
  OnInitMqttService
} from '../iot/aws-iot-core.service';
import { Constants } from '../../../constants';
import { MediaChangeDetectService } from '../../../pages/device/shared/services/media-change-detect.service';
import { BaseMqttService } from './base-mqtt.service';

export interface Volume {
  mute: false;
  level: string;
}

@Injectable({
  providedIn: 'root'
})
export class HardwareMqttService
  extends BaseMqttService
  implements MqttSubscription, MqttSendMessage, OnInitMqttService
{
  private _volume: Volume = {
    mute: false,
    level: '---'
  };

  private _laser = false;
  constructor(
    protected awsIotCoreService: AwsIotCoreService,
    private mediaChangeDetectService: MediaChangeDetectService
  ) {
    super(awsIotCoreService);
  }

  async changeVolumeControl(val: any): Promise<void> {
    await this.publish(
      `${this._deviceId}/${Constants.HARDWARE}/${Constants.VOLUME}`,
      {
        command: 'volume',
        data: val
      }
    );
  }

  async changeLaserControl(): Promise<void> {
    await this.publish(
      `${this._deviceId}/${Constants.HARDWARE}/${Constants.LASER}`,
      {
        command: 'laser',
        data: 1
      }
    );
  }

  protected subscribe(): void {
    this.subscribeTopic(
      `${this._deviceId}/${Constants.HARDWARE}/${Constants.LASER}`,
      (data, error) => {
        if (error) {
          console.log('[Constants.TOGGLE_LASER]:Error:', error);
        } else {
          this._laser = !data.laser;
          console.log('laser status', this._laser);
          this.mediaChangeDetectService.changeDetection$.next(true);
        }
      }
    );

    this.subscribeTopic(
      `${this._deviceId}/${Constants.HARDWARE}/${Constants.VOLUME}/status`,
      (data, error) => {
        if (error) {
          console.log('[Constants.VOLUME]:Error:', error);
        } else {
          this._volume.level = data.level;
          this.mediaChangeDetectService.changeDetection$.next(true);
        }
      }
    );
  }

  async takeResetDevice(deviceId): Promise<void> {
    await this.publish(`${deviceId}/${Constants.HARDWARE}/${Constants.RESET}`, {
      command: 'reset',
      data: 'full'
    });
  }

  get volume(): Volume {
    return this._volume;
  }

  set volume(value: Volume) {
    this._volume = value;
  }
}
