import { Injectable, Injector } from '@angular/core';
import { BaseResourceService } from '../../../../shared/services/base-resource.service';
import { RoleDevice } from '../../../../shared/models/role-device.model';

@Injectable({
  providedIn: 'root'
})
export class RoleDeviceService extends BaseResourceService<RoleDevice> {
  constructor(protected injector: Injector) {
    super('rolesDevice', injector, RoleDevice.fromJson);
  }
}