import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * Created by Wilton Oliveira Ferreira on 29/08/2022
 */

@Injectable({
  providedIn: 'root'
})
export class MediaChangeDetectService {
  changeDetection$ = new Subject();
}
