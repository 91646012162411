/**
 * Created by Guilherme Beneti Martins on 07/12/2022
 */

import { Selector } from '@ngxs/store';
import { CallState } from '../states/call.state';

import { RegisteredType } from '../../constants/registered-type';

// Selectors send true of false depending on register status
export class BooleanRegisterQueries {
  @Selector([CallState.getRegisterState])
  static isNotRegistered(state: RegisteredType): boolean {
    return state === RegisteredType.NOT_REGISTERED;
  }

  @Selector([CallState.getRegisterState])
  static isRegistering(state: RegisteredType): boolean {
    return state === RegisteredType.REGISTERING;
  }

  @Selector([CallState.getRegisterState])
  static isRegistered(state: RegisteredType): boolean {
    return state === RegisteredType.REGISTERED;
  }

  @Selector([CallState.getRegisterState])
  static isEnded(state: RegisteredType): boolean {
    return state === RegisteredType.ENDED;
  }

  @Selector([CallState.getRegisterState])
  static isFailed(state: RegisteredType): boolean {
    return state === RegisteredType.FAILED;
  }
}

// Selectors send true when the status is reached
export class RegisterChangeQueries {
  @Selector([CallState.getRegisterState])
  static notRegisteredStatus(state: RegisteredType): boolean {
    if (state === RegisteredType.NOT_REGISTERED) return true;
  }

  @Selector([CallState.getRegisterState])
  static registeringStatus(state: RegisteredType): boolean {
    if (state === RegisteredType.REGISTERING) return true;
  }

  @Selector([CallState.getRegisterState])
  static registeredStatus(state: RegisteredType): boolean {
    if (state === RegisteredType.REGISTERED) return true;
  }

  @Selector([CallState.getRegisterState])
  static endedStatus(state: RegisteredType): boolean {
    if (state === RegisteredType.ENDED) return true;
  }

  @Selector([CallState.getRegisterState])
  static failedStatus(state: RegisteredType): boolean {
    if (state === RegisteredType.FAILED) return true;
  }
}
