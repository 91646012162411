/**
 * Created by Guilherme Beneti Martins on 08/12/2022
 */
import { Selector } from '@ngxs/store';
import { ParticipantsState } from '../states/participants.state';
import { WebsocketUser } from '../../models/participants/websocket-user.model';
import { BooleanRegisterQueries } from './register.queries';
import { WebsocketState } from '../../../shared/store/states/websocket.state';

export class ParticipantsQueries {
  @Selector([
    ParticipantsState.getOtherParticipants,
    ParticipantsState.isOperatorParticipantConnected
  ])
  static getParticipantsAmount(
    otherParticipants: string[],
    operatorConnected: boolean
  ): number {
    return operatorConnected
      ? otherParticipants.length + 1
      : otherParticipants.length;
  }

  @Selector([
    ParticipantsState.isOperatorParticipantConnected,
    ParticipantsState.hasCommander,
    ParticipantsState.getOperatorParticipant,
    ParticipantsState.getCommander
  ])
  static isOperatorCommander(
    hasOperator: boolean,
    hasCommander: boolean,
    operator: WebsocketUser,
    commander: WebsocketUser
  ): boolean {
    return (
      hasOperator &&
      hasCommander &&
      operator?.participantId === commander?.participantId &&
      operator?.email === commander?.email &&
      operator?.deviceId === commander?.deviceId &&
      operator?.email === commander?.email &&
      operator?.operatorType === commander?.operatorType &&
      operator?.connectionId === commander?.connectionId
    );
  }

  @Selector([
    ParticipantsState.getOperatorParticipant,
    ParticipantsState.isOperatorParticipantConnected
  ])
  static isOperatorGuest(
    operator: WebsocketUser,
    isOperatorConnected: boolean
  ): boolean {
    if (isOperatorConnected) return operator?.operatorType === 'GUEST';
  }

  @Selector([ParticipantsState.getOtherParticipants])
  static hasCommonParticipant(
    participants: WebsocketUser[]
  ): (participant: WebsocketUser) => boolean {
    const participantsSet = new Set<WebsocketUser>(participants);
    return (participant: WebsocketUser) => {
      return participantsSet.has(participant);
    };
  }

  @Selector([
    BooleanRegisterQueries.isRegistering,
    WebsocketState.existingParticipants
  ])
  static acceptExistingParticipants(isRegistering: boolean, message: any): any {
    if (isRegistering) return message;
  }
}
