/**
 * Created by Guilherme Beneti Martins on 19/10/2022
 */

import { Device } from '../../../shared/models/device.model';
import { Screenshot } from '../../../shared/models/screenshot.model';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { ScreenshotService } from '../services/screenshot.service';
import { append, patch, removeItem } from '@ngxs/store/operators';
import { S3FileService } from '../../../shared/services/s3-file.service';
import { defaultIfEmpty, takeUntil } from 'rxjs/operators';
import {
  ClearScreenshots,
  ClearSelectedScreenshots,
  DeleteScreenshot,
  DeselectScreenshot,
  FetchAllScreenshots,
  FetchScreenshotsByDeviceID,
  FetchScreenshotsByID,
  SelectScreenshot,
  SetMaxScreenshotsToShow
} from './screenshot.actions';
import { ScreenshotsStateModel } from './screenshot.model';

@State<ScreenshotsStateModel>({
  name: 'screenshots',
  defaults: {
    items: [],
    selectedIDs: [],
    maxScreenshotsToShow: 0
  }
})
@Injectable({
  providedIn: 'root'
})
export class ScreenshotsState {
  constructor(
    private screenshotService: ScreenshotService,
    protected s3FileService: S3FileService
  ) {}

  @Action(FetchScreenshotsByDeviceID)
  protected async fetchScreenshots(
    ctx: StateContext<ScreenshotsStateModel>,
    action: FetchScreenshotsByDeviceID
  ) {
    ctx.dispatch(new ClearScreenshots());
    this.screenshotService
      .getScreenshotsByDeviceId(action.deviceId)
      .pipe(defaultIfEmpty(), takeUntil(action.notifier))
      .subscribe((screenshot) => {
        if (screenshot !== null) {
          ctx.setState(
            patch<ScreenshotsStateModel>({
              items: append<Screenshot>([screenshot])
            })
          );
        }
      });
  }

  @Action(FetchAllScreenshots)
  protected async fetchAllScreenshots(
    ctx: StateContext<ScreenshotsStateModel>,
    action: FetchAllScreenshots
  ) {
    ctx.dispatch(new ClearScreenshots());
    this.screenshotService
      .getAllScreenshots()
      .pipe(defaultIfEmpty(), takeUntil(action.notifier))
      .subscribe((screenshot: Screenshot | null) => {
        if (screenshot !== null) {
          ctx.setState(
            patch<ScreenshotsStateModel>({
              items: append<Screenshot>([screenshot])
            })
          );
        }
      });
  }

  @Action(DeleteScreenshot)
  protected deleteScreenshot(
    ctx: StateContext<ScreenshotsStateModel>,
    action: DeleteScreenshot
  ) {
    ctx.setState(
      patch<ScreenshotsStateModel>({
        items: removeItem<Screenshot>(
          (screenshot) => screenshot.id === action.id
        )
      })
    );
  }

  @Action(FetchScreenshotsByID)
  protected async fetchScreenshotsByID(
    ctx: StateContext<ScreenshotsStateModel>,
    action: FetchScreenshotsByID
  ) {}

  @Action(SelectScreenshot)
  protected selectScreenshot(
    ctx: StateContext<ScreenshotsStateModel>,
    action: SelectScreenshot
  ) {
    ctx.setState(
      patch<ScreenshotsStateModel>({
        selectedIDs: append<string>([action.id])
      })
    );
  }

  @Action(DeselectScreenshot)
  protected deselectScreenshot(
    ctx: StateContext<ScreenshotsStateModel>,
    action: DeselectScreenshot
  ) {
    ctx.setState(
      patch<ScreenshotsStateModel>({
        selectedIDs: removeItem<string>((id) => id === action.id)
      })
    );
  }

  @Action(ClearSelectedScreenshots)
  protected clearSelectedScreenshots(ctx: StateContext<ScreenshotsStateModel>) {
    ctx.setState(
      patch<ScreenshotsStateModel>({
        selectedIDs: removeItem<string>(() => true)
      })
    );
  }

  @Action(ClearScreenshots)
  protected ClearScreenshots(ctx: StateContext<ScreenshotsStateModel>) {
    ctx.setState(
      patch<ScreenshotsStateModel>({
        items: []
      })
    );
  }

  @Action(SetMaxScreenshotsToShow)
  protected SetScreenshotsToShow(
    ctx: StateContext<ScreenshotsStateModel>,
    action: SetMaxScreenshotsToShow
  ) {
    ctx.setState(
      patch<ScreenshotsStateModel>({
        maxScreenshotsToShow: action.maxScreenshotsToShow
      })
    );
  }

  @Selector()
  static getScreenshots(state: ScreenshotsStateModel): Screenshot[] {
    return state.items;
  }

  @Selector()
  static getMaxScreenshotsToShow(state: ScreenshotsStateModel): number {
    return state.maxScreenshotsToShow;
  }

  @Selector()
  static filterScreenshotsShown(state: ScreenshotsStateModel): Screenshot[] {
    return state.items.slice(
      0,
      Math.min(state.maxScreenshotsToShow, state.items.length)
    );
  }

  static filterScreenshotsByDevice(screenshots: Screenshot[]): any {
    if (screenshots.length > 0) {
      return (device: Device) => {
        if (device == undefined) {
          return [];
        } else {
          return screenshots.filter(
            (screenshot) => screenshot.deviceId === device.id
          );
        }
      };
    } else {
      return () => [];
    }
  }

  @Selector([ScreenshotsState.getScreenshots])
  static getDeviceScreenshots(screenshots: Screenshot[]) {
    return this.filterScreenshotsByDevice(screenshots);
  }

  @Selector()
  static getSelectedIDs(state: ScreenshotsStateModel): string[] {
    return state.selectedIDs;
  }

  @Selector([ScreenshotsState.getScreenshots, ScreenshotsState.getSelectedIDs])
  static getSelectedScreenshots(
    screenshots: Screenshot[],
    selected: string[]
  ): Screenshot[] {
    return screenshots.filter((screenshot) =>
      selected.some((id) => id === screenshot.id)
    );
  }

  @Selector([ScreenshotsState.getSelectedScreenshots])
  static getSelectedDeviceScreenshots(screenshots: Screenshot[]): any {
    return this.filterScreenshotsByDevice(screenshots);
  }
}
