export interface BaseResourceInterface {
  id?: number;
  description?: string;
}

export abstract class BaseResourceModel {
  id?: string;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
}
