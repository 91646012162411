import { Component, Injector } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { RoleDeviceService } from '../../security/roleDevice/shared/role-device.service';

@Component({
  selector: 'app-device-view',
  template: `
    <formly-form [model]="model" [fields]="fields" [options]=""> </formly-form>
  `
})
export class DeviceViewComponent extends FieldType {
  infoDevice = {
    fieldGroupClassName: 'row',
    fieldGroup: [
      {
        className: 'col-12 col-md-6',
        type: 'outputLabel',
        key: 'name',
        templateOptions: {
          label: this.translate.instant('Name'),
          type: 'text'
        }
      },
      {
        className: 'col-12 col-md-6',
        type: 'outputLabel',
        key: 'description',
        templateOptions: {
          label: this.translate.instant('Description'),
          type: 'text'
        }
      },
      {
        className: 'col-12 col-md-6',
        type: 'outputLabel',
        key: 'roleId',
        templateOptions: {
          label: this.translate.instant('Device Category'),
          valueProp: 'id',
          labelProp: 'description',
          type: 'select',
          options: []
        },
        hooks: {
          onInit: async (field) => {
            this.roleDeviceService.getAll().subscribe(
              (resources) => {
                field.templateOptions.options = resources;
              },
              (error) => console.error(`Error loading list: ${error}`)
            );
          }
        }
      },
      {
        className: 'col-12 col-md-6',
        type: 'outputLabel',
        key: 'version',
        templateOptions: {
          label: this.translate.instant('Version'),
          type: 'text'
        }
      },
      {
        className: 'col-12',
        type: 'outputLabel',
        key: 'operatorDevices',
        templateOptions: {
          label: this.translate.instant('Operators'),
          type: 'pickList',
          description: this.translate.instant(
            'List of selected operators to this device'
          )
        }
      },
      {
        className: 'col-12 col-md-6',
        type: 'outputLabel',
        key: 'latitude',
        templateOptions: {
          label: this.translate.instant('Latitude'),
          type: 'text'
        }
      },
      {
        className: 'col-12 col-md-6',
        type: 'outputLabel',
        key: 'longitude',
        templateOptions: {
          label: this.translate.instant('Longitude'),
          type: 'text'
        }
      }
    ]
  };

  fields: FormlyFieldConfig[] = [
    {
      className: 'section-label text-center',
      template: `<div><strong>${this.translate.instant(
        'DEVICE INFORMATION'
      )}</strong></div><br>`
    },
    this.infoDevice
  ];

  protected route: ActivatedRoute;

  constructor(
    protected injector: Injector,
    private translate: TranslateService,
    protected roleDeviceService: RoleDeviceService
  ) {
    super();
    this.route = this.injector.get(ActivatedRoute);
  }
}
