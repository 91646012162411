<div class="card border-0">
  <div class="card-header" *ngIf="isSearch">
    <div class="row justify-content-end mr-2">
      <div class="w-50">
        <input
          class="form-control form-control-lg"
          type="text"
          [(ngModel)]="search"
          (input)="getSearch($event?.target?.value)"
          placeholder="Pesquisar"
        />
      </div>
    </div>
  </div>
  <div class="card-body">
    <p-table
      #dt
      *ngIf="data"
      [value]="data | async"
      [(selection)]="dataSelected"
      [rowHover]="true"
      dataKey="id"
      styleClass="p-datatable-sm"
      [first]="first"
      [totalRecords]="count"
      (onLazyLoad)="loadLazy($event)"
      [lazy]="true"
      [pageLinks]="10"
      [rows]="limit"
      [showCurrentPageReport]="true"
      [rowsPerPageOptions]="[10, 20, 30, 40, 50, 100]"
      [loading]="loading"
      [paginator]="true"
      [currentPageReportTemplate]="currentPageReportTemplate"
      [globalFilterFields]="fields"
      [filterDelay]="0"
    >
      <ng-template pTemplate="header">
        <tr>
          <th
            *ngFor="let item of columns | showTable"
            [pSortableColumn]="item.value"
          >
            {{ item.label }} <p-sortIcon [field]="item.value"></p-sortIcon>
          </th>
          <th *ngIf="buttons.length > 0"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-data let-columns="columns">
        <tr class="ui-selectable-row" [className]="rowStyleProcess(data)">
          <td *ngFor="let item of rows | showTable">
            <ng-container *ngIf="item.type === 'string'">
              <span class="p-column-title" style="font-weight: bolder">{{
                item.label
              }}</span>
              {{ data[item.value] }}
            </ng-container>
            <ng-container *ngIf="item.type === 'image'">
              <span class="p-column-title" style="font-weight: bolder">{{
                item.label
              }}</span>
              <img
                [src]="imagePath + checkValue(data.image)"
                alt="user image"
                class="align-top m-r-10"
                style="width: 40px"
              />
            </ng-container>
            <ng-container *ngIf="item.type === 'date'">
              <span class="p-column-title" style="font-weight: bolder">{{
                item.label
              }}</span>
              {{ data[item.value] | date : 'dd/MM/YYYY' }}
            </ng-container>
            <ng-container *ngIf="item.type === 'object'">
              <span class="p-column-title" style="font-weight: bolder">{{
                item.label
              }}</span>
              {{ data[item.value][item.field] }}
            </ng-container>
          </td>
          <td
            *ngIf="buttons.length > 0"
            style="min-width: 100px; text-align: right"
            class="overflow-hidden d-flex flex-row-reverse"
          >
            <button
              [title]="item.label"
              class="btn btn-light mr-1 btn-sm"
              *ngFor="let item of buttons"
              (click)="eventButton(data, item)"
            >
              <span class="material-icons" [style]="'color:' + item.color">{{
                item.icon
              }}</span>
            </button>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="5" style="text-align: left">
            Nenhum registro encontrado.
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
