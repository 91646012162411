/**
 * Created by Guilherme Beneti Martins on 19/10/2022
 */

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { Screenshot } from '../../models/screenshot.model';
import { ConfirmationService } from 'primeng/api';
import { DOCUMENT } from '@angular/common';
import { Observable } from 'rxjs';
import { Constants } from 'src/app/constants';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-galleria',
  templateUrl: './galleria.component.html',
  styleUrls: ['./galleria.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GalleriaComponent implements OnInit {
  @Input() public screenshots: Screenshot[];
  @Input() show = false;
  @Input() initialScreenshot$: Observable<Screenshot>;
  public readonly image: string = Constants.IMAGE_PLACEHOLDER;

  @Output() close = new EventEmitter<boolean>();
  @Output() delete = new EventEmitter<Screenshot>();
  @Output() download = new EventEmitter<Screenshot>();

  public fullscreen = true;
  public activeIndex = 0;
  public cdnS3Medias = Constants.CDN_S3_MEDIAS;

  constructor(
    @Inject(DOCUMENT) document,
    protected confirmationService: ConfirmationService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.initialScreenshot$.subscribe((initialScreenshot) => {
      const screenshotPosition = this.screenshots.indexOf(initialScreenshot);
      if (screenshotPosition > 0) {
        this.activeIndex = screenshotPosition;
      } else {
        this.activeIndex = 0;
      }
    });
  }

  galleriaClass(): string {
    return `custom-galleria ${this.fullscreen ? 'fullscreen' : ''}`;
  }

  emitClose(): void {
    this.close.emit(true);
    this.activeIndex = 0;
  }

  emitDownload(screenshot: Screenshot): void {
    this.download.emit(screenshot);
  }

  emitDelete(screenshot: Screenshot): void {
    this.delete.emit(screenshot);
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.key === 'ArrowLeft') {
      this.previousImage();
    } else if (event.key === 'ArrowRight') {
      this.nextImage();
    } else if (event.key === 'Escape') {
      this.emitClose();
    }
  }

  nextImage(): void {
    this.activeIndex = (this.activeIndex + 1) % this.screenshots.length;
  }

  previousImage(): void {
    this.activeIndex =
      (this.activeIndex - 1 + this.screenshots.length) %
      this.screenshots.length;
  }

  confirmDelete(event: Event, screenshot: Screenshot): void {
    this.confirmationService.confirm({
      target: event.target,
      message: this.translate.instant('Are you sure you want to delete this item?'),
      icon: 'pi pi-exclamation-triangle',
      key: 'galleriaPopup',
      accept: () => {
        this.emitDelete(screenshot);

        if (this.screenshots.length <= 0) {
          this.emitClose();
        } else if (
          this.activeIndex < this.screenshots.length &&
          this.activeIndex > 0
        ) {
          this.activeIndex = this.activeIndex - 1;
        } else {
          this.activeIndex = 1;
        }
      }
    });
  }
}
