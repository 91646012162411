import { Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { LOCATION_INITIALIZED } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

export function ApplicationInitializerFactory(
  translate: TranslateService,
  injector: Injector
) {
  return async () => {
    await injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    let defaultLang: string;
    if (
      translate.getDefaultLang() === undefined &&
      !localStorage.getItem('language')
    ) {
      defaultLang = translate.getBrowserLang();
      localStorage.setItem('language', defaultLang);
    } else defaultLang = localStorage.getItem('language');

    translate.addLangs(['en', 'fr', 'es', 'pt']);
    translate.setDefaultLang(defaultLang);
    try {
      await translate.use(defaultLang).toPromise();
    } catch (err) {
      console.log(err);
    }
    console.log(`Successfully initialized ${defaultLang} language.`);
  };
}
