<app-header class="header"></app-header>
<div id="page-content" class="d-flex flex-wrap">
  <app-aside-menu class="aside-menu"></app-aside-menu>
  <main class="main-content">
    <app-breadcrumbs></app-breadcrumbs>
    <router-outlet></router-outlet>
  </main>
</div>
<p-toast position="top-right" key="navbar-toast" [baseZIndex]="2000"></p-toast>
<p-toast
  position="bottom-right"
  key="bottom-actions-toast"
  [baseZIndex]="2000"
  [preventDuplicates]="true"
></p-toast>
<p-confirmDialog key="confirm-logout">
  <ng-template pTemplate="header">
    <div
      class="d-flex align-items-center flex-column"
      style="padding: 0.4rem 3.4rem; gap: 0.6rem; margin-bottom: -32px"
    >
      <i class="pi pi-info-circle" style="font-size: 3rem"></i>
      <h4 style="text-white">A new login is required</h4>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="d-flex align-items-center flex-column">
      <button
        type="button"
        pButton
        (click)="logout()"
        label="Proceed"
        class="p-button-success"
        style="width: 9rem"
      ></button>
    </div>
  </ng-template>
</p-confirmDialog>
